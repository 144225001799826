"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsvcsGatewayVersions_DisplayOrders = exports.EsvcsGatewayVersions_Descriptions_English = exports.EsvcsGatewayVersions_Descriptions = exports.EsvcsGatewayVersions_translateDescriptionBack = exports.EsvcsGatewayVersions_translateDescriptions = exports.EsvcsGatewayVersions = void 0;
var EsvcsGatewayVersions;
(function (EsvcsGatewayVersions) {
    EsvcsGatewayVersions[EsvcsGatewayVersions["None"] = 0] = "None";
    EsvcsGatewayVersions[EsvcsGatewayVersions["V1"] = 1] = "V1";
    EsvcsGatewayVersions[EsvcsGatewayVersions["V2"] = 2] = "V2";
})(EsvcsGatewayVersions = exports.EsvcsGatewayVersions || (exports.EsvcsGatewayVersions = {}));
function EsvcsGatewayVersions_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.EsvcsGatewayVersions_Descriptions);
    allKeys.forEach(function (key) {
        exports.EsvcsGatewayVersions_Descriptions[key] = translationFunction(exports.EsvcsGatewayVersions_Descriptions[key]);
    });
}
exports.EsvcsGatewayVersions_translateDescriptions = EsvcsGatewayVersions_translateDescriptions;
function EsvcsGatewayVersions_translateDescriptionBack() {
    var allKeys = Object.keys(exports.EsvcsGatewayVersions_Descriptions);
    allKeys.forEach(function (key) {
        exports.EsvcsGatewayVersions_Descriptions[key] = exports.EsvcsGatewayVersions_Descriptions_English[key];
    });
}
exports.EsvcsGatewayVersions_translateDescriptionBack = EsvcsGatewayVersions_translateDescriptionBack;
var _EsvcsGatewayVersions_Descriptions = {};
_EsvcsGatewayVersions_Descriptions.None = _EsvcsGatewayVersions_Descriptions[0] = "None";
_EsvcsGatewayVersions_Descriptions.V1 = _EsvcsGatewayVersions_Descriptions[1] = "Version 1";
_EsvcsGatewayVersions_Descriptions.V2 = _EsvcsGatewayVersions_Descriptions[2] = "Version 2";
var _EsvcsGatewayVersions_Descriptions_English = {};
_EsvcsGatewayVersions_Descriptions_English.None = _EsvcsGatewayVersions_Descriptions_English[0] = "None";
_EsvcsGatewayVersions_Descriptions_English.V1 = _EsvcsGatewayVersions_Descriptions_English[1] = "Version 1";
_EsvcsGatewayVersions_Descriptions_English.V2 = _EsvcsGatewayVersions_Descriptions_English[2] = "Version 2";
exports.EsvcsGatewayVersions_Descriptions = _EsvcsGatewayVersions_Descriptions;
exports.EsvcsGatewayVersions_Descriptions_English = _EsvcsGatewayVersions_Descriptions_English;
var _EsvcsGatewayVersions_DisplayOrders = {};
_EsvcsGatewayVersions_DisplayOrders.None = _EsvcsGatewayVersions_DisplayOrders[0] = 0;
_EsvcsGatewayVersions_DisplayOrders.V1 = _EsvcsGatewayVersions_DisplayOrders[1] = 1;
_EsvcsGatewayVersions_DisplayOrders.V2 = _EsvcsGatewayVersions_DisplayOrders[2] = 2;
exports.EsvcsGatewayVersions_DisplayOrders = _EsvcsGatewayVersions_DisplayOrders;
