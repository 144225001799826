import { IODataExpand, IODataProperty } from "./models";

export function buildODataExpansion(options: IODataExpand) {
  var parts: string[] = [];
  Object.keys(options).forEach((key) => {
    parts.push(buildODataExpansionPart(key, options[key]));
  });
  return parts.join(",");
}

function buildODataExpansionPart(key: string, property: IODataProperty) {
  var result = key;
  var parts: string[] = [];
  if (property.$filter) parts.push(`$filter=${property.$filter}`)
  if (property.$expand) parts.push(`$expand=${typeof property.$expand === "string" ? property.$expand : buildODataExpansion(property.$expand)}`);
  if (property.$select) parts.push(`$select=${property.$select}`)
  if (property.$count) parts.push(`$count=${property.$count}`);
  if (parts.length) {
    result += `(${parts.join(";")})`;
  }
  return result;
}
