import React, { useCallback, useState } from "react";
import { Button, Callout, FormGroup, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useFormikContext } from "formik";
import { isLocaleUS } from "../../../../i18n";
import { IPagedApiResponse } from "../../../../models/PagedApiResponse";
import { IPersonSearchResult, IPersonSnapshot } from "../../../../models/Person";
import { IApiPagingOptions } from "../../../../services/api/odata/models";
import AnimatedReveal from "../../../shared/AnimatedReveal";
import DataTableContainer from "../../../shared/DataTable/DataTableContainer";
import { IColumn, IColumnFilters, IColumnSort, SortDirection } from "../../../shared/DataTable/models";
import FormHost from "../../../shared/form/FormHost";
import TextField from "../../../shared/form/fields/TextField";
import { FormModes } from "../../../shared/form/models/baseTypes";
import { FormikErrorsEx, IValidation, ValidationType, groupErrorsKey } from "../../../shared/form/models/validation";
import useDialogState from "../../../shared/useDialogState";
import JsonDisplayDialog, { FnOpenJsonDialog, TContentFragment } from "./JsonDisplayDialog";


interface IPersonFormState {
  personId?: string;      // Substring of PatID/RP_ID to search LIKE
  lastName?: string;      // Substring of lastName to search LIKE
  firstName?: string;     // Substring of firstName to search LIKE
};

interface IPersonFilters extends IPersonFormState {
  customerId: string;     // REQUIRED
};

const defaultSort: IColumnSort<IPersonSearchResult> = {
  fieldName: "id",
  sortDirection: SortDirection.Asc
};

type TActionsColumn = { _actions_?: string };
export type TPersonSearchColDef = IColumn<IPersonSearchResult & TActionsColumn>;
export type TColDefGetterFunc = (openJsonDlg: FnOpenJsonDialog) => TPersonSearchColDef[];

export type TPersonEntityApiFunc = (
  customerId: string,
  personId?: string,
  lastName?: string,
  firstName?: string,
  pagingOptions?: IApiPagingOptions
) => Promise<IPagedApiResponse<IPersonSearchResult>>;


//#region Form handling
const personIdValidations: IValidation[] = [
  { type: ValidationType.Length, min: 3, max: 50 },
];

const lastNameValidations: IValidation[] = [
  { type: ValidationType.Length, min: 3, max: 50 },
];

const firstNameValidations: IValidation[] = [
  { type: ValidationType.Length, min: 3, max: 50 },
];
//#endregion

const FormHeader: React.FC = () => {
  const { errors } = useFormikContext<IPersonFormState>();
  const formLevelError = (errors as FormikErrorsEx<IPersonFormState>)[groupErrorsKey]?.["$form"];

  return (
    <AnimatedReveal show={!!formLevelError} containerStyle={{ width: 500, paddingBottom: 10 }}>
      <Callout intent={Intent.DANGER}>{formLevelError}</Callout>
    </AnimatedReveal>)
}

interface IFormRunControlProps {
  isApiActive: boolean;
};

const FormRunControl: React.FC<IFormRunControlProps> = (props) => {
  const { isSubmitting, isValid, isValidating, values: formFields, submitForm } = useFormikContext<IPersonFormState>();
  const canRunQuery = !!formFields.personId || !!formFields.lastName || !!formFields.firstName;
  const isSubmitDisabled = isSubmitting || isValidating || !isValid || !canRunQuery || props.isApiActive;

  // Clever Dirty Tricks Department:
  // Since the button below is intended to align with the row of input fields
  // to the right of it, (all of which have labels above them), by wrapping
  // the button with a FormGroup having a nonbreaking space label, that label
  // automatically assumes the same vertical size as the field labels, causing
  // the button to automatically align with the input fields.
  return (
    <FormGroup label="&nbsp;">
      <Button
        icon={IconNames.SEARCH}
        intent={Intent.PRIMARY}
        onClick={submitForm}
        disabled={isSubmitDisabled}
      >
        Run Query
      </Button>
    </FormGroup>
  );
}

export interface IPersonPanelProps {
  customerId: string;
  pageSize: number;
}

interface IProps extends IPersonPanelProps {
  personLabel: string;
  colGetter: TColDefGetterFunc;
  apiFunc: TPersonEntityApiFunc;
};

const PersonEntityViewerPanel: React.FC<IProps> = ({personLabel, customerId, pageSize, apiFunc, colGetter}) => {
  const [apiFilters, setApiFilters] = useState<IPersonFilters | null>(null);
  const [isApiCallActive, setIsApiCallActive] = useState(false);

  // Lifted state info for JsonDisplayDialog
  const [dlgContent, setDlgContent] = useState<TContentFragment>(null);
  const [dlgTitle, setDlgTitle] = useState<string>(`${personLabel} Detail`);
  const [isDlgOpen, setDlgOpen, onDlgClose] = useDialogState();
  const openJsonDlg = (argDlgContent: TContentFragment) => {
    setDlgContent(argDlgContent);
    const obj = (!!argDlgContent) ? argDlgContent.valueOf() as IPersonSnapshot : { id: "No ID" };
    setDlgTitle(`Customer [${customerId}] ${personLabel} [${obj?.id}]`);
    setDlgOpen();
  };

  const [initialFormValues, setInitialFormValues] = useState<IPersonFormState>({
    personId: undefined,
    lastName: undefined,
    firstName: undefined
  });

  const onValidateForm = useCallback((data: IPersonFormState) => {
    let errors: FormikErrorsEx<IPersonFormState> = {};
    if (!!data.personId && (!!data.lastName || !!data.firstName)) {
      errors[groupErrorsKey] = { "$form": `Either ${personLabel.toLowerCase()} ID or Name search should be specified, not both.` }
    }
    return errors;
  }, [personLabel]);

  const onFormSubmit = useCallback(
    async ({ personId, lastName, firstName }: IPersonFormState) => {
      setInitialFormValues({ personId, lastName, firstName });   // This preserves our last entries for any subsequent run
      setApiFilters({ customerId: customerId, personId, lastName, firstName });
      return true;
    },
    [customerId]);

  const dataLoader = useCallback(
    async (pageNum: number,
      pageSize: number,
      sorting?: IColumnSort<IPersonSearchResult>,
      filters?: IColumnFilters<IPersonSearchResult>) => {
      // This method should not be called until filter is set.
      // User must deliberately run the query (which sets API filter).
      if (!apiFilters) return await Promise.reject();
      setIsApiCallActive(true);
      try {
        const results = await apiFunc(
          apiFilters.customerId,
          apiFilters.personId,
          apiFilters.lastName,
          apiFilters.firstName,
          { pageNum, pageSize });

        return results;
      }
      finally {
        setIsApiCallActive(false);
      }
    }, [apiFilters, apiFunc]);

  return (
    <div style={{ marginTop: 0, marginBottom: 40 }}>
      <FormHost<IPersonFormState>
        formMode={FormModes.Edit}
        initialValues={initialFormValues}
        dontWarnIfDirty={true}
        formContentStyle={{ margin: "20px 0" }}
        onSave={onFormSubmit}
        validate={onValidateForm}
      >
        <FormHeader />

        <div className="columns">
          <div className="column is-2">
            <TextField
              label={`${personLabel} ID`}
              binding="personId"
              validations={personIdValidations}
            />
          </div>
          <div className="column is-2">
            <TextField
              label={isLocaleUS() ? "Last Name" : "Surname"}
              binding="lastName"
              validations={lastNameValidations}
            />
          </div>
          <div className="column is-2">
            <TextField
              label={isLocaleUS() ? "First Name" : "Forename"}
              binding="firstName"
              validations={firstNameValidations}
            />
          </div>
          <div className="column is-2">
            <FormRunControl isApiActive={isApiCallActive} />
          </div>
        </div>
      </FormHost>
      {
        apiFilters && <DataTableContainer
          pageSize={pageSize}
          columns={colGetter(openJsonDlg)}
          dataLoader={dataLoader}
          getRowKey="id"
          defaultSort={defaultSort}
          containerStyle={{ marginTop: 40 }}
        />
      }
      <JsonDisplayDialog title={dlgTitle} content={dlgContent} isOpen={isDlgOpen} onClose={onDlgClose} />
    </div >
  );
};

export default PersonEntityViewerPanel;
