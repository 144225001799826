import React from "react";
import { IPagedApiResponse } from "../../../models/PagedApiResponse";


export enum ContentAlignment { Left = "left", Center = "center", Right = "right" }

// export function getContentFlexAlignment(alignment?: ContentAlignment) {
//   if (!alignment) {
//     return undefined;
//   }

//   switch (alignment) {
//     case ContentAlignment.Left: return 'flex-start';
//     case ContentAlignment.Right: return 'flex-end';
//     case ContentAlignment.Center: return 'middle';
//     default:
//       throw new Error("Unknow content alignment value: " + alignment);
//   }
// }


export function getContentTextAlignment(alignment?: ContentAlignment) {
  switch (alignment) {
    //case ContentAlignment.Left: return 'left';
    case ContentAlignment.Right: return 'right';
    case ContentAlignment.Center: return 'center';
    default:
      return 'left';
      //throw new Error("Unknow content alignment value: " + alignment);
  }
}



export type FieldNames<TRow> = Extract<keyof TRow, string>; // Exclude numeric and symbol keys

// interface IColumnFieldOptions<TRow, TCol> {
//   name: FieldNames<TRow>;
//   /**
//    * Must return a primitive type: string, number, boolean, Date
//    */
//   accessor?: (row: TRow) => TCol;
//   formatter?: (value: unknown) => string;
//   renderer?: (value: TCol | string) => React.ReactNode;
//   comparer?: (a: TCol, b: TCol) => 0 | 1 | -1;
//   alignment: React.CSSProperties['textAlign'];
// }


// export interface IColumnSortingOptions {
//   disabled?: boolean;
//   //sortDescFirst?: boolean;
//   clientSortFn?: (a: unknown, b: unknown, desc: boolean) => 0 | 1 | -1;
// }


export interface IColumnFilteringOptions {
  disabled?: boolean;
  filter: unknown;
  //renderFilterUi: ()
}

export type IColumnFilters<TRow> = Partial<Record<FieldNames<TRow>, unknown>>;


export enum SortDirection { None, Asc, Desc }

export interface IColumnSort<TRow> {
  fieldName: FieldNames<TRow>;
  sortDirection: SortDirection;
}

//type IColumnSorts<TRow> = Set<IColumnSort<TRow>>;

// Specifies how the model fields map to corresponding ODAta field expressions.
// Used to build odata sort expressions.
export type ODataFieldMap<T> = Partial<Record<FieldNames<T>, string | string[]>>;

export function getODataSortExpression<T>(sort?: IColumnSort<T>, fieldMap?: ODataFieldMap<T>) {
  if (!sort) {
    return undefined;
  }

  const getSingleFieldSortExpression = (fieldName: string, sortDirection: SortDirection) => fieldName + (sortDirection === SortDirection.Desc ? ' desc' : '');

  const {fieldName, sortDirection} = sort;

  if (fieldMap) {
    const oDataFields = fieldMap[fieldName];

    if (typeof oDataFields === "string") {
      return getSingleFieldSortExpression(oDataFields, sortDirection);
    }
    else if (Array.isArray(oDataFields)) {
      return oDataFields.map(f => getSingleFieldSortExpression(f, sortDirection)).join(",")
    }
  }

  return getSingleFieldSortExpression(fieldName, sortDirection);
}


export type ServerDataLoader<TRow> = (pageNum: number, pageSize: number, sorting?: IColumnSort<TRow>, filters?: IColumnFilters<TRow>) => Promise<IPagedApiResponse<TRow>>;


export interface IColumnStyleOptions {
  className?: string;
  alignment?: ContentAlignment;
  minWidth?: string | number
  maxWidth?: string | number
  width?: string | number;
}



export interface IColumn<TRow, TCol=any> {
  header: string;
  name: FieldNames<TRow>;
  accessor?: (row: TRow) => TCol;
  formatter?: (value: TCol) => string;
  renderer?: (value: TCol | string, row: TRow, column: IColumn<TRow, TCol>) => React.ReactNode;
  comparer?: (a: TCol, b: TCol) => 0 | 1 | -1;
  disableSort?: boolean;
  defaultSortDirection?: SortDirection;
  styles?: IColumnStyleOptions;
}


// interface IClientDataOptions<TRow> {
//   pageSize?: number;
//   data: TRow[];
// }

export interface IServerDataOptions<TRow> {
  pageSize: number;
  loadData: ServerDataLoader<TRow>;
}
