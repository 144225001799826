import React, { useCallback, useState } from "react";
import { Button, FormGroup, Intent } from "@blueprintjs/core";
import { DateRange, DateRangeInput } from "@blueprintjs/datetime";
import { IconNames } from "@blueprintjs/icons";
import { ICustomer } from "../../../../models/Customer";
import { IClientLogEntry, IEgressLogEntry, IServiceLogEntry } from "../../../../models/LogEntry";
import { IPagedApiResponse } from "../../../../models/PagedApiResponse";
import { IApiPagingOptions } from "../../../../services/api/odata/models";
import { addDays } from "../../../../utils/dateUtils";
import DataTableContainer from "../../../shared/DataTable/DataTableContainer";
import { IColumn, IColumnFilters, IColumnSort, SortDirection } from "../../../shared/DataTable/models";
import useDialogState from "../../../shared/useDialogState";
import css from "../styles.module.scss";
import JsonDisplayDialog, { FnOpenJsonDialog, TContentFragment } from "./JsonDisplayDialog";


const formatDateOnly = (date?: Date) => !!date ? new Date(Date.parse(date.toString())).toLocaleDateString() : '';

export const defaultSort: IColumnSort<IClientLogEntry | IServiceLogEntry | IEgressLogEntry> = {
  fieldName: "createdOn",
  sortDirection: SortDirection.Desc
};

export interface ICosmoLogFilters {
  customerId: string;
  startEndDate: DateRange;
};

export type TLogColDef = IColumn<IClientLogEntry | IServiceLogEntry | IEgressLogEntry>;
type TActionsColumn = { _actions_?: string };
type TErrorCtColumn = { _errors_?: string };
export type TClientLogColDef = IColumn<IClientLogEntry & TActionsColumn>;
export type TServiceLogColDef = IColumn<IServiceLogEntry & TActionsColumn>;
export type TEgressLogColDef = IColumn<IEgressLogEntry & TActionsColumn & TErrorCtColumn>;
export type TColDefGetterFunc = (openJsonDlg: FnOpenJsonDialog) => TLogColDef[];

export type TLogItemApiFunc<TLogEntry> = (
  customerId: string,
  startDate?: Date,
  endDate?: Date,
  pagingOptions?: IApiPagingOptions,
  sortingOptions?: IColumnSort<TLogEntry>
) => Promise<IPagedApiResponse<TLogEntry>>;


interface IProps extends ICustomer {
  minDate: Date;
  maxDate: Date;
  initMinDate: Date;
  colGetter: TColDefGetterFunc;
  apiFunc: TLogItemApiFunc<IClientLogEntry | IServiceLogEntry | IEgressLogEntry>;
};

export const CustomerLogViewerPanel: React.FC<IProps> = (props) => {
  // Main component state
  const minDatePossible = props.minDate;
  const initialMinDate = props.initMinDate;
  const maxDatePossible = props.maxDate;
  const [uiFilters, setUiFilters] = useState<ICosmoLogFilters>({ customerId: props.id, startEndDate: [initialMinDate, maxDatePossible] });
  const [apiFilters, setApiFilters] = useState<ICosmoLogFilters | null>(null);
  const [isApiCallActive, setIsApiCallActive] = useState(false);

  // Lifted state info for JsonDisplayDialog
  const [dlgContent, setDlgContent] = useState<TContentFragment>(null);
  const [isDlgOpen, setDlgOpen, onDlgClose] = useDialogState();
  const openJsonDlg = (argDlgContent: TContentFragment) => {
    setDlgContent(argDlgContent);
    setDlgOpen();
  };

  // Make a safe copy of the input DateRange: will not contain a null or undefined
  const safeDateRange = (range: DateRange): DateRange => {
    // These are done in reverse order for a reason!
    const maxDate = !!(range?.[1]) ? new Date(range![1].setHours(23, 59, 59, 999)) : maxDatePossible;
    const minDate = !!(range?.[0]) ? new Date(range![0].setHours(0, 0, 0, 0)) : addDays(maxDate, -1);
    return [minDate, maxDate];
  };


  const dlgTitle = (props.name + ' (' + props.id + ')');

  const dataLoader = useCallback(
    async (pageNum: number,
      pageSize: number,
      sorting?: IColumnSort<IClientLogEntry | IServiceLogEntry | IEgressLogEntry>,
      filters?: IColumnFilters<IClientLogEntry | IServiceLogEntry | IEgressLogEntry>) => {
      // This method should not be called until filter is set.
      // User must deliberately run the query (which sets API filter from UI filter).
      if (!apiFilters) return await Promise.reject();

      setIsApiCallActive(true);
      try {
        const results = await props.apiFunc(
          apiFilters.customerId,
          apiFilters.startEndDate?.[0] ?? undefined,
          apiFilters.startEndDate?.[1] ?? undefined,
          { pageNum, pageSize } as IApiPagingOptions,
          sorting);
        return results;
      }
      finally {
        setIsApiCallActive(false);
      }
    }, [apiFilters, props]);  // should this be props.apiFunc?

  const onDateRangeChanged = (range: DateRange) => setUiFilters(current =>
    ({ ...current, startEndDate: safeDateRange(range) }));

  const runQuery = () => setApiFilters({ ...uiFilters });

  return (
    <div style={{ marginTop: 20, marginBottom: 40 }}>
      <div className="columns" style={{ width: "100%" }}>
        <FormGroup className="column">
          <div className={css.formGroupLabel}>Date Range</div>
          <DateRangeInput
            allowSingleDayRange={true}
            contiguousCalendarMonths={false}
            maxDate={maxDatePossible}
            minDate={minDatePossible}
            formatDate={date => formatDateOnly(date)}
            onChange={onDateRangeChanged}
            parseDate={str => new Date(Date.parse(str))}
            value={uiFilters.startEndDate}
          />
          &nbsp;
          <Button
            style={{ marginTop: 0 }}
            icon={IconNames.SEARCH}
            intent={Intent.PRIMARY}
            onClick={runQuery}
            disabled={isApiCallActive}
          >
            Run Query
          </Button>
        </FormGroup>
      </div>
      {apiFilters && <DataTableContainer
        pageSize={20}
        columns={props.colGetter(openJsonDlg)}
        dataLoader={dataLoader}
        getRowKey="id"
        defaultSort={defaultSort}
      />}
      <JsonDisplayDialog title={dlgTitle} content={dlgContent} isOpen={isDlgOpen} onClose={onDlgClose} />
    </div >
  );
}

export default CustomerLogViewerPanel;

