import React, { useMemo } from "react";
import OfficeAcceptedTermsContent from "./OfficeAcceptedTermsContent";
import { getOfficeEnrollmentData } from "../../../stores/enrollmentsStore";
import PromiseWait from '../../shared/PromiseWait';

const OfficeAcceptedTerms = () => {

  const promise = useMemo(getOfficeEnrollmentData, []);

  return (
    <PromiseWait promise={promise} component={OfficeAcceptedTermsContent} mapComponentProps={PromiseWait.mapAsIs} />
  );
};

export default OfficeAcceptedTerms;
