const routes = {
  login: { path: "/login" },
  home: { path: "/" },
  status: { path: "/status" },
  admin: { path: "/admin"},
  support: {
    root: { path: "/support" },
    customers: { path: "/support/customers/:customerId" }
  },


  officeMode: {
    root: { path: "/office/" },
    welcome: {
      path: "/office/welcome/:id",
      // Note: Temporary only. Used to support previously sent office emails.
      old: { path: "/ext/welcome/:id" }
    },
    confirmation: { path: "/office/confirm" },
    termsAccepted: { path: "/office/confirmed" },
  },

  partnerMode: {
    root: { path: "/partner/" },
    status: { path: "/partner/" }
  }
};

export default routes;
