import React, { memo, useCallback } from "react";
import { Intent, Button } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";

interface INavButton {
  title: string;
  icon: IconName;
  currentPageNum: number;
  destPageNum: number;
  maxPageNum: number;
  setPageNum: (pageNum: number) => void;
}


const NavButton: React.FC<INavButton> = memo(({title, icon, destPageNum, currentPageNum, setPageNum, maxPageNum}) => {
  const gotoPage = useCallback(() => setPageNum(destPageNum), [destPageNum, setPageNum]);

  return (
    <Button
      intent={Intent.PRIMARY}
      title={title}
      minimal={true}
      small={true}
      icon={icon}
      disabled={destPageNum === currentPageNum || destPageNum < 1 || destPageNum > maxPageNum}
      onClick={gotoPage}
    />
  );
})


export default NavButton;
