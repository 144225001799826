import axios from "axios";
import UrlAssembler from "url-assembler";


type AzureAdAppRegistration = {
  tenantName: string,
  clientId: string,
}

type AzureAdGroup = {
  Id: string,
  Name: string,
}

interface IEmployeeAzureAdConfig extends AzureAdAppRegistration {
  roles: {
    user: string,
    admin: string,
    support: string
  },
  groups: AzureAdGroup[],
}

interface IPartnerAzureAdConfig extends AzureAdAppRegistration {
  loginDomain?: string;
  scopes: string[],
  policies: {
    login: string;
  }
}

interface IAzureAdSettings {
  logMsalEvents?: boolean,
  employee: IEmployeeAzureAdConfig;
  partner: IPartnerAzureAdConfig;
}


type IAppConfigJson = {
  name: string;
  dataUrl: string;
  gatewayAdminUrl: string;
  agentInstallerUrl: string;
  buildVersion: string;
  localeId: number | undefined;
  azureAd: IAzureAdSettings
};


export interface IAppConfig {
  readonly name: string;
  readonly apiUrl: UrlAssembler;
  readonly gatewayAdminUrl: UrlAssembler;
  readonly oDataUrl: UrlAssembler;
  readonly agentInstallerUrl?: string;
  readonly buildVersion: string;
  readonly localeId: number | undefined;
  readonly azureAd: Readonly<IAzureAdSettings> & { logMsalEvents: boolean }
}


export class AppConfig implements IAppConfig {
  readonly name: string;
  //private readonly dataUrl: UrlAssembler;
  readonly apiUrl: UrlAssembler;
  readonly gatewayAdminUrl: UrlAssembler;
  readonly oDataUrl: UrlAssembler;
  readonly agentInstallerUrl?: string;
  readonly buildVersion: string;
  readonly localeId: number | undefined;
  readonly azureAd: Readonly<IAzureAdSettings> & { logMsalEvents: boolean };

  constructor(json: IAppConfigJson) {
    console.assert(json.name && json.dataUrl && json.buildVersion && json.localeId);
    this.name = json.name;

    const dataUrl = new UrlAssembler(json.dataUrl);
    this.apiUrl = dataUrl.segment("api/");
    const gatewayAdminUrl = new UrlAssembler(json.gatewayAdminUrl);
    this.gatewayAdminUrl = gatewayAdminUrl.segment("api/v1/");

    this.oDataUrl = dataUrl.segment("odata/");

    this.agentInstallerUrl = json.agentInstallerUrl;
    this.buildVersion = json.buildVersion;
    this.localeId = json.localeId;

    const logMsalEvents = !!json.azureAd.logMsalEvents;
    this.azureAd = { ...json.azureAd, logMsalEvents };
    if (!this.azureAd.partner.loginDomain)
      this.azureAd.partner.loginDomain = `${this.azureAd.partner.tenantName}.b2clogin.com`;
  }
}

let loadedConfig: IAppConfig;

// Load config file from "/public/config.json"
const configFileName = "config.json";
const configUrl = process.env.PUBLIC_URL + "/" + configFileName;

async function internalLoadConfig() {
  try {
    const configResponse = await axios.get<IAppConfigJson>(configUrl);
    loadedConfig = new AppConfig(configResponse.data);
    return loadedConfig;
  }
  catch (e) {
      throw new Error(`Failed to load app configuration (${configFileName})`);
  }
}


const configLoaderPromise = internalLoadConfig();
// The following isn't strictly needed -- we could just export `cachedConfigLoader` directly.
// However, making it a function makes for a better API. Ex: Instead of `loadConfig.then(...)`, caller will need to use `loadConfig().then(...)`
const loadConfig = () => configLoaderPromise;

function getLoadedConfig() {
  if (!loadedConfig) {
    throw new Error("App Configuration hasn't been loaded!")
  }

  return loadedConfig;
}

export { getLoadedConfig as default, loadConfig };




