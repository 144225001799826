import IOfficeAuthToken from "../../models/OfficeAuthToken";

const officeTokenKey = "_office_";

export function setOfficeAuthToken(token: IOfficeAuthToken) {
  console.assert(token && token.token && token.expires_in && token.token_type);
  sessionStorage.setItem(officeTokenKey, JSON.stringify(token));
}

export function getOfficeAuthToken() {
  const data = sessionStorage.getItem(officeTokenKey);
  if (data) {
    var token = JSON.parse(data) as IOfficeAuthToken;
    console.assert(token && token.token && token.expires_in && token.token_type);
    return token;
  }
  return null;
}
