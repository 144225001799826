import React, { memo, useMemo } from "react";
import { Icon, Intent } from "@blueprintjs/core";
import { IconName, IconNames } from "@blueprintjs/icons";


interface IProps {
  value: boolean;
  trueIcon?: IconName;
  falseIcon?: IconName;
  intent?: Intent;
  style?: React.CSSProperties;
}

const BooleanIcon = memo(({value, trueIcon  = IconNames.TICK, falseIcon = IconNames.DOT, intent=Intent.SUCCESS, style}: IProps) => {
  const _style = useMemo(() => ({opacity: value ? 1 : 0.3, ...style}), [style, value]);
  return (
    <Icon
      icon={value ? trueIcon : falseIcon}
      intent={value ? intent : Intent.NONE}
      style={_style}
    />)
});

export default BooleanIcon;
