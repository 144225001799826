import React, { memo, useMemo } from "react";
import { IconNames } from "@blueprintjs/icons";
import css from "../../dataTable.module.scss";
import NavButton from "./NavButton";
import PageSelector from "./PageSelector";


interface IProps {
  pageSize: number;
  pageNum: number;
  totalRecords: number;
  setPageNum: (pageNum: number) => void;
}



const Pager: React.FC<IProps> = memo(({pageSize, pageNum, setPageNum, totalRecords}) => {
  const totalPages = useMemo(() => Math.ceil(totalRecords / pageSize), [pageSize, totalRecords]);

  if (totalPages < 2) {
    return null;
  }

  return (
    <div className={css.pager}>
      <NavButton
        title="First Page"
        icon={IconNames.DOUBLE_CHEVRON_LEFT}
        destPageNum={1}
        currentPageNum={pageNum}
        maxPageNum={totalPages}
        setPageNum={setPageNum}
      />

      <NavButton
        title="Previous Page"
        icon={IconNames.CHEVRON_LEFT}
        destPageNum={pageNum-1}
        currentPageNum={pageNum}
        maxPageNum={totalPages}
        setPageNum={setPageNum}
      />

      &nbsp;

      Page&nbsp;
      <PageSelector pageSize={pageSize} pageNum={pageNum} totalRecords={totalRecords} setPageNum={setPageNum} />
      &nbsp;of {totalPages}

      &nbsp;

      <NavButton
        title="Next Page"
        icon={IconNames.CHEVRON_RIGHT}
        destPageNum={pageNum+1}
        currentPageNum={pageNum}
        maxPageNum={totalPages}
        setPageNum={setPageNum}
      />

      <NavButton
        title="Last Page"
        icon={IconNames.DOUBLE_CHEVRON_RIGHT}
        destPageNum={totalPages}
        currentPageNum={pageNum}
        maxPageNum={totalPages}
        setPageNum={setPageNum}
      />
    </div>
  );
});


export default Pager;
