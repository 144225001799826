import React, { memo, useMemo, useCallback, useEffect } from "react";
import { Button, Intent, Icon, IconName } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import reactModal from "@prezly/react-promise-modal";
import BaseDialog from "./BaseDialog";
import useDialogState from "../useDialogState";
import TextSpan from "../TextSpan";



interface IProps  {
  title: string;
  errorMessage?: string;
  canClose?: boolean;
  onClosed?: () => void;
}


const ErrorDialog: React.FC<IProps> = memo(({
  title,
  errorMessage,
  canClose = true,
  onClosed}) => {
  const [isOpen, open, close] = useDialogState();

  const handleClose = useCallback(() => {close(); onClosed && onClosed()}, [close, onClosed]);

  const actions = useMemo(() => canClose &&
    <div className="centered">
      <Button
        text="Close"
        //intent={Intent.WARNING}
        onClick={handleClose}
        style={{minWidth: 120, margin: '0 1em'}}
      />
    </div>
    , [canClose, handleClose]);

  useEffect(() => {
    if (errorMessage) open(); else close();
  }, [open, close, errorMessage]);

  return (
      <BaseDialog
        title={title}
        icon={IconNames.ERROR}
        isOpen={isOpen}
        onClose={handleClose}
        canEscapeKeyClose={canClose}
        isCloseButtonShown={canClose}
        actions={actions}
      >
        <TextSpan intent={Intent.DANGER}>{errorMessage}</TextSpan>
      </BaseDialog>
  );
});


const errorDialogPromise = (
  error: string | Error | React.ReactElement | React.ReactFragment,
  title = "Error",
  icon: IconName = IconNames.ERROR
  ) => {

  const content = error instanceof Error
    ? <TextSpan intent={Intent.DANGER}>{error.message}</TextSpan>
    : typeof error === "string"
      ? <TextSpan intent={Intent.DANGER}>{error}</TextSpan>
      : error;

  return reactModal(({show: isOpen, onSubmit: onClose}) => {
    const closeButton =
      <div className="centered">
        <Button
          text="Close"
          intent={Intent.PRIMARY}
          onClick={onClose}
        />
      </div>;

    return (
      <BaseDialog
        title={<span className="intent-danger">{title}</span>}
        icon={icon && <Icon icon={icon} intent={Intent.DANGER} />}
        isOpen={isOpen}
        onClose={onClose}
        canEscapeKeyClose={true}
        isCloseButtonShown={true}
        actions={closeButton}
      >
      {content}
    </BaseDialog>)});
}

export { errorDialogPromise }

export default ErrorDialog;
