import React, { useMemo } from "react";
import senseiLogo from "../../assets/images/21612 Sensei Logos_Services Enrollment.svg";
import css from "./styles.module.scss";
import Page from "../shared/Page";

const Header = () => {
  return (
    <>
      <div className={css.pageHeader} style={{ paddingBottom: 0 }}>
        <img src={senseiLogo} alt="Sensei Logo" style={{ width: 370, paddingRight: 70 }} />
      </div>
    </>
  );
};

interface IProps {
  showCsdFlow?: boolean;
  contentContainerClassName?: string;
}

const Layout: React.FC<IProps> = ({
  children,
  showCsdFlow,
  contentContainerClassName,
}) => {
  const header = useMemo(() => <Header />, []);

  return (
    <Page
      header={header}
      className={showCsdFlow ? "csd-flow" : ""}
      headerContainerStyle={{ backgroundColor: "#fff", border: "none" }}
      contentContainerClassName={contentContainerClassName}
    >
      {children}
    </Page>
  );
};

export default Layout;
