import React, {  useState } from "react";
import { IAgentUpdateResponse } from "../../../../models/AgentUpdateResponse";
import AgentUpdateForm from "./UpdateForm";
import UpdateResults from "./UpdateResults";


interface IProps {
}


const AgentUpdatePanel: React.FC<IProps> = () => {
  const [results, setResults] = useState<IAgentUpdateResponse[] | undefined>();

  return (
    <>
      <AgentUpdateForm onAgentUpdatesSent={setResults} />
      {results && <UpdateResults data={results} />}
    </>
  )
}

export default AgentUpdatePanel;
