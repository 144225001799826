import React from "react";
import { Route, RouteProps } from "react-router";
import useAuthService, { AuthenticatedTemplate } from "../../services/authService";
import AccessDenied from "./AccessDenied";

interface IProps extends RouteProps {
  allowedRoles: string[];
  allowedGroups?: string[];
}


const ProtectedRoute: React.FC<IProps> = (props) => {
  const { isLoggedIn, isInRole, isInGroup } = useAuthService();
  const { allowedRoles, allowedGroups = [], ...routeProps } = props;
  console.assert(isLoggedIn);
  const isAllowedAccess = isInRole(allowedRoles) || isInGroup(allowedGroups);

  return (
    <AuthenticatedTemplate>
      {isAllowedAccess ? <Route {...routeProps} /> : <AccessDenied /> }
    </AuthenticatedTemplate>
  );
}


export default ProtectedRoute;
