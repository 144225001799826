import React, { memo, useEffect } from "react";
import { Switch, Route, useParams, generatePath, Redirect } from "react-router";
import { PageNotFound } from "./ErrorPage";
import { AppModes, useAppMode, isAppMode } from '../../services/appModeService';
import routes from "../../routePaths";
import Welcome from "./Welcome";
import Confirmation from "./Confirmation";
import OfficeAcceptedTerms from "./OfficeAcceptedTerms";
import { getOfficeAuthToken } from "../../services/authService/officeTokenStorage";




// Apr 14, 2021: Office mode URL route format was changed from "/ext/" to "/office/". However, some offices may
// still have old emails containing the old links.  As such, we need to support both old and new routes for a while.
// Note that this is a temporary workaround. This can be removed once we know there are no unprocessed links out
// there containing with old URL format (likely in a month or two).
export const OldOfficeMode: React.FC = () => {
  // Redirect the user to the new office mode path
  const { id } = useParams<{ id: string }>();
  const redirectpath = generatePath(routes.officeMode.welcome.path, {id});
  return <Redirect to={redirectpath} />
}


const OfficeMode: React.FC = memo(() => {
  const { setAppMode } = useAppMode();
  useEffect(() => setAppMode(AppModes.Office), [setAppMode]);

  if (!isAppMode(AppModes.Office))
    return null; // Wait until mode switch effect runs

  const isLoggedIn = !!getOfficeAuthToken();
  if (!isLoggedIn) {
    return (
      <Switch>
        <Route path={routes.officeMode.welcome.path} exact={true} component={Welcome}/>
        <Route component={PageNotFound} />
      </Switch>
    );
  }

  // Office user is has logged in
  return (
    <Switch>
      <Route path={routes.officeMode.confirmation.path} exact={true} component={Confirmation} />
      <Route path={routes.officeMode.termsAccepted.path} exact={true} component={OfficeAcceptedTerms} />
      <Route component={PageNotFound} />
    </Switch>
  );
});

export default OfficeMode;
