import React, { useCallback, useState } from "react";
import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { IEsvcsEnrollment } from "csd.phoenix.models/EsvcsEnrollment";
import { resendEmail } from "../../../stores/enrollmentsStore";
import { showToast } from "../../../services/ToasterService";
import { EsvcsEnrollmentStatuses } from 'csd.phoenix.models/EsvcsEnrollmentStatuses';
import Wait from "../Wait";
import { Formik } from "formik";
import TextField from "../form/fields/TextField";
import { IValidation, ValidationType } from "../form/models/validation";
import { FormContextProvider } from "../form/FormContext";
import { FormModes } from "../form/models/baseTypes";
import { getLocale } from "../../../i18n";
import { EsvcsProductOfferings } from "csd.phoenix.models/EsvcsProductOfferings";

// interface IEmailActions {
//   okButtonLabel?: string;
//   okButtonIntent?: Intent;
//   onOk: () => void;
//   onCancel: () => void;
//   isDisabled?: boolean;
// }

const emailValidations: IValidation[] = [
  { type: ValidationType.Required },
  { type: ValidationType.Email },
  { type: ValidationType.Length, max: 128 }
];

interface IFormFields {
  email: string;
}

interface IEmailDialogProps {
  title?: string;
  buttonLabel?: string;
  isOpen: boolean;
  enrollment: IEsvcsEnrollment;
  onClose:  () => void;
}

export const EmailDialog: React.FC<IEmailDialogProps> = ({ title = "Re-send Email", buttonLabel = "OK", isOpen, onClose, enrollment }) => {
  const [isLoading, setIsLoading] = useState(false);


  const initialValues: IFormFields = {
    email: enrollment.AdminUserEmail,
  };

  const onSubmit = useCallback((values: IFormFields) => {
    setIsLoading(true);
    if (enrollment.RecordId) {
      enrollment.AdminUserEmail = values.email;
      resendEmail(enrollment.RecordId, values.email).then((r) => {
        showToast("Email successfully sent.", Intent.SUCCESS);
      }).catch((e) => {
        let message = "There was an error re-sending the email.";
        if (enrollment.EnrollmentStatus === EsvcsEnrollmentStatuses.Confirmed)
          message += "  Please verify customer database exists."

        showToast(message, Intent.DANGER);
      }).finally(() => {
        setIsLoading(false);
        onClose();
      });
    }
  }, [onClose, enrollment]);

  const getEmailType = useCallback(() => {
    switch (enrollment.EnrollmentStatus) {
      case EsvcsEnrollmentStatuses.GatewayProvisioned:
        return "Client Installer Email";
      case EsvcsEnrollmentStatuses.Completed:
        return "Product Welcome Email";
      default:
        return "Terms & Conditions Email";
    }
  }, [enrollment]);

  const emailType = getEmailType();

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} >
      {({ isSubmitting, isValidating, isValid, handleSubmit }) => {
        const isBusy = isSubmitting || isValidating;
        const canSubmit = !isBusy && isValid;


        return (
          <FormContextProvider formMode={FormModes.Edit} locale={getLocale()}>
            <Dialog
              style={{ width: 550 }}
              isOpen={true}
              canEscapeKeyClose={false}
              canOutsideClickClose={false}
              isCloseButtonShown={true}
              title={title}
              onClose={onClose}
              icon={IconNames.ENVELOPE}
            >
              {isLoading && <Wait showBorder={false} style={{ padding: '1em' }} />}

              <form onSubmit={handleSubmit}>

                {!isLoading &&
                  <div className={Classes.DIALOG_BODY}>
                    Would you like to re-send the {emailType} for {enrollment.CustomerName}?
                    <div hidden={(enrollment.ProductOffering === EsvcsProductOfferings.PartnerApi && enrollment.EnrollmentStatus === EsvcsEnrollmentStatuses.Completed)}
                      style={{ paddingTop: '1em', maxWidth: 500, textAlign: 'left' }}>
                        <TextField
                          label="Comfirm Email Address:"
                          binding="email"
                          validations={emailValidations}
                        />
                    </div>
                  </div>
                }

                {!isLoading &&
                  <div className={Classes.DIALOG_FOOTER} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '1em' }}>
                    <Button type="submit" disabled={!canSubmit} intent={Intent.SUCCESS} style={{ width: 140 }}>
                      {isBusy ? <Wait rightOrBottomText="Please Wait ..." showBorder={false} style={{ background: 'unset' }} /> : "OK"}
                    </Button>
                    <Button
                      text="Cancel"
                      intent={Intent.WARNING}
                      onClick={onClose}
                      disabled={isLoading}
                      style={{ minWidth: 120, margin: '0 1em' }}
                    />
                  </div>
                }

               </form>
            </Dialog>
          </FormContextProvider>);
      }}
    </Formik>

  );
}
