import React from "react";
import { Classes } from "@blueprintjs/core";

interface ITermsAndCondition {
  terms: string;
}

const TermsAndConditions: React.FC<ITermsAndCondition> = ({ terms }) => {
  return (
    <>
      <div className="elevated" style={{ margin: "30px 0" }}  >
        <div
          style={{
            overflowY: "auto",
            maxHeight: 300,
            margin: 0,

          }}
        >
          <div
            style={{
              backgroundColor: "white",
              opacity: 0.9,
              padding: "4px",
            }}
          >
            <div className={Classes.RUNNING_TEXT} dangerouslySetInnerHTML={{ __html: terms }} style={{ padding: 15 }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
