import React, { memo } from "react";
import { Switch, Redirect, Route } from "react-router";
import routes from "../../routePaths";
import Login from "../auth/Login";
import NewEnrollment from "../Pages/NewEnrollment";
import EnrollmentStatus from "../Pages/EnrollmentStatus";
import ShowClaims from "./ShowAuthClaims";
import { AppModes, useAppMode } from '../../services/appModeService';
import useAuthService, { UnauthenticatedTemplate, AuthenticatedTemplate} from "../../services/authService";
import useAppConfig from "../shared/useAppConfig";
import ProtectedRoute from "../routing/ProtectedRoute";
import NotFound from "../routing/NotFound";
import PageLayout from "../Pages/PageLayout";
import { SupportPageRoutes } from "../Pages/SupportPage";
import AdminPage from "../AdminPage";

const Routes = () => {
  const { azureAd: { employee }  } = useAppConfig();

  return (
    <Switch>
      <ProtectedRoute path={routes.home.path} exact={true} component={NewEnrollment} allowedRoles={[employee.roles.user, employee.roles.admin]} />
      <ProtectedRoute path={routes.status.path} exact={true} component={EnrollmentStatus} allowedRoles={[employee.roles.admin, employee.roles.support, employee.roles.user]} />
      <ProtectedRoute path={routes.admin.path} exact={true} component={AdminPage} allowedRoles={[employee.roles.admin]} />
      <ProtectedRoute path={routes.support.root.path} component={SupportPageRoutes} allowedRoles={[employee.roles.admin, employee.roles.support]} />
      <ProtectedRoute path="/user" exact={true} component={ShowClaims} allowedRoles={[employee.roles.admin, employee.roles.support, employee.roles.user]} />
      <Redirect to={routes.home.path} />
    </Switch>);
}

const UserMode: React.FC = memo(() => {
  // If a partner or office user has logged in, we don't want to allow the user to navigate to user mode screens.
  const { appMode } = useAppMode();
  const authService = useAuthService();
  //const { salesAdGroup } = useAppConfig();

  //console.log("UserMode - allowedGroups =====>", allowedGroups);

  if (appMode !== AppModes.NotSet && appMode !== AppModes.Employee) {
    return <NotFound />;
  }



  return (
    <>
      <UnauthenticatedTemplate>
        <Login isPartnerLogin={false} />
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <PageLayout>
          {authService.isInDefaultAccessGroup() ?
            <Route component={EnrollmentStatus} /> : <Routes />}
        </PageLayout>
      </AuthenticatedTemplate>
    </>);
})


export default UserMode;
