import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import { FormGroup, PopoverProps, Intent, Keys } from "@blueprintjs/core";
import { DateInput3 } from "@blueprintjs/datetime2";
import classnames from "classnames";
import { localDateToSameUtcDate, utcDateToSameLocalDate } from "csd.phoenix.models/Date_Factory";
import { FastField, FieldProps, getIn } from "formik";
import isDate from "lodash.isdate";
import { getLocaleLanguageCulture, toLocaleId } from "../../../../i18n";
import { formatDate as _formatDate, defaultDateFormatString } from "../../../../utils/dataFormatters";
import { getDate, today as getToday, parseLocaleDate } from "../../../../utils/dateUtils";
import RequiredIndicator from "../../../shared/RequiredIndicator";
import useFormContext from "../FormContext";
import css from "../form.module.scss";
import { IDateFieldProps } from "../models";
import { useAutoComplete } from "../models/utils";
import {
  IDateValidation,
  ValidationType,
  findValidation,
  getDateFromDescription,
  hasRequiredValidation,
  validationMethodFactory
} from "../models/validation";
import PrintedTextField from "./PrintedTextField";
import moment from "moment";

// Note: We must provide min and max dates to BluePrint.DateInput,
// since the default range is  too limited for our use cases.
const today = getToday();
const defaultMinDate = new Date(today.getFullYear() - 120, today.getMonth(), today.getDate());
const defaultMaxDate = new Date(today.getFullYear() + 10, today.getMonth(), today.getDate());

function isValidDate(uiDate: Date | null) {
  return uiDate && isDate(uiDate) && !isNaN(uiDate.valueOf());
}

function formatDate(date: Date | null, locale: string) {
  return _formatDate(date, toLocaleId(locale), defaultDateFormatString(toLocaleId(locale)), false);
}

// Note: BluePrint `DateInput` expects specific return values from `parseDate()`.
function parseDate(str: string, locale?: string): Date | false | null {
  if (!str) {
    // Important: We must return `false` rather than `null` if the string contains all spaces.
    return null;
  }

  const date = parseLocaleDate(str, toLocaleId(locale));
  return isValidDate(date) ? date : false;
}

// function popoverBlur(): {
//   if (!str) {
//     // Important: We must return `false` rather than `null` if the string contains all spaces.
//     return null;
//   }

//   const date = parseLocaleDate(str, locale);
//   return isValidDate(date) ? date : false;
// }


type Props<T = any> = FieldProps<T> & { fieldProps: IDateFieldProps };

const BoundDateField = ({ field, form, fieldProps }: Props) => {
  const { locale, isEditing, isPrinting, fieldChangedSignal, fieldPropOverrides } = useFormContext();
  const autoComplete = useAutoComplete(fieldProps);
  const error = (form.submitCount || getIn(form.touched, field.name)) && getIn(form.errors, field.name);
  const intent = error ? Intent.DANGER : Intent.NONE;
  const isRequired = hasRequiredValidation(fieldProps.validations);
  const disabled = fieldProps.disabled ?? false;
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const onFocus = useCallback(() => {
    setIsOpen(true);
    initialValue.current = field.value;

  }, [field.value]);


  const onKeyDownCapture = useCallback((e: React.KeyboardEvent) => {
    if (e.keyCode === Keys.TAB || e.keyCode === Keys.ESCAPE) {
      setIsOpen(false);
    }

  }, []);


  const onBlur = useCallback((e: React.FocusEvent<HTMLDivElement>) => {
    // Is the focus moving outside of the container?
    // If so, close the calendar popover.
    if (e.relatedTarget) {
      const allDescendents = Array.from(containerRef.current!.querySelectorAll("*"));
      const isOutside = allDescendents.every(node => e.relatedTarget !== node);

      isOutside && setIsOpen(false);
    }
  }, []);

  const popoverProps = useMemo<PopoverProps>(() => ({
    fill: true,
    isOpen: isOpen,
    usePortal: false,

    onInteraction: (nextOpenState: boolean) => {
      setIsOpen(nextOpenState);
    },

    onClosed: () => {
      form.setFieldTouched(field.name);
      field.value?.valueOf() !== initialValue.current?.valueOf() && fieldChangedSignal.dispatch(field.name, field.value, initialValue.current);
      initialValue.current = field.value;
    }

  }), [field.name, field.value, fieldChangedSignal, form, isOpen]);

  const className = classnames(css.formPart);
  const dateValidation = findValidation<IDateValidation>(ValidationType.Date, fieldProps.validations)
  const minDate = fieldProps.minDate || getDateFromDescription(dateValidation && dateValidation.min) || defaultMinDate;
  const maxDate = fieldProps.maxDate || getDateFromDescription(dateValidation && dateValidation.max) || defaultMaxDate;
  //console.log(`DATE RANGE: ${minDate.toDateString()} - ${maxDate.toDateString()}`);

  const { isTimeZoneAgnostic = true } = fieldProps;
  const { setFieldValue } = form;

  const onChange = useCallback((date: string | null) => {
    const uiDate = (date && moment(date).toDate()) || undefined;
    if (uiDate && isValidDate(uiDate) && uiDate >= minDate && uiDate <= maxDate) {
      const dateField = isTimeZoneAgnostic ? localDateToSameUtcDate(uiDate) : getDate(uiDate);
      setFieldValue(field.name, dateField);
    }
    else {
      // Date field is empty or invalid. Leave old date unchanged..
      setFieldValue(field.name, undefined);
    }

    setIsOpen(false);
  }, [field.name, minDate, maxDate, isTimeZoneAgnostic, setFieldValue]);

  // When using data retrieved from a saved Form intance JSON, this will be a string.
  let uiDate: Date | null = typeof field.value === 'string' ? new Date(field.value) : field.value;
  const initialValue = useRef(uiDate);
  if (isValidDate(uiDate)) {
    uiDate = isTimeZoneAgnostic ? utcDateToSameLocalDate(uiDate!) : getDate(uiDate!);
    if (uiDate < minDate || uiDate > maxDate) {
      uiDate = null;
    }
  }
  else {
    uiDate = null;
  }

  const localeString = getLocaleLanguageCulture();
  const strDate: string | null = uiDate ? formatDate(uiDate, localeString) : null;

  const control = isPrinting
    ? <PrintedTextField value={formatDate(uiDate, localeString)} />
    : <div ref={containerRef} onBlur={onBlur}>
      <DateInput3
        locale={localeString}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder={isEditing ? (fieldProps.prompt || defaultDateFormatString(locale)) : undefined}
        disabled={!isEditing || disabled}
        minDate={minDate}
        maxDate={maxDate}
        popoverProps={popoverProps}
        inputProps={{ intent, autoComplete, onFocus, onKeyDownCapture }}
        value={strDate}
        shortcuts={fieldProps.showShortcuts}
        onChange={onChange}
        canClearSelection={false}
        {...fieldPropOverrides[field.name]}
      />
    </div>;

  return (
    <FormGroup
      label={fieldProps.label}
      labelInfo={<RequiredIndicator show={isRequired} isPrinting={isPrinting} />}
      intent={intent}
      helperText={isEditing ? (error || <>&nbsp;</>) : undefined}
      style={fieldProps.style}
      className={className}
    >
      {control}
    </FormGroup>
  )
}


const DateField = memo((props: IDateFieldProps) => {
  console.assert(props && props.binding);
  const { isEditing } = useFormContext();
  const validate = isEditing ? validationMethodFactory(props) : undefined;
  return <FastField name={props.binding} fieldProps={props} component={BoundDateField} validate={validate} />;
});


export default DateField;






