import { useEffect, useCallback }  from "react";

// Show window-unload warning if flag is set (ex. if a form is dirty)
const useWarnBeforeWindowUnload = (warn = false) => {
  const handleBeforeWindowUnload = useCallback((e: BeforeUnloadEvent) => {
    if (warn === true) {
      // Ref: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
      e.preventDefault(); // Cancel the event
      e.returnValue = ''; // Chrome requires returnValue to be set
    }
  }, [warn]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeWindowUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeWindowUnload);
  }, [handleBeforeWindowUnload]);

}

export default useWarnBeforeWindowUnload;
