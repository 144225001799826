import React from "react";
import css from "./RequiredIndicator.module.scss";

const RequiredIndicator: React.FC<{show: boolean, isPrinting?: boolean }> = ({ show, isPrinting = false }) => {
  if (!show) {
    return null;
  }

  if (isPrinting) {
    return <span>*</span>;
  }

  return <span className={css.requiredFieldLabel} title="This field is required" />
};


export default RequiredIndicator;
