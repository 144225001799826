import React, { useState, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import { Intent, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { IEsvcsEnrollment } from "csd.phoenix.models/EsvcsEnrollment";
import { IEsvcsEnrollmentDpmsVersions } from "csd.phoenix.models/EsvcsEnrollmentDpmsVersions"
import { DpmsTypes, DpmsTypes_Descriptions} from "csd.phoenix.models/DpmsTypes";
import {
  ValidationType,
  IValidation,
} from "../../../shared/form/models/validation";
import { IFieldOption } from "../../../shared/form/models";
import TextField from "../../../shared/form/fields/TextField";
import SelectField from "../../../shared/form/fields/SelectField";
import { showToast } from "../../../../services/ToasterService";
import SectionLite from "../../../shared/Page/SectionLite";
import { handleFieldChanged, handleFormLoaded } from "./changeHandlers";
import useFieldChangeTracker from "../../../shared/form/useFieldChangeTracker";
import { isLocaleUS } from "../../../../i18n";
import { getProductList, getDpmsList } from '../../../shared/ListData';
import TimeZoneField from "../../../shared/form/fields/composite/TimeZoneField";

const requiredValidation: IValidation[] = [{ type: ValidationType.Required }];

const emailValidation: IValidation[] = [
  { type: ValidationType.Required },
  { type: ValidationType.Length, max: 128 },  //Restrict length of email address to the size of the database field
  { type: ValidationType.Email },
];

const ccEmailValidation: IValidation[] = [
  { type: ValidationType.Length, max: 128 },  //Restrict length of email address to the size of the database field
  { type: ValidationType.Email },
];

const sapIdValidations: IValidation[] = [
  { type: ValidationType.Required },
  { type: ValidationType.SapId },
];

const versionNumberValidation: IValidation[] = [
  //{ type: ValidationType.Required },
  { type: ValidationType.Length, max: 10 },
];

const timeZoneValidation: IValidation[] = [
  { type: ValidationType.Length, max: 50 },
];

const nameValidation: IValidation[] = [
  { type: ValidationType.Required },
  { type: ValidationType.Length, max: 128 }
];

const adminUserDesc = (
  <div>
    <div>
      Primary/Admin person. A welcome email will be sent to the email address.
    </div>
    <div className="intent-warning">
      <Icon intent={Intent.DANGER} icon={IconNames.WARNING_SIGN} />
      &nbsp;<strong>Important:</strong>&nbsp; This user will become the admin
      user in Sensei cloud services, and have access to all <strong>PHI</strong>
      &nbsp;(and potentially financial) data for this practice.
      <br />
      This user will also have the ability to create/manage other users in the
      Sensei cloud services environment.
      <br />
      As such, the provided email address should belong to an{" "}
      <em>individual</em> person (i.e. not an org, group, or distribution list
      address.)
    </div>
  </div>
);

const FormContent: React.FC = () => {
  useFieldChangeTracker(handleFieldChanged, handleFormLoaded);
//  const [partners, setPartners] = useState<IFieldOption[]>([]);
  const [sdVersions, setSdVersions] = useState<IFieldOption[]>([]);
  const [otVersions, setOtVersions] = useState<IFieldOption[]>([]);
  const [omsVersions, setOmsVersions] = useState<IFieldOption[]>([]);
  const [pwVersions, setPwVersions] = useState<IFieldOption[]>([]);
  const [r4Versions, setR4Versions] = useState<IFieldOption[]>([]);
  const [t4Versions, setT4Versions] = useState<IFieldOption[]>([]);

  const { values: { DpmsType: dpmsType } } = useFormikContext<IEsvcsEnrollment>();

  const dpmsVersions = useMemo(() => {
    switch (dpmsType) {
      case DpmsTypes.SoftDent: return sdVersions;
      case DpmsTypes.Orthotrac: return otVersions;
      case DpmsTypes.R4: return r4Versions;
      case DpmsTypes.WinOMS: return omsVersions;
      case DpmsTypes.PracticeWorks: return pwVersions;
      case DpmsTypes.T4: return t4Versions;
      default: return [];
    }
  }, [dpmsType, sdVersions, otVersions, r4Versions, omsVersions, pwVersions, t4Versions]);

  useEffect(() => {
    let isComponentLoaded = true;

    // async function loadPartnerList() {
    //   try {
    //     const _partners = await getPartners();
    //     const options = _partners.map((p) => ({ value: p.Id, text: p.Name }));
    //     isComponentLoaded && setPartners(options);
    //   } catch (e) {
    //     showToast("Failed to load partner list!", Intent.DANGER);
    //     setPartners([]);
    //   }
    // }

    async function loadDpmsVersions() {
      try {
        // Currently hard-coding the available enrollment versions.
        // Ultimately we want to export the TS for the shared enum from Backplane.Data -> DpmsVersion
        const _dpmsVersions: IEsvcsEnrollmentDpmsVersions[] = [
          { DpmsType: DpmsTypes.SoftDent, Description: DpmsTypes_Descriptions.SoftDent, DpmsVersion: "16.2.8+"},
          { DpmsType: DpmsTypes.Orthotrac, Description: DpmsTypes_Descriptions.Orthotrac, DpmsVersion: "14.0+"},
          { DpmsType: DpmsTypes.WinOMS, Description: DpmsTypes_Descriptions.WinOMS, DpmsVersion: "10+" },
          { DpmsType: DpmsTypes.PracticeWorks, Description: DpmsTypes_Descriptions.PracticeWorks, DpmsVersion: "10.1+"}
        ]

          if (!isComponentLoaded) {
          return;
        }
        // SoftDent
        const sdOptions = _dpmsVersions.filter((d) => d.DpmsType === DpmsTypes.SoftDent.valueOf());
        setSdVersions(sdOptions.map((d) => ({ value: d.DpmsVersion, text: d.DpmsVersion })));
        // OrthoTrac
        const otOptions = _dpmsVersions.filter((d) => d.DpmsType === DpmsTypes.Orthotrac.valueOf());
        setOtVersions(otOptions.map((d) => ({ value: d.DpmsVersion, text: d.DpmsVersion })));
        // WinOMS
        const omsOptions = _dpmsVersions.filter((d) => d.DpmsType === DpmsTypes.WinOMS.valueOf());
        setOmsVersions(omsOptions.map((d) => ({ value: d.DpmsVersion, text: d.DpmsVersion })));
        // PracticeWorks
        const pwOptions = _dpmsVersions.filter((d) => d.DpmsType === DpmsTypes.PracticeWorks.valueOf());
        setPwVersions(pwOptions.map((d) => ({ value: d.DpmsVersion, text: d.DpmsVersion })));
        // R4
        const r4Options = _dpmsVersions.filter((d) => d.DpmsType === DpmsTypes.R4.valueOf());
        setR4Versions(r4Options.map((d) => ({ value: d.DpmsVersion, text: d.DpmsVersion })));
        // T4
        const t4Options = _dpmsVersions.filter((d) => d.DpmsType === DpmsTypes.T4.valueOf());
        setT4Versions(t4Options.map((d) => ({ value: d.DpmsVersion, text: d.DpmsVersion })));
      } catch (e) {
        showToast("Failed to load DPMS Versions!", Intent.DANGER);
        setSdVersions([]);
        setOtVersions([]);
        setOmsVersions([]);
        setPwVersions([]);
        setR4Versions([]);
        setT4Versions([]);
      }
    }

    //loadPartnerList();
    loadDpmsVersions();

    return () => { isComponentLoaded = false };
  }, []);

  return (
    <>
      <SectionLite
        title="Product/Service"
        icon={IconNames.LAYOUT_CIRCLE}
        description="Product/Service that will be provisioned."
      >
        <div className="columns">
          <div className="column is-3">
            <SelectField
              label="Product"
              binding="ProductOffering"
              options={getProductList(true)}
              validations={requiredValidation}
            />
          </div>
          {/* <div className="column is-4">
            <SelectField
              label="Partner"
              binding="PartnerId"
              options={partners || []}
              isLoading={!partners}
              validations={[]}
            />
          </div> */}
        </div>
      </SectionLite>

      <SectionLite
        title="DPMS"
        icon={IconNames.DATABASE}
        description="Customer's current DPMS System."
      >
        <div className="columns">
          <div className="column is-2">
            <SelectField
              label="DPMS"
              binding="DpmsType"
              options={getDpmsList()}
              validations={requiredValidation}
            />
          </div>
          <div className="column is-2">
            <SelectField
              label="Version"
              binding="DpmsVersion"
              options={dpmsVersions}
              validations={versionNumberValidation}
            />
          </div>
        </div>
      </SectionLite>

      <SectionLite
        title="Customer"
        icon={IconNames.OFFICE}
        description="Customer/Office information. Provide customer SAP ID."
      >
        <div className="columns">
          <div className="column is-2">
            <TextField
              label="SAP ID"
              binding="CustomerId"
              validations={sapIdValidations}
            />
          </div>
          <div className="column is-3">
            <TextField
              label="Customer Name"
              binding="CustomerName"
              validations={nameValidation}
            />
          </div>
          <div className="column is-3">
            <TimeZoneField
              label="Time Zone"
              binding="TimeZone"
              validations={timeZoneValidation}
            />
          </div>
        </div>
      </SectionLite>

      <SectionLite
        title="Admin User"
        icon={IconNames.PERSON}
        description={adminUserDesc}
      >
        <div className="columns">
          <div className="column is-2">
            <TextField
              label={isLocaleUS() ? "First Name" : "Forename"}
              binding="AdminUserFirstName"
              validations={nameValidation}
            />
          </div>
          <div className="column is-2">
            <TextField
              label={isLocaleUS() ? "Last Name" : "Surname"}
              binding="AdminUserLastName"
              validations={nameValidation}
            />
          </div>
          <div className="column is-3">
            <TextField
              label="Email"
              binding="AdminUserEmail"
              validations={emailValidation}
            />
          </div>
          <div className="column is-3">
            <TextField
              label="CC Email"
              binding="CCUserEmail"
              validations={ccEmailValidation}
            />
          </div>
        </div>
      </SectionLite>
    </>
  );
};

export default FormContent;
