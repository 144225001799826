import { DpmsTypes }         from "csd.phoenix.models/DpmsTypes";
import { loadConfig }        from "../services/appConfigService";
import { IPagedApiResponse } from "../models/PagedApiResponse";
import { ICustomerSummary}   from "../models/CustomerSummary";
import { ICustomer }         from "../models/Customer";
import { IApiPagingOptions } from "../services/api/odata/models";
import http from "../services/api/http";
import { IColumnSort, SortDirection, } from "../components/shared/DataTable/models";



const defaultControllerName = "Customers";

export async function getCustomerSummaries(
    customerId?: string,
    dpmsType?: DpmsTypes,
    isHosted?: boolean,
    isActive?: boolean,
    pagingOptions?: IApiPagingOptions,
    sortingOptions?: IColumnSort<ICustomerSummary> )
{
    const isSortDescending = (sortingOptions?.sortDirection !== SortDirection.Asc);
    const { gatewayAdminUrl } = await loadConfig();
    const url = gatewayAdminUrl
      .segment(defaultControllerName +'/CustomerSummaries')
      .query({
        customerId,
        dpmsType,
        isActive,
        isHosted,
        sortBy: sortingOptions?.fieldName,
        sortDescending: isSortDescending,
        pageNumber: pagingOptions?.pageNum ?? 1,
        pageSize: pagingOptions?.pageSize ?? 20
      })
      .toString();

    let { data } = await http.get<IPagedApiResponse<ICustomerSummary>>(url);

    for (let {lastIngressSession, lastEgressSession, lastEgressEntityFailure} of data.data)
    {
      // Convert date fields form string to `Date` types
      if (!!lastIngressSession?.createdOn) lastIngressSession.createdOn = new Date(lastIngressSession?.createdOn);
      if (!!lastEgressSession?.createdOn) lastEgressSession.createdOn = new Date(lastEgressSession?.createdOn);
      if (!!lastEgressEntityFailure?.date) lastEgressEntityFailure.date = new Date(lastEgressEntityFailure?.date);
    }
    return data;
  }



export async function getCustomer( customerId: string ) {
  const { gatewayAdminUrl } = await loadConfig();
  const url = gatewayAdminUrl
                .segment(defaultControllerName)
                .segment("/:customerId")
                .param({customerId})
                .toString();

  let { data } = await http.get<ICustomer>(url);

  // Convert date fields form string to `Date` types
  if (!! data.lastIngressSession?.createdOn) data.lastIngressSession.createdOn = new Date(data.lastIngressSession?.createdOn);
  if (!! data.lastEgressSession?.createdOn)  data.lastEgressSession.createdOn  = new Date(data.lastEgressSession?.createdOn);
  if (!! data.lastEgressEntityFailure?.date) data.lastEgressEntityFailure.date = new Date(data.lastEgressEntityFailure?.date);

  return data;
}
