
import React from "react";
import Page from "../shared/Page";


const AdminPage: React.FC =() =>
 
   
        <Page header= "Administration" >
          <div>Welcome to the Administration page</div>
        </Page>



  

export default AdminPage;
