import { IAppointmentSearchResult, IAppointmentSnapshot } from "../models/Appointment";
import { IPagedApiResponse } from "../models/PagedApiResponse";
import { IPersonSearchResult, IPersonSnapshot } from "../models/Person";
import http from "../services/api/http";
import { IApiPagingOptions } from "../services/api/odata/models";
import { loadConfig } from "../services/appConfigService";


interface IPersonSearchProperties {
  id: string;
  lastName: string;
  firstName: string;
  birthDate: string;
};


interface IPersonApiResult {
  searchProperties: IPersonSearchProperties;
  entitySnapshot: IPersonSnapshot;
};

const defaultControllerName = "EntitySnapshots";

/**
 * Return array 0..n of patients, with common fields .lastName and .firstName
 * added at the root object level so the caller doesn't require special logic
 * to find the search name fields.
 *
 * @param customerId     - (string) SAP ID of the customer to be searched.  REQUIRED.
 * @param personId      - (string) Substring of patient ID to search for.  See notes on how
 *                                  this interacts with the lastname,firstname parameters.
 * @param lastName       - (string) Substring of the patient last name to search for.
 * @param firstName      - (string) Substring of the patient first name to search for.
 * @param pagingOptions  - (object) pageNum, pageSize
 * @returns array[0..n] of patients
 */
export async function getPatients(
  customerId: string,
  personId?: string,
  lastName?: string,
  firstName?: string,
  pagingOptions?: IApiPagingOptions
): Promise<IPagedApiResponse<IPersonSearchResult>> {

  return getPersons("Patients", customerId, personId, lastName, firstName, pagingOptions);
}


export async function getResponsibleParties(
  customerId: string,
  personId?: string,
  lastName?: string,
  firstName?: string,
  pagingOptions?: IApiPagingOptions
): Promise<IPagedApiResponse<IPersonSearchResult>> {

  return getPersons("ResponsibleParties", customerId, personId, lastName, firstName, pagingOptions);
}



/**
 * Return array 0..n of Persons, with common fields .lastName and .firstName
 * added at the root object level so the caller doesn't require special logic
 * to find the search name fields.
 *
 * @param personType     - (string) "Patients" or "ResponsibleParties".     REQUIRED.
 * @param customerId     - (string) SAP ID of the customer to be searched.  REQUIRED.
 * @param patientId      - (string) Substring of patient ID to search for.  See notes on how
 *                                  this interacts with the lastname,firstname parameters.
 * @param lastName       - (string) Substring of the patient last name to search for.
 * @param firstName      - (string) Substring of the patient first name to search for.
 * @param pagingOptions  - (object) pageNum, pageSize
 * @returns array[0..n] of patients
 */
export async function getPersons(
  personType: "Patients" | "ResponsibleParties",
  customerId: string,
  personId?: string,
  lastName?: string,
  firstName?: string,
  pagingOptions?: IApiPagingOptions
): Promise<IPagedApiResponse<IPersonSearchResult>> {


  const { gatewayAdminUrl } = await loadConfig();
  const entityTypeSegment = "/" + personType;

  const url = gatewayAdminUrl
    .segment(defaultControllerName)
    .segment('/:customerId')
    .param({ customerId })
    .segment(entityTypeSegment)
    .query({
      id: personId,
      LastName: !!personId ? undefined : lastName,
      FirstName: !!personId ? undefined : firstName,
      PageNumber: pagingOptions?.pageNum ?? 1,
      PageSize: pagingOptions?.pageSize ?? 20
    })
    .toString();

  let results = await http.get<IPagedApiResponse<IPersonApiResult>>(url);

  // Convert from API models to UI models
  const personArray = results.data.data.map<IPersonSearchResult>(row =>
  ({
    id: row.searchProperties.id,
    firstName: row.searchProperties.firstName,
    lastName: row.searchProperties.lastName,
    isActive: row.entitySnapshot.isActive,
    birthDate: (!!row.searchProperties.birthDate) ? new Date(row.searchProperties.birthDate) : undefined,
    createdOn: new Date(row.entitySnapshot.createdOn),
    modifiedOn: new Date(1000 * row.entitySnapshot._ts),
    entitySnapshot: row.entitySnapshot
  })
  );
  const searchResults: IPagedApiResponse<IPersonSearchResult> = {
    currentPage: results.data.currentPage,
    pageSize: results.data.pageSize,
    totalRecords: results.data.totalRecords,
    data: personArray
  };

  return searchResults;
}

///////////////////////////////////////////////////////////////////////////

interface IAppointmentSearchProperties {
  id: string;
  appointmentDate: string;
  patientLastName: string;
  patientFirstName: string;
  patientId: string;
}

interface IAppointmentApiResult {
  searchProperties: IAppointmentSearchProperties;
  entitySnapshot: IAppointmentSnapshot;
}

export async function getAppointments(
  customerId: string,
  appointmentId?: string,
  appointmentDate?: Date,
  pagingOptions?: IApiPagingOptions
): Promise<IPagedApiResponse<IAppointmentSearchResult>> {

  const { gatewayAdminUrl } = await loadConfig();

  const url = gatewayAdminUrl
    .segment(defaultControllerName)
    .segment('/:customerId')
    .param({ customerId })
    .segment('/Appointments')
    .query({
      Id: appointmentId,
      AppointmentDate: appointmentDate,
      PageNumber: pagingOptions?.pageNum ?? 1,
      PageSize: pagingOptions?.pageSize ?? 20
    })
    .toString();

  let results = await http.get<IPagedApiResponse<IAppointmentApiResult>>(url);

  // Convert from API models to UI models
  const appointmentArray = results.data.data.map<IAppointmentSearchResult>(row =>
  ({
    id: row.searchProperties.id,
    appointmentDate: new Date(row.searchProperties.appointmentDate),
    patientLastName: row.searchProperties.patientLastName,
    patientFirstName: row.searchProperties.patientFirstName,
    patientId: row.searchProperties.patientId,
    customerId: row.entitySnapshot.customerId,
    isActive: row.entitySnapshot.isActive,
    createdOn: new Date(row.entitySnapshot.createdOn),
    lastSyncedOn: new Date(1000 * row.entitySnapshot._ts),
    entitySnapshot: row.entitySnapshot
  })
  );

  const searchResults: IPagedApiResponse<IAppointmentSearchResult> = {
    currentPage: results.data.currentPage,
    pageSize: results.data.pageSize,
    totalRecords: results.data.totalRecords,
    data: appointmentArray
  };

  return searchResults;
};

