import React, { memo, useCallback, useState, useEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import { getLocale } from "../../../../i18n";
import useWarnBeforeWindowUnload from "../../useWarnBeforeWindowUnload";
import ErrorDialog from "../../../shared/dialogs/ErrorDialog";
import Header, { IHeaderProps } from "./Header";
import Footer, { IFooterProps } from "./Footer";
import { CancelButtonState, FormModes } from "../models/baseTypes";
import { FormikErrorsEx } from "../models/validation";
import { FormContextProvider } from "../FormContext";
import Content from "./Content";

type SaveHandler<T extends {}> = (values: T) => Promise<boolean | undefined>;

export interface IProps<T extends {}> {
  formMode: FormModes;
  disableAutoComplete?: boolean;
  locale?: number;
  title?: React.ReactNode;
  HeaderComponent?: React.ComponentType<IHeaderProps>;
  FooterComponent?: React.ComponentType<IFooterProps>;
  initialValues: T;
  isLoading?: boolean;
  dontWarnIfDirty?: boolean;
  onSave?: SaveHandler<T>;
  onCancel?: () => void;
  // Called after either Form submit or reset. Can be used to do
  // things like close a parent dialog, make a route change etc.
  onFormCompleted?: (saved: boolean) => void;
  cancelButtonState?: CancelButtonState;
  validate?: (values: T) => FormikErrorsEx<T>;
  children: React.ReactNode;
  wrapContentInPageComponent?: boolean;
  formContentStyle?: React.CSSProperties;
}



function FormHost<T extends {}>(props: IProps<T>) {
  const [initialValues, setInitialValues] = useState(() => props.initialValues);
  const [errorMsg, setErrorMsg] = useState<string>();
  let [isDirty, setIsDirty] = useState(false);
  useWarnBeforeWindowUnload(isDirty);
  const warnIfDirty = props.dontWarnIfDirty !== true;
  const { onSave, onCancel, onFormCompleted } = props;

  useEffect(() => {
    setInitialValues(props.initialValues);
  }, [props.initialValues]);

  const handleSubmit = useCallback(async (values: T, formikHelpers: FormikHelpers<any>) => {
    try {
      const saved = !onSave || (await onSave(values) !== false);
      // At this point, the form is still considered dirty, and trying to navigate away from
      // the form might generate an alert (if warnIfDirty === true). So we must reset the form.
      // Calling `formikHelpers.resetForm({values})` will end up calling
      // `handleReset()` and thus `onFormCompleted(false)` -- which is not what we want.
      // So  we'll just reset the intialValue instead.
      if (saved) {
        formikHelpers.resetForm(initialValues)
        setTimeout(() => onFormCompleted && onFormCompleted(true), 0);
      }
    }
    catch(e) {
      console.error("`FormHost.props.onSave` failed! ", e);
      //sessionStorage.setItem('formdata', JSON.stringify(values));
      setErrorMsg("We're sorry, but an error occurred while attempting to save your data.");
    }
    finally {
      formikHelpers.setSubmitting(false);
    }
  }, [onSave, initialValues, onFormCompleted]);


  const handleReset = useCallback((values: T, formikHelpers: FormikHelpers<any>) => {
    try {
       onCancel && onCancel();
       setTimeout(() => onFormCompleted && onFormCompleted(false), 0);
    }
    catch(e) {
      console.error("`FormHost.props.onCancel` failed! ", e);
    }
  }, [onCancel, onFormCompleted]);


  const formContent = useCallback(() => {
    const HeaderComponent = props.HeaderComponent || Header;
    const FooterComponent = props.FooterComponent || Footer
    const onErrorDialogClosed = () => setErrorMsg(undefined);

    return (
      <>
        <ErrorDialog title="Save Error" errorMessage={errorMsg} onClosed={onErrorDialogClosed} />
        <Content<T>
          warnIfDirty={warnIfDirty}
          setIsDirty={setIsDirty}
          title={props.title}
          isLoading={props.isLoading === true}
          Header={HeaderComponent}
          Footer={FooterComponent}
          style={props.formContentStyle}
          wrapContentInPageComponent={props.wrapContentInPageComponent}
          cancelButtonState={props.cancelButtonState}
        >
          {props.children}
        </Content>
      </>);
    }, [errorMsg, props, warnIfDirty]);

  return (
    <FormContextProvider
        formMode={props.formMode ?? FormModes.View}
        locale={props.locale || getLocale()}
        disableAutoComplete={props.disableAutoComplete}
    >
      <Formik<T>
        initialValues={initialValues}
        validate={props.validate}
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize={true}
        //validateOnChange={false}
      >
        {formContent}
      </Formik>
    </FormContextProvider>
  )
}

export default memo(FormHost) as typeof FormHost;
