"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Date_Factory = exports.utcDateToSameLocalDate = exports.localDateToSameUtcDateTime = exports.localDateToSameUtcDate = void 0;
function isDate(value) {
    return !!value &&
        typeof value === 'object' &&
        Object.prototype.toString.call(value) === '[object Date]' &&
        !isNaN(value.valueOf());
}
function localDateToSameUtcDate(date) {
    if (!date) {
        return undefined;
    }
    // Convert to a UTC date which has the same year/month/day values
    var ticks = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    return new Date(ticks);
}
exports.localDateToSameUtcDate = localDateToSameUtcDate;
function localDateToSameUtcDateTime(date) {
    if (!date) {
        return undefined;
    }
    // Convert to a UTC date which has the same year/month/day/hour/minute/second values 
    var ticks = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    return new Date(ticks);
}
exports.localDateToSameUtcDateTime = localDateToSameUtcDateTime;
function utcDateToSameLocalDate(date) {
    if (!date) {
        return undefined;
    }
    // Convert to a local date which has the same year/month/day values
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}
exports.utcDateToSameLocalDate = utcDateToSameLocalDate;
var Date_Factory = /** @class */ (function () {
    function Date_Factory() {
    }
    Date_Factory.CreateIncoming = function (data, _a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.ignoreTimePortion, ignoreTimePortion = _c === void 0 ? false : _c, _d = _b.ignoreAdjustment, ignoreAdjustment = _d === void 0 ? false : _d;
        if (!data) {
            return undefined;
        }
        var date = typeof data === 'string' ? new Date(data) : data;
        console.assert(isDate(date), "Invalid date value: [" + typeof data + "] " + data);
        if (ignoreTimePortion !== true && ignoreAdjustment !== true) {
            return date;
        }
        // If the date is local already, skip UTC conversion, and just remove the time portion.
        // Note: If the string only contains a date (ex: "2020-02-03"), then JavaScript assumes UTC midnight.
        // As such, the string represents local time only if it includes a time portion *and* doesn't end in "Z"
        var isLocalDate = typeof data === "string" && /\d{2}T\d{2}/.test(data) && !data.endsWith("Z");
        if (isLocalDate) {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        }
        return utcDateToSameLocalDate(date);
    };
    Date_Factory.CreateOutgoing = function (data, _a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.ignoreTimePortion, ignoreTimePortion = _c === void 0 ? false : _c, _d = _b.ignoreAdjustment, ignoreAdjustment = _d === void 0 ? false : _d;
        if (!data)
            return undefined;
        var date = typeof data === 'string' ? new Date(data) : data;
        console.assert(isDate(date), "Invalid date value: [" + typeof data + "] " + data);
        if (ignoreAdjustment === true) {
            date = localDateToSameUtcDateTime(date);
        }
        else if (ignoreTimePortion === true) {
            date = localDateToSameUtcDate(date);
        }
        return date.toISOString();
    };
    return Date_Factory;
}());
exports.Date_Factory = Date_Factory;
