import React, { useMemo, useCallback } from "react";
import { Callout, Intent, H3, H5, Classes } from "@blueprintjs/core";
import { useHistory } from "react-router";
// import {
//   EsvcsProductOfferings,
//   EsvcsProductOfferings_Descriptions,
// } from "csd.phoenix.models/EsvcsProductOfferings";
import routes from "../../../routePaths";
import { HttpError } from "../../../services/api/http";
import { setOfficeAuthToken } from "../../../services/authService/officeTokenStorage";
import { showToast, dismissToast } from "../../../services/ToasterService";
import { getLocale } from "../../../i18n";
import {
  IMarkAsViewedActionResponse,
  getOfficeAuthToken,
} from "../../../stores/enrollmentsStore";
import OfficeAuthForm, { IFormFields } from "./OfficeAuthForm";
import css from "../styles.module.scss";

const loginErrorToastKey = "loginError";

const Content: React.FC<IMarkAsViewedActionResponse> = (props) => {
  const locale = useMemo(getLocale, []);
  const history = useHistory();

  const handleFormSubmitted = useCallback(
    async ({ sapId, postCode }: IFormFields) => {
      try {
        dismissToast(loginErrorToastKey);
        const token = await getOfficeAuthToken(
          props.RecordId,
          sapId,
          postCode,
          locale
        );
        setOfficeAuthToken(token);

      } catch (error) {
        if (error instanceof HttpError && error.statusCode === 401) {
          showToast(
            "The information you provided didn't match our records!",
            Intent.DANGER,
            5000,
            loginErrorToastKey
          );
        } else {
          showToast(
            "Sorry, there was an error validating your information.",
            Intent.DANGER,
            5000,
            loginErrorToastKey
          );
        }
        return false;
      }
    },
    [locale, props.RecordId]
  );

  const onFormCompleted = useCallback((saved: boolean) => {
    saved && history.push(routes.officeMode.confirmation.path);
    return Promise.resolve();
  }, [history]);

  // const productName =
  //   EsvcsProductOfferings_Descriptions[props.ProductOffering] +
  //   (props.ProductOffering !== EsvcsProductOfferings.PartnerApi
  //     ? ""
  //     : ` (${props.PartnerName})`);

  return (
    <div className={css.content} style={{ margin: "auto" }}>
      <H3 className={Classes.TEXT_MUTED}>Welcome, {props.CustomerName}!</H3>
      <H5 className={Classes.TEXT_MUTED}>
        Thank you for your interest in Carestream Dental's{" "}
        {props.PartnerDisplayName} service.
      </H5>
      <div>
        <div className={css.content} style={{ marginTop: "3em" }}>
          The following information is needed in order to complete the signup
          process.
        </div>
        <Callout className={css.callout} icon={null}>
          Your Carestream Dental account number can be found on the statement
          you receive from us, and may also be found in the license information
          within your software. In the Help menu, Click About, and look for an
          Account ID or Customer ID.
        </Callout>
      </div>

      <div>
        <OfficeAuthForm
          onFormSubmitted={handleFormSubmitted}
          onFormCompleted={onFormCompleted}
        />
      </div>
    </div>
  );
};

export default Content;
