import React, { useState, useMemo, useRef } from "react";
import classnames from "classnames";
import { FormGroup, Intent, Button, MenuItem, PopoverProps } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Select, ItemRenderer, ItemPredicate } from "@blueprintjs/select";
import css from "../form.module.scss";
import { ISelectFieldProps, IFieldOption } from "../models";
import { Field, FieldProps, getIn } from "formik";
import { validationMethodFactory, hasRequiredValidation } from "../models/validation";
import RequiredIndicator from "../../../shared/RequiredIndicator";
import useFormContext from "../FormContext";
import Wait from "../../Wait";
import PrintedTextField from "./PrintedTextField";



const SelectItem = Select.ofType<IFieldOption>();

const itemPredicate: ItemPredicate<IFieldOption> = (query, item, _, exact) => {
  const _query = query.toLocaleLowerCase();
  const _text = (item.text || item.value.toString()).toLowerCase();
  return exact === true
    ? _query === _text
    : _text.indexOf(_query) >= 0;

}

const noResults = <MenuItem text="No match" disabled={true} />;
const selectedItemStyle: React.CSSProperties = { width: '100%', justifyContent: 'space-between' };

type Props<T = any> = FieldProps<T> & { fieldProps: ISelectFieldProps };

const BoundSelectField = ({ field, form, fieldProps }: Props) => {
  const { isEditing, isPrinting, fieldChangedSignal, fieldPropOverrides } = useFormContext();
  const [activeItem, setActiveItem] = useState<IFieldOption | null>(null);
  const error = (form.submitCount || getIn(form.touched, field.name)) && getIn(form.errors, field.name);
  const intent = error ? Intent.DANGER : Intent.NONE;
  const value = field.value;
  const initialValue = useRef(value);
  const isRequired = hasRequiredValidation(fieldProps.validations);

  const items = useMemo(
    () => {
      let _items = fieldProps.options || [];
      // Add an "unselect" option to the list?
      if (!isRequired) {
        _items = [
          { text: " ---", value: undefined as any },
          ..._items
        ]
      }

      return _items;
    },
    [fieldProps.options, isRequired]);

  const selectedItem = items.find(i => i.value === value);
  const selectionText = (selectedItem && (selectedItem.text || selectedItem.value)) || "";
  const itemDisabled = (item: IFieldOption) => isRequired && item.value === "";

  const onItemSelect = (item: IFieldOption) => {
    form.setFieldValue(field.name, item.value);
    // item.value !== initialValue.current && fieldChangedSignal.dispatch(field.name, item.value, initialValue.current);
    // initialValue.current = item.value;
  };

  const itemRenderer: ItemRenderer<IFieldOption> = (item, { handleClick, modifiers: { disabled, matchesPredicate, active } }) =>
    matchesPredicate
      ? <MenuItem
        key={(item.value || "").toString()}
        text={item.text || item.value}
        disabled={disabled}
        //active={item.value === value}
        active={active}
        onClick={handleClick}
      />
      : null;

  const popoverProps: PopoverProps = useMemo(() => ({
    fill: true,
    minimal: true,
    onClosed: () => {
      form.setFieldTouched(field.name, value);
      value !== initialValue.current && fieldChangedSignal.dispatch(field.name, value, initialValue.current);
      initialValue.current = value;
    }
  }), [field.name, fieldChangedSignal, form, value]);

  const className = classnames(css.formPart);
  const isLoading = fieldProps.isLoading === true;

  const control = isPrinting
    ? <PrintedTextField value={selectionText || value} />
    : <SelectItem
      items={items}
      disabled={!isEditing || isLoading}
      activeItem={activeItem}
      onActiveItemChange={setActiveItem}
      onItemSelect={onItemSelect}
      itemRenderer={itemRenderer}
      itemDisabled={itemDisabled}
      itemsEqual="value"
      itemPredicate={itemPredicate}
      filterable={items.length > 8}
      popoverProps={popoverProps}
      noResults={noResults}
      {...fieldPropOverrides[field.name]}
    >
      {isLoading && <Wait showBorder={false} />}
      {!isLoading && <Button
        text={selectionText || " ---"}
        rightIcon={IconNames.CHEVRON_DOWN}
        disabled={!isEditing}
        className={classnames(error && "error")}
        style={selectedItemStyle}
        {...field}
        {...fieldPropOverrides[field.name]}
      />}
    </SelectItem>

  return (
    <FormGroup
      label={fieldProps.label}
      labelInfo={<RequiredIndicator show={isRequired} isPrinting={isPrinting} />}
      intent={intent}
      helperText={isEditing ? (error || <>&nbsp;</>) : undefined}
      style={fieldProps.style}
      className={className}
    >
      {control}
    </FormGroup>
  )
}


const SelectField = (props: ISelectFieldProps) => {
  console.assert(props && props.binding);
  const { isEditing } = useFormContext();
  const validate = isEditing ? validationMethodFactory(props) : undefined;
  // Important: This needs to a `Field`, rather than a `FastField` in order to recognize changes to props such as `isLoading`.
  return <Field name={props.binding} fieldProps={props} component={BoundSelectField} validate={validate} />;
}


export default SelectField;






