import { getIn } from "formik";
import { IEsvcsEnrollment } from "csd.phoenix.models/EsvcsEnrollment";
import { IFormContext } from "../../../shared/form/FormContext";
import { DpmsTypes } from 'csd.phoenix.models/DpmsTypes';

export function handleFieldChanged(
  fieldName: string,
  value: unknown,
  oldValue: unknown,
  values: IEsvcsEnrollment,
  setFieldValue: (field: string, value: any) => void,
  context: IFormContext
) {
  if (fieldName === "ProductOffering") {
    // const isAPartnerApi = value === EsvcsProductOfferings.PartnerApi;
    // if (isAPartnerApi) {
    //   context.setFieldPropOverrides("PartnerId", {});
    // }
    // else {
    setFieldValue("PartnerId", undefined);
    context.setFieldPropOverrides("PartnerId", { disabled: true });
    //}
  }
  else if (fieldName === "DpmsType") {
    const dpmsType = getIn(values, "DpmsType");
    // if the DPMS type has changed remove the value of the DPMS version and
    // clear out the timezone so that the user needs to set it.
    setFieldValue("DpmsVersion", undefined);
    setFieldValue("TimeZone", undefined);
    context.setFieldPropOverrides("DpmsVersion", {});
    context.setFieldPropOverrides("TimeZone", {});

    // Disable the DMPSVersion and timezone fields if the DMPS is Sensei Cloud
    if (dpmsType === DpmsTypes.CMP) {
      context.setFieldPropOverrides("DpmsVersion", { disabled: true });
      context.setFieldPropOverrides("TimeZone", { disabled: true });
    }
    else {
      context.setFieldPropOverrides("DpmsVersion", { disabled: false });
      context.setFieldPropOverrides("TimeZone", { disabled: false });
    }
  }

}

export function handleFormLoaded(values: IEsvcsEnrollment, context: IFormContext) {
  //const isAPartnerApi = getIn(values, "ProductOffering") === EsvcsProductOfferings.PartnerApi;
  const dpmsType = getIn(values, "DpmsType");

  // if (!isAPartnerApi) {
  //   context.setFieldPropOverrides("PartnerId", { disabled: true });
  // }
  if (!dpmsType) {
    context.setFieldPropOverrides("DpmsVersion", { disabled: true });
    context.setFieldPropOverrides("TimeZone", { disabled: true });
  }
}

// const ChangeHandler = () => {
//   useFieldChangeTracker(handleFieldChanged, handleFormLoaded);
//   return null;
// }
// export default ChangeHandler;



