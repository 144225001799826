"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsvcsEnrollment_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var EsvcsEnrollment_Factory = /** @class */ (function () {
    function EsvcsEnrollment_Factory() {
    }
    EsvcsEnrollment_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AdminUserEmail: model.AdminUserEmail,
            AdminUserFirstName: model.AdminUserFirstName,
            AdminUserLastName: model.AdminUserLastName,
            CCUserEmail: model.CCUserEmail,
            CustomerId: model.CustomerId,
            CustomerName: model.CustomerName,
            DpmsType: model.DpmsType,
            DpmsVersion: model.DpmsVersion,
            EnrollmentStatus: model.EnrollmentStatus,
            EulaAcceptedOn: Date_Factory_1.Date_Factory.CreateIncoming(model.EulaAcceptedOn),
            EulaVersionAccepted: model.EulaVersionAccepted,
            GatewayVersion: model.GatewayVersion,
            IsHosted: model.IsHosted,
            LocaleId: model.LocaleId,
            Notes: model.Notes,
            PartnerId: model.PartnerId,
            ProductOffering: model.ProductOffering,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SubmittedBy: model.SubmittedBy,
            TenantInstallationId: model.TenantInstallationId,
            TimeZone: model.TimeZone,
        };
        return result;
    };
    EsvcsEnrollment_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AdminUserEmail: model.AdminUserEmail,
            AdminUserFirstName: model.AdminUserFirstName,
            AdminUserLastName: model.AdminUserLastName,
            CCUserEmail: model.CCUserEmail,
            CustomerId: model.CustomerId,
            CustomerName: model.CustomerName,
            DpmsType: model.DpmsType,
            DpmsVersion: model.DpmsVersion,
            EnrollmentStatus: model.EnrollmentStatus,
            EulaAcceptedOn: Date_Factory_1.Date_Factory.CreateOutgoing(model.EulaAcceptedOn),
            EulaVersionAccepted: model.EulaVersionAccepted,
            GatewayVersion: model.GatewayVersion,
            IsHosted: model.IsHosted,
            LocaleId: model.LocaleId,
            Notes: model.Notes,
            PartnerId: model.PartnerId,
            ProductOffering: model.ProductOffering,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            SubmittedBy: model.SubmittedBy,
            TenantInstallationId: model.TenantInstallationId,
            TimeZone: model.TimeZone,
        };
        return result;
    };
    return EsvcsEnrollment_Factory;
}());
exports.EsvcsEnrollment_Factory = EsvcsEnrollment_Factory;
