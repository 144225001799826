import React from "react";
import NavBar from "./NavBar";
import css from "./layout.module.scss";

const PageLayout: React.FC = ({children}) => {
  return (
    <section className={css.page}>
      <NavBar />
      <section className={css.content}>
        {children}
      </section>
    </section>
  );
}

export default PageLayout;
