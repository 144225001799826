import React, { memo, useMemo, useRef } from "react";
import { useSpring, animated, SpringConfig, useChain } from "react-spring";
import useMeasure from "react-use-measure";

export enum RevealDirection { Vertical, Horizontal }

interface IProps {
  show: boolean;
  direction?: RevealDirection; // Default vertical
  animationConfig?: SpringConfig;
  containerStyle?: React.CSSProperties;
  outerContainerClassName?: string;
}


const AnimatedReveal: React.FC<IProps> = memo(({
  show,
  direction = RevealDirection.Vertical,
  animationConfig,
  outerContainerClassName,
  containerStyle,
  children
}) => {
  const [ref, bounds] = useMeasure();
  const isVertical = direction === RevealDirection.Vertical;

  const opacitySpringRef = useRef<any>();
  const opacitySpringConfig = useMemo(() => ({
    config: { duration: 750 },
    ref: opacitySpringRef,
    from: { opacity: 0.0 },
    to: { opacity: show ? 1.0 : 0},
  }), [show]);

  const { opacity }: any = useSpring(opacitySpringConfig);

  const sizeSpringRef = useRef<any>();
  const sizeSpringConfig = useMemo(() => ({
    config: { precision: 0.1, ...animationConfig },
    ref: sizeSpringRef,
    from: isVertical ? ({ height: 0 }) : ({ width: 0 }),
    to: isVertical ? ({ height: bounds.height }) : ({ width: bounds.width })
  }), [animationConfig, bounds.height, bounds.width, isVertical]);

  const {height, width}: any = useSpring(sizeSpringConfig);

  useChain([sizeSpringRef, opacitySpringRef], [0, 1]);

  const outerContainerStyle: React.CSSProperties = useMemo(() => ({
    height,
    width,
    opacity,
    overflowY: isVertical ? 'hidden' : undefined,
    overflowX: !isVertical ? 'hidden' : undefined,
  }), [height, isVertical, opacity, width]);

  const innerContainerStyle: React.CSSProperties = useMemo(() => ({
    ...containerStyle,
    display: show ? undefined : 'none'
  }), [containerStyle, show]);

  // Note: `ResizeObserver` (and thus `useMeasure`) doens't include margins, even when using the 'border-box'
  // option of the `observe` method. As such, we must use padding to emulate margin.
  return (
    <animated.div style={outerContainerStyle} className={outerContainerClassName} >
      <div ref={ref} style={isVertical ? undefined : { width: 'max-content'}}>
        <div style={innerContainerStyle}>
          {children}
        </div>
      </div>
    </animated.div>
  );
});


export default AnimatedReveal;
