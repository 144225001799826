import LruCache  from 'lru-cache';
import { assertNonNil, notEmpty } from './objectUtils';
import PendingPromiseCache from './PendingPromiseCache';

/**
 * Options  for `AsyncLruCache` constructor
 * @template K The type of key used for loading promises.
 * @template V The type of Promise return values.
 */
export interface AsyncLruCacheOptions<K, V> {
  /**
   * Maximum number of items/entries that will be maintaned in the cache.
   * Adding additional items to the cache will remove items that were
   * accessed the longest ago.
   */
  maxItems: number;

  /**
   * The factory method to call when an entry with the supplied key doesn't exist in the cache.
   */
  promiseFactory: (key: K) => Promise<V>;

  /**
   * The time (in ms) since insertion, after which an item in the cache will be considers stale/expired.
   * A request for a stale/expired item will trigger a call to the `promiseFactory` to load the item.
   * If not specified, items in the cache don't expire, and are only removed when the cache reaches max
   * capacity (or a call to `remove` is made.)
   */
  expireAfter?: number;

  debugMode?: boolean;
  onCacheHit?: (key: K) => void;
  onCacheMiss?: (key: K) => void;
}



/**
 * A Least-Recent-Used cache. When an item doesn't exist in the case, `options.promiseFactory` will be
 * called to load the item. Items can be automatically removed form the cache under two circumstances:
 * - If the cache is full, the least recently used item will be removed from the cache.
 * - If `options.expireAfter` is specified, an item will be removed from the cache after it's been
 * in the cache for the specified duration.
 */
export default class AsyncLruCache<K, V> {

  private _options: AsyncLruCacheOptions<K, V>;
  private _cache: LruCache<K, V>;
  private _pendingPromises: PendingPromiseCache<K, V>;


  constructor(options: AsyncLruCacheOptions<K, V>) {

    const defaultOptions = {
      debugMode: false,
      onCacheHit: (key: K) => this.debugMessage("Cache hit: " + key),
      onCacheMiss: (key: K) => this.debugMessage("Cache miss: " + key)
    };

    this._options = Object.assign(defaultOptions, options);

    // Min allowed expiration: 50ms.
    if (this._options.expireAfter && this._options.expireAfter < 50)
      this._options.expireAfter = 50;

    this._cache = new LruCache<K, V>({
      max: this._options.maxItems,
      maxAge: this._options.expireAfter
    });

    this._pendingPromises = new PendingPromiseCache<K,V>({
      promiseFactory: options.promiseFactory,
    });
  }


  private debugMessage(message: string) {
    if (this._options.debugMode)
      console.info(message);
  }


  private validateKey(key: K) {
    if (notEmpty(key))
      return key;

    throw new Error(`Invalid Key: \`${key}\``);
  }


  /**
   * Returns a cached item  if it exists in the cache and hasn't expired.
   * Otherwise, uses `options.promiseFactory` to load the item, add it to cache, and return it.
   */
  get(key: K): Promise<V> {
    let item = this._cache.get(this.validateKey(key));

    if (typeof item !== 'undefined')
    {
      assertNonNil(this._options.onCacheHit)(key);
      /*
        Jump the response to the end of the execution stack to allow react
        the time to perform any transition that would normally happen during an API call
      */
     return Promise.resolve().then(() => item!);
    }

    assertNonNil(this._options.onCacheMiss)(key);
    const promise = this._pendingPromises.get(key);
    return promise.then(value => this.setExplicitly(key, value));
  }


  /**
   * Manually add an item to the cache.
   */
  setExplicitly(key: K, value: V) {
    if (typeof value === 'undefined') {
      console.warn("Can't store `undefined` value in cache. Key = " + key);
      this._cache.del(key);
    }
    else {
      this._cache.set(this.validateKey(key), value);
      this.debugMessage("Stored: " + key);
    }

    return value;
  }


  /**
   * Gets an item from the cache if it exists. Doesn't try to
   * load the item if it's not in the cache.  This doesn't affect
   * the items LRU status.
   */
  peek(key: K) {
    return this._cache.peek(this.validateKey(key));
  }


  /**
   * Checks to see if an item exists in the cache.
   * Doesn't affect the items LRU status.
   */
  has(key: K) {
    return this._cache.has(this.validateKey(key));
  }


  remove(key: K) {
    this._cache.del(this.validateKey(key));
    //this.debugMessage("Removed: " + key);
    return this;
  }


  clear() {
    this._cache.reset();
    this.debugMessage("Cache cleared.");
    return this;
  }


  cachedItems() {
    return this._cache.values();
  }

  keys() {
    return this._cache.keys();
  }
}
