import { KeyValuePairs, PrimitiveType, ODataQueryParams } from "./models";
import QueryOptionsBuilder from "./QueryOptionsBuilder";
import UrlAssembler from "url-assembler";

export default class Resource {

  private url: UrlAssembler;
  private isComposable = true;
  private isCount = false;
  private isBatch = false;
  private isValue = false;
  private isSingleEntity = false;
  private isRef = false;


  constructor(basePath: string = "") {
    const len = basePath.length;
    const _basePath = (basePath[len - 1] === "/") ? basePath.substr(0, len - 1) : basePath;
    this.url = new UrlAssembler(_basePath);
  }


  get [Symbol.toStringTag]() {
    return 'OData.Resource';
  }


  asString() {
    return encodeURI(this.url.valueOf());
  }


  toString() {
    return this.asString();
  }

  private appendSegment(segment: string) {
    this.url = this.url.segment("/" + segment);
    return this;
  }


  // http://docs.oasis-open.org/odata/odata/v4.0/errata03/os/complete/part2-url-conventions/odata-v4.0-errata03-os-part2-url-conventions-complete.html#_Toc453752351
  entityCount() {
    this.appendSegment("$count");
    this.isCount = true;
    return this.asString();
  }


  // http://docs.oasis-open.org/odata/odata/v4.0/errata03/os/complete/part2-url-conventions/odata-v4.0-errata03-os-part2-url-conventions-complete.html#_Toc453752350
  valueOf() {
    this.appendSegment("$value");
    this.isValue = true;
    return this.asString();
  }


  // http://docs.oasis-open.org/odata/odata/v4.0/errata03/os/complete/part2-url-conventions/odata-v4.0-errata03-os-part2-url-conventions-complete.html#_Toc453752345
  entityRef() {
    this.isRef = true;
    return this.appendSegment("$ref");
  }


  // http://docs.oasis-open.org/odata/odata/v4.0/errata03/os/complete/part2-url-conventions/odata-v4.0-errata03-os-part2-url-conventions-complete.html#_Toc453752344
  resolveRef(entityRef: string) {
    this.appendSegment("$entity?$id=" + entityRef);
    return this.asString();
  }

  entitySet(name: string) {
    return this.appendSegment(name);
  }

  entity(name: string, key: PrimitiveType) {
    this.isSingleEntity = true;

    console.assert(key != null);
    const str = name + "(" + key + ")";

    return this.appendSegment(str);
  }

  entityWithKeys(name: string, keys: KeyValuePairs) {
    this.isSingleEntity = true;

    const items = Object.entries(keys)
      .map(
        ([key, value]) => key + "=" + value);

    console.assert(items.length > 1);
    const str = name + "(" + items.join(",") + ")";

    return this.appendSegment(str);
  }


  function(name: string, params?: KeyValuePairs) {

    let items: string[] = [];
    if (params) {
      items = Object.entries(params)
        .map(([key, value]) => key + "=" + value);
    }

    const str =  `Action.${name}(` + items.join(",") + ")";
    return this.appendSegment(str);
  }


  // entityAction(name: string, params?: KeyValuePairs) {
  //   this.isComposable = false;
  //   return this.entityFunction(name, params);
  // }

  action(actionName: string) {
    return this.appendSegment(`Action.${actionName}`);
  }


  queryOptions(queryParams?: ODataQueryParams) {
    var queryOptions = new QueryOptionsBuilder(this.asString(), queryParams);
    return queryOptions;
  }

}

