import { IColumnSort, SortDirection } from "../components/shared/DataTable/models";
import { IClientLogEntry, IServiceLogEntry } from "../models/LogEntry";
import { IPagedApiResponse } from "../models/PagedApiResponse";
import { default as http } from "../services/api/http";
import { IApiPagingOptions } from "../services/api/odata/models";
import { loadConfig } from "../services/appConfigService";


const defaultControllerName = "Logs";


export async function getClientLogEntries(
    customerId: string,
    startDate?: Date,
    endDate?: Date,
    pagingOptions?: IApiPagingOptions,
    sortingOptions?: IColumnSort<IClientLogEntry>,
) {
    const isSortDescending = (sortingOptions?.sortDirection !== SortDirection.Asc);
    const { gatewayAdminUrl } = await loadConfig();
    const url = gatewayAdminUrl
        .segment(defaultControllerName + '/ClientLogs/')
        .segment(':customerId')
        .param({ customerId })
        .query({
            customerId,
            startDate,
            endDate,
            sortBy: sortingOptions?.fieldName,
            sortDescending: isSortDescending,
            pageNumber: pagingOptions?.pageNum ?? 1,
            pageSize: pagingOptions?.pageSize ?? 20
        })
        .toString();

    let { data } = await http.get<IPagedApiResponse<IClientLogEntry>>(url);

    for (let item of data.data) {
        // Convert date fields form string to `Date` types
        item.timestamp = new Date(item.timestamp);
        item.occurredOnDate = new Date(item.occurredOnDate);
        item.createdOn = new Date(item.createdOn);
    }

    return data;
}

// 2024-02-20 [JTB]: Should we eventually factor out the common code and make
//            these into a single common function that takes a "logType" enum
//            and can handle any of the 3 log types in IColumnSort<> and in
//            the return type?


export async function getServiceLogEntries(
    customerId: string,
    startDate?: Date,
    endDate?: Date,
    pagingOptions?: IApiPagingOptions,
    sortingOptions?: IColumnSort<IServiceLogEntry>,
) {
    const isSortDescending = (sortingOptions?.sortDirection !== SortDirection.Asc);
    const { gatewayAdminUrl } = await loadConfig();
    const url = gatewayAdminUrl
        .segment(defaultControllerName + '/ServiceLogs/')
        .segment(':customerId')
        .param({ customerId })
        .query({
            customerId,
            startDate,
            endDate,
            sortBy: sortingOptions?.fieldName,
            sortDescending: isSortDescending,
            pageNumber: pagingOptions?.pageNum ?? 1,
            pageSize: pagingOptions?.pageSize ?? 20
        })
        .toString();

    let { data } = await http.get<IPagedApiResponse<IServiceLogEntry>>(url);

    for (let item of data.data) {
        // Convert date fields form string to `Date` types
        item.timestamp = new Date(item.timestamp);
        item.occurredOnDate = new Date(item.occurredOnDate);
        item.createdOn = new Date(item.createdOn);
    }

    return data;
}


// Note that the egress log entries are virtually identical in structure
// to the service log entries.

export async function getEgressLogEntries(
    customerId: string,
    startDate?: Date,
    endDate?: Date,
    pagingOptions?: IApiPagingOptions,
    sortingOptions?: IColumnSort<IServiceLogEntry>,
) {
    const isSortDescending = (sortingOptions?.sortDirection !== SortDirection.Asc);
    const { gatewayAdminUrl } = await loadConfig();
    const url = gatewayAdminUrl
        .segment(defaultControllerName + '/EgressLogs/')
        .segment(':customerId')
        .param({ customerId })
        .query({
            customerId,
            startDate,
            endDate,
            sortBy: sortingOptions?.fieldName,
            sortDescending: isSortDescending,
            pageNumber: pagingOptions?.pageNum ?? 1,
            pageSize: pagingOptions?.pageSize ?? 20
        })
        .toString();

    let { data } = await http.get<IPagedApiResponse<IServiceLogEntry>>(url);

    for (let item of data.data) {
        // Convert date fields form string to `Date` types
        item.timestamp = new Date(item.timestamp);
        item.occurredOnDate = new Date(item.occurredOnDate);
        item.createdOn = new Date(item.createdOn);
    }

    return data;
}
