import React from "react";
import UserConfirmationDetails from "./UserConfirmationDetails";
import { FormModes } from "../../shared/form/models/baseTypes";
import FormHost from "../../shared/form/FormHost";
import { IEnrollmentConfirmation } from "../../../stores/enrollmentsStore";

interface IProps {
  locale: number;
  initialValues: IEnrollmentConfirmation;
  onFormSubmitted: (values: IEnrollmentConfirmation) => Promise<any>;
}

const UserEulaConfirmationForm: React.FC<IProps> = ({
  locale,
  initialValues,
  onFormSubmitted,
}) => {
  return (
    <FormHost<IEnrollmentConfirmation>
      formMode={FormModes.Edit}
      locale={locale}
      initialValues={initialValues}
      disableAutoComplete={true}
      onSave={onFormSubmitted}
    >
      <UserConfirmationDetails />
    </FormHost>
  );
};

export default UserEulaConfirmationForm;
