"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsvcsProductOfferings_DisplayOrders = exports.EsvcsProductOfferings_Descriptions_English = exports.EsvcsProductOfferings_Descriptions = exports.EsvcsProductOfferings_translateDescriptionBack = exports.EsvcsProductOfferings_translateDescriptions = exports.EsvcsProductOfferings = void 0;
var EsvcsProductOfferings;
(function (EsvcsProductOfferings) {
    EsvcsProductOfferings[EsvcsProductOfferings["PartnerApi"] = 1] = "PartnerApi";
    EsvcsProductOfferings[EsvcsProductOfferings["PatientForms"] = 2] = "PatientForms";
    EsvcsProductOfferings[EsvcsProductOfferings["CloudAccess"] = 3] = "CloudAccess";
})(EsvcsProductOfferings = exports.EsvcsProductOfferings || (exports.EsvcsProductOfferings = {}));
function EsvcsProductOfferings_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.EsvcsProductOfferings_Descriptions);
    allKeys.forEach(function (key) {
        exports.EsvcsProductOfferings_Descriptions[key] = translationFunction(exports.EsvcsProductOfferings_Descriptions[key]);
    });
}
exports.EsvcsProductOfferings_translateDescriptions = EsvcsProductOfferings_translateDescriptions;
function EsvcsProductOfferings_translateDescriptionBack() {
    var allKeys = Object.keys(exports.EsvcsProductOfferings_Descriptions);
    allKeys.forEach(function (key) {
        exports.EsvcsProductOfferings_Descriptions[key] = exports.EsvcsProductOfferings_Descriptions_English[key];
    });
}
exports.EsvcsProductOfferings_translateDescriptionBack = EsvcsProductOfferings_translateDescriptionBack;
var _EsvcsProductOfferings_Descriptions = {};
_EsvcsProductOfferings_Descriptions.PartnerApi = _EsvcsProductOfferings_Descriptions[1] = "Partner Program";
_EsvcsProductOfferings_Descriptions.PatientForms = _EsvcsProductOfferings_Descriptions[2] = "Patient Forms";
_EsvcsProductOfferings_Descriptions.CloudAccess = _EsvcsProductOfferings_Descriptions[3] = "Cloud WebView";
var _EsvcsProductOfferings_Descriptions_English = {};
_EsvcsProductOfferings_Descriptions_English.PartnerApi = _EsvcsProductOfferings_Descriptions_English[1] = "Partner Program";
_EsvcsProductOfferings_Descriptions_English.PatientForms = _EsvcsProductOfferings_Descriptions_English[2] = "Patient Forms";
_EsvcsProductOfferings_Descriptions_English.CloudAccess = _EsvcsProductOfferings_Descriptions_English[3] = "Cloud WebView";
exports.EsvcsProductOfferings_Descriptions = _EsvcsProductOfferings_Descriptions;
exports.EsvcsProductOfferings_Descriptions_English = _EsvcsProductOfferings_Descriptions_English;
var _EsvcsProductOfferings_DisplayOrders = {};
_EsvcsProductOfferings_DisplayOrders.PartnerApi = _EsvcsProductOfferings_DisplayOrders[1] = 1;
_EsvcsProductOfferings_DisplayOrders.PatientForms = _EsvcsProductOfferings_DisplayOrders[2] = 2;
_EsvcsProductOfferings_DisplayOrders.CloudAccess = _EsvcsProductOfferings_DisplayOrders[3] = 3;
exports.EsvcsProductOfferings_DisplayOrders = _EsvcsProductOfferings_DisplayOrders;
