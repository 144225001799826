import React from 'react';
import { Route, Switch } from "react-router-dom";
import routes from "../../routePaths";
import UserMode from "../UserMode";
import OfficeMode, { OldOfficeMode } from "../OfficeMode";
import PartnerMode from "../PartnerMode";



//import UserMode from "../UserMode";


const AppRoutes = () => {
  return (
    <Switch>
      <Route path={routes.partnerMode.root.path} component={PartnerMode} />
      <Route path={routes.officeMode.welcome.old.path} component={OldOfficeMode} />
      <Route path={routes.officeMode.root.path} component={OfficeMode} />
      
      <Route component={UserMode} />
    </Switch>);
}


export default AppRoutes;
