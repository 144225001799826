import React, { useCallback, useEffect, useRef, useState } from "react";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import { Button, FormGroup, InputGroup, Intent, Radio, RadioGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Tooltip2 } from "@blueprintjs/popover2";
import { DpmsTypes } from 'csd.phoenix.models/DpmsTypes';
import { ICustomerEgressEntityFailures } from "../../../models/CustomerEgressEntityFailures";
import { ICustomerSession } from "../../../models/CustomerSession";
import { ICustomerSummary } from "../../../models/CustomerSummary";
import routes from "../../../routePaths";
import { getCustomerSummaries } from "../../../stores/customersStore";
import DataTableContainer from "../../shared/DataTable/DataTableContainer";
import { IColumn, IColumnFilters, IColumnSort, SortDirection } from "../../shared/DataTable/models";
import useLastUpdatedTimestamp from "../../shared/useLastUpdatedTimestamp";
import css from "./styles.module.scss";


const columns = (() => {
  const formatDate = (date?: Date) => !!date ? date.toLocaleString() : '';

  type ColumnsDef = IColumn<ICustomerSummary & { _actions_?: string }>;

  const columns: ColumnsDef[] = [
    {
      header: "Customer",
      name: "id",
      accessor: (data) => `${data.id}: ${data.name}`
    },

    {
      header: "Active?",
      name: "isActive",
    },

    {
      header: "Hosted?",
      name: "isHosted",
      disableSort: true
    },

    {
      header: "DPMS",
      name: "dpmsType",
    },

    {
      header: "Agent ID",
      name: "agentId",
    },

    {
      header: "Time Zone",
      name: "timeZoneName",
    },

    {
      header: "Last Ingress",
      name: "lastIngressSession",
      renderer: (value: ICustomerSession, row: ICustomerSummary, col: IColumn<ICustomerSummary, ICustomerSession>) => {
        if (!value) return null;
        return (
          <Tooltip2 content={<div>Session ID: {value.id}</div>}>
            {formatDate(value.createdOn)}
          </Tooltip2>)
      }
    },

    {
      header: "Last Egress",
      name: "lastEgressSession",
      renderer: (value: ICustomerSession, row: ICustomerSummary, col: IColumn<ICustomerSummary, ICustomerSession>) => {
        if (!value) return null;
        return (
          <Tooltip2 content={<div>Session ID: {value.id}</div>}>
            {formatDate(value.createdOn)}
          </Tooltip2>)
      },
    },

    {
      header: "Last Egress Error",
      name: "lastEgressEntityFailure",
      renderer: (value: ICustomerEgressEntityFailures, row: ICustomerSummary, col: IColumn<ICustomerSummary, ICustomerEgressEntityFailures>) => {
        if (!value) return null;
        return (
          <Tooltip2 content={<><div>Error count: {value.count}</div><div>Session ID: {value.sessionId}</div></>}>
            {formatDate(value.date)}
          </Tooltip2>)
        //return <span title={`Count: ${value.count}, Session ID: ${value.sessionId}`}>{formatDateString(value.date)}</span>
      }
    },

    {
      "header": "",
      name: "_actions_",
      disableSort: true,
      accessor: (data: ICustomerSummary) => data,
      renderer: (customer: ICustomerSummary) =>
        <Link
          to={generatePath(routes.support.customers.path, { customerId: customer.id })}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button style={{ fontSize: "smaller" }} rightIcon={IconNames.CIRCLE_ARROW_RIGHT} title="Select" small={true} minimal={true}></Button>
        </Link>
    }
  ];

  return columns;
})();


const defaultSort: IColumnSort<ICustomerSummary> = {
  fieldName: "id",
  sortDirection: SortDirection.Desc
}


interface IProps {
}

interface ICustomerFilters {
  isHosted?: boolean;
  isActive?: boolean;
  customerId?: string;
  dpmsType?: DpmsTypes;
}

// Filter persistence
const defaultFilters: ICustomerFilters = { customerId: '', isActive: true };
const storageKey = "uiState.customerList.filters";
const saveFiltersToStorage = (filters: ICustomerFilters = defaultFilters) => sessionStorage.setItem(storageKey, JSON.stringify(filters));
const loadFiltersFromStorage = (): ICustomerFilters => {
  const json = sessionStorage.getItem(storageKey);
  const filters = json == null ? defaultFilters : JSON.parse(json);
  return filters as ICustomerFilters;
}


enum SearchType {
  CustomerId,
  Other
}


const CustomerPanel: React.FC<IProps> = () => {
  const [setLastUpdatedTimestamp, lastUpdatedDisplay] = useLastUpdatedTimestamp();
  const [uiFilters, setUiFilters] = useState<ICustomerFilters>({ ...defaultFilters });
  const [apiFilters, setApiFilters] = useState<ICustomerFilters | undefined>();
  const [isApiCallActive, setIsApiCallActive] = useState(false);
  const searchType = !!uiFilters.customerId ? SearchType.CustomerId : SearchType.Other;

  // Save/Load filters to storage
  const filterRef = useRef(uiFilters);
  filterRef.current = uiFilters;
  useEffect(() => {
    setUiFilters(loadFiltersFromStorage());
    return () => saveFiltersToStorage(filterRef.current);
  }, []);

  const dataLoader = useCallback(async (pageNum: number, pageSize: number, sorting?: IColumnSort<ICustomerSummary>, colFilters?: IColumnFilters<ICustomerSummary>) => {
    // This method should not be called until filter is set
    if (!apiFilters) return await Promise.reject();

    setIsApiCallActive(true);
    try {
      const results = await getCustomerSummaries(apiFilters.customerId,
        apiFilters.dpmsType,
        apiFilters.isHosted,
        apiFilters.isActive,
        { pageNum, pageSize },
        sorting);
      setLastUpdatedTimestamp(new Date());
      return results;
    }
    finally {
      setIsApiCallActive(false);
    }
  }, [apiFilters, setLastUpdatedTimestamp]);


  const onCustomerIdChanged = useCallback(({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    if (/^\d{0,10}$/.test(value)) {
      if (!!value) {
        // Reset all other filters
        setUiFilters({ customerId: value });
      }
      else {
        setUiFilters(current => ({ ...current, customerId: value }));
      }
    }
  }, []);

  const onIsActiveFlagChanged = useCallback(({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    setUiFilters(current => ({ ...current, isActive: value === "" ? undefined : value === "1" ? true : false }))
  }, []);

  const onIsHostedFlagChanged = useCallback(({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    setUiFilters(current => ({ ...current, isHosted: value === "" ? undefined : value === "1" ? true : false }))
  }, []);

  const onDpmsSelectionChanged = useCallback(({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    setUiFilters(current => ({ ...current, dpmsType: value === "" ? undefined : parseInt(value) as DpmsTypes }))
  }, []);

  const runQuery = useCallback(() => {
    const customerId = uiFilters.customerId === '' ? undefined : uiFilters.customerId;
    setApiFilters({ ...uiFilters, customerId });
  }, [uiFilters]);


  return (
    <>
      <div className="columns" style={{ width: "100%" }}>
        <div className="column">
          <FormGroup>
            <div className={css.formGroupLabel}>Customer ID</div>
            <InputGroup style={{ width: "10em" }}
              placeholder="0000000000"
              value={uiFilters.customerId}
              onChange={onCustomerIdChanged}
            />
          </FormGroup>
          <Button
            style={{ width: "10em" }}
            icon={IconNames.SEARCH}
            intent={Intent.PRIMARY}
            onClick={runQuery}
            disabled={isApiCallActive}
          >
            Run Query
          </Button>
        </div>

        <FormGroup className="column">
          <div className={css.formGroupLabel}>Status</div>
          <RadioGroup
            disabled={searchType === SearchType.CustomerId}
            selectedValue={uiFilters.isActive === true ? "1" : uiFilters.isActive === false ? "0" : ""}
            onChange={onIsActiveFlagChanged}>
            <Radio value="" >Any</Radio>
            <Radio value="1" >Active</Radio>
            <Radio value="0" >Inactive</Radio>
          </RadioGroup>
        </FormGroup>

        <FormGroup className="column">
          <div className={css.formGroupLabel}>Location</div>
          <RadioGroup
            disabled={searchType === SearchType.CustomerId}
            selectedValue={uiFilters.isHosted === true ? "1" : uiFilters.isHosted === false ? "0" : ""}
            onChange={onIsHostedFlagChanged}>
            <Radio value="" >Any</Radio>
            <Radio value="0" >On-premises</Radio>
            <Radio value="1" >Hosted</Radio>
          </RadioGroup>
        </FormGroup>

        <FormGroup className="column">
          <div className={css.formGroupLabel}>DPMS</div>
          <div className="columns">
            {/* Column 1  radio buttons*/}
            <RadioGroup
              disabled={searchType === SearchType.CustomerId}
              className="column"
              selectedValue={!!uiFilters.dpmsType ? uiFilters.dpmsType : ""}
              onChange={onDpmsSelectionChanged}>
              <Radio value="">Any</Radio>
              <Radio value={DpmsTypes.Orthotrac} >Orthotrac</Radio>
              <Radio value={DpmsTypes.PracticeWorks} >PracticeWorks</Radio>
            </RadioGroup>
            {/* Column 2  radio buttons*/}
            <RadioGroup
              disabled={searchType === SearchType.CustomerId}
              className="column"
              selectedValue={!!uiFilters.dpmsType ? uiFilters.dpmsType : ""}
              onChange={onDpmsSelectionChanged}>
              <Radio value={DpmsTypes.SoftDent} >SoftDent</Radio>
              <Radio value={DpmsTypes.WinOMS} >WinOMS</Radio>
            </RadioGroup>
          </div>
        </FormGroup>
      </div>

      {lastUpdatedDisplay}

      {apiFilters && <DataTableContainer
        pageSize={20}
        columns={columns}
        dataLoader={dataLoader}
        getRowKey="id"
        defaultSort={defaultSort}
        containerStyle={{ maxHeight: 290 }}
      />}
    </>
  );
}

export default CustomerPanel;
