import React, { memo } from "react";
import { Intent, Tag, Popover, PopoverPosition, Callout } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Prompt } from "react-router-dom";
import { useSpring, animated } from "react-spring";

const DirtyFormWarning = memo(({ isDirty, style }: { isDirty: boolean, style?: React.CSSProperties }) => {
  const animation = useSpring({opacity: isDirty ? 1 : 0, delay: 500, config: {duration: 1000} });

  return (
    <div>
      <Prompt when={isDirty} message="You have unsaved changes which will be lost. Discard?" />

      <Popover position={PopoverPosition.BOTTOM_LEFT} minimal={true} content={<Callout icon={null} intent={Intent.WARNING}>You have unsaved changes</Callout>} >
        <animated.div style={animation}>
          {isDirty && <Tag intent={Intent.WARNING} round={true} icon={IconNames.WARNING_SIGN} style={style} >Not Saved</Tag>}
        </animated.div>
      </Popover>
    </div>
  )
});



export default DirtyFormWarning;
