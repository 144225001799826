import { useHistory } from "react-router";
import React, { useCallback, useMemo, useState } from "react";
import { Callout, Intent, H3 } from "@blueprintjs/core";
import UserEulaConfirmationForm from "./UserEulaConfirmationForm";
import TermsAndConditions from "./TermsAndConditions";
import Layout from "../Layout";
import css from "../styles.module.scss";
import ErrorCallout from "../../shared/ErrorCallout";
import { HttpError } from "../../../services/api/http";
import routes from "../../../routePaths";
import { showToast, dismissToast } from "../../../services/ToasterService";
import {
  markEnrollmentAcceptedTerms,
  IEnrollmentConfirmation,
} from "../../../stores/enrollmentsStore";
import { getLocale } from "../../../i18n";
import { EsvcsEnrollmentStatuses } from 'csd.phoenix.models/EsvcsEnrollmentStatuses';

const confirmationErrorToastKey = "confirmationKey";

interface IConfirmationFields {
  firstName: string;
  lastName: string;
  email: string;
  acceptedTerms: boolean;
  documentId: string;
}

const ConfirmationContent: React.FC<IEnrollmentConfirmation> = (props) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const history = useHistory();
  const locale = useMemo(getLocale, []);
  const handleFormSubmitted = useCallback(
    async ({
      firstName,
      lastName,
      email,
      documentId,
    }: IConfirmationFields) => {
      try {
        dismissToast(confirmationErrorToastKey);
        await markEnrollmentAcceptedTerms(
          documentId,
          firstName,
          lastName,
          email
        );
        setIsConfirmed(true);
      } catch (error) {
        if (error instanceof HttpError && error.statusCode === 401) {
          showToast(
            "The information you provided didn't match our records!",
            Intent.DANGER,
            5000,
            confirmationErrorToastKey
          );
          return false;
        } else if (error instanceof HttpError && error.statusCode === 503) {
          showToast(
            "An error occurred confirming your acceptance of the EULA, please try again and if the error persists contact your support provider.",
            Intent.DANGER,
            5000,
            confirmationErrorToastKey
          );
          return false;
        }
        else {
          throw error;
        }
      }
    }, []
  );

  if (isConfirmed) {
    history.replace(routes.officeMode.termsAccepted.path);
    return null;
  }

  if (props.enrollmentStatus !== EsvcsEnrollmentStatuses.Viewed) {
    return (
      <ErrorCallout
        title="Sorry, we can't process your request!"
        description="The link that you are using has either been previously redeemed or you are attempting to use an invalid link."
      />
    );
  }

  return (
    <Layout  >
      <div className={css.content}
        style={{
          width: "80%",
          margin: "auto",
          maxWidth: 1024
        }}
      >
        <div>
          <div className="centered" style={{ margin: "20px 0" }}>
            <H3>Terms &amp; Conditions</H3>
            <br />
          </div>
          <Callout icon={null}>
            Please confirm that you accept the terms and conditions. The name and
            email address are for the system administrator for your practice,
            please check and update them if required.
        </Callout>
        </div>
        {props.terms && (
          <TermsAndConditions terms={props.terms} />
        )}

        <div>
          <UserEulaConfirmationForm
            locale={locale}
            initialValues={props}
            onFormSubmitted={handleFormSubmitted}
          />
        </div>

      </div>
    </Layout>
  );
};

export default ConfirmationContent;
