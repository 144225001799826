import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DpmsTypes, DpmsTypes_Descriptions } from 'csd.phoenix.models/DpmsTypes';
import { IEsvcsEnrollment } from "csd.phoenix.models/EsvcsEnrollment";
import { IEsvcsEnrollmentPartner } from "csd.phoenix.models/EsvcsEnrollmentPartner";
import { EsvcsEnrollmentStatuses, EsvcsEnrollmentStatuses_Descriptions } from 'csd.phoenix.models/EsvcsEnrollmentStatuses';
import { EsvcsGatewayVersions, EsvcsGatewayVersions_Descriptions } from 'csd.phoenix.models/EsvcsGatewayVersions';
import { EsvcsProductOfferings, EsvcsProductOfferings_Descriptions } from 'csd.phoenix.models/EsvcsProductOfferings';
import { showToast } from "../../../services/ToasterService";
import { AppModes, useAppMode } from '../../../services/appModeService';
import useAuthService from '../../../services/authService';
import { getInstances, getPartners } from "../../../stores/enrollmentsStore";
import DataTableContainer from "../../shared/DataTable/DataTableContainer";
import { ContentAlignment, IColumn, IColumnFilters, IColumnSort, SortDirection } from "../../shared/DataTable/models";
import InlineTextInput from "../../shared/InlineTextInput";
import { getDpmsList, getEnrollmentStatusList, getProductList } from "../../shared/ListData";
import MultiSelection from "../../shared/MultiSelection";
import Page from "../../shared/Page";
import SectionLite from "../../shared/Page/SectionLite";
import Selection from "../../shared/Selection";
import { IFieldOption } from "../../shared/form/models";
import StatusUtils from "./StatusUtils";



let partners: Array<IEsvcsEnrollmentPartner> = [];

function getPartnerName (partnerId: number | undefined) : string {
  return partners.find((p) => p.Id === partnerId)?.Name ?? '';
}

const getColumns = (isPartnerMode: boolean, isDefaultAccessMode: boolean) => {

  const employeeOnlyColumns: IColumn<IEsvcsEnrollment>[] = [
    {
      header: "Service",
      name: "ProductOffering",
      accessor: (data) => EsvcsProductOfferings_Descriptions[data.ProductOffering]
    },
    {
      header: "Partner",
      name: "PartnerId",
      accessor: (data) => (data.PartnerId) ? getPartnerName(data.PartnerId) : data.PartnerId
    },
    {
      header: "Submitted By",
      name: "SubmittedBy",
      accessor: (data) => `${data.SubmittedBy}`
    }
  ];


  const actionColumn: IColumn<IEsvcsEnrollment>[] = [
    {
      header: "",
      name: "RecordId",
      disableSort: true,
      renderer: (id: string, enrollment: IEsvcsEnrollment, _: IColumn<IEsvcsEnrollment, any>) =>
        <div style={{ textAlign: "center" }}>
          <StatusUtils enrollment={enrollment}></StatusUtils>
        </div>
    }
  ];


  const columns: IColumn<IEsvcsEnrollment>[] = [
    {
      header: "Created On",
      name: "RecordCreated"
    },
    {
      header: "Last Updated On",
      name: "RecordLastUpdated"
    },
    {
      header: "Status",
      name: "EnrollmentStatus",
      accessor: (data) => `${EsvcsEnrollmentStatuses_Descriptions[data.EnrollmentStatus]}`
    },
    {
      header: "Gateway Version",
      name: "GatewayVersion",
      accessor: (data) => data.GatewayVersion !== EsvcsGatewayVersions.None ? `${EsvcsGatewayVersions_Descriptions[data.GatewayVersion]}` : null
    },
    {
      header: "SAP - Customer",
      name: "CustomerId",
      accessor: (data) => `${data.CustomerId} - ${data.CustomerName}`,
    },
    {
      header: "DPMS",
      name: "DpmsVersion",
      accessor: (data) => `${DpmsTypes_Descriptions[data.DpmsType]} ${data.DpmsType === DpmsTypes.CMP ? "" : data.DpmsVersion}`
    },
    {
      header: "Hosted?",
      name: "IsHosted",
      styles: { alignment: ContentAlignment.Center },
      accessor: (data) => data.IsHosted
    },
    {
      header: "Time Zone",
      name: "TimeZone"
    },
    ...(isPartnerMode ? [] : employeeOnlyColumns)
    ,
    ...(isDefaultAccessMode ? [] : actionColumn),
  ];


  return columns;
}

//const defaultStatuses = new Set<EsvcsEnrollmentStatuses>([10, 0]);
//const defaultStatuses = new Set<EsvcsEnrollmentStatuses>([EsvcsEnrollmentStatuses.Completed, EsvcsEnrollmentStatuses.New]);
const defaultStatuses = new Set<EsvcsEnrollmentStatuses>([]);

const defaultSort: IColumnSort<IEsvcsEnrollment> = {
  fieldName: "RecordLastUpdated",
  sortDirection: SortDirection.Desc
}

interface IProps {
  pageNum: number;
  pageSize: number;
  customerId?: string;
}

const Comp: React.FC<IProps> = ({ pageNum, pageSize = 15, customerId }) => {
  const [sapNameSelection, setSAPNameSelection] = useState<string>("");
  const [submittedBySelection, setSubmittedBySelection] = useState<string>("");
  const [productSelection, setProductSelection] = useState<string>("");
  const [partnerSelection, setPartnerSelection] = useState<string>("");
  const [statuses, setStatuses] = useState(defaultStatuses);
  const currentFilters = useMemo<IColumnFilters<IEsvcsEnrollment>>(() =>
    ({ EnrollmentStatus: statuses }), [statuses]);
  const { appMode } = useAppMode();
  const isPartnerMode = appMode === AppModes.Partner;
  const [partnerList, setPartnerList] = useState<IFieldOption[]>([]);
  const [dmpsSelections, setDMSPSelections] = useState<string[]>([]);
  const [enrollmentStatusList] = useState<IFieldOption[]>(getEnrollmentStatusList());
  const tempProductList = getProductList();
  tempProductList.unshift({ value: undefined as any, text: "(Select a product)" });
  const [productList] = useState<IFieldOption[]>(tempProductList);
  const [dpmsList] = useState<IFieldOption[]>(getDpmsList());
  const [isPartnerSelection, setIsPartnerSelection] = useState<boolean>(false);
  const [selectedProductItemValue, setSelectedProductItemValue] = useState<string | undefined>("");
  const {isInDefaultAccessGroup} = useAuthService();

  const columns = useMemo(() => getColumns(isPartnerMode, isInDefaultAccessGroup()),
    [isInDefaultAccessGroup, isPartnerMode]);

  const dataLoader = useCallback((pageNum: number, pageSize: number, sorting?: IColumnSort<IEsvcsEnrollment>, filters?: IColumnFilters<IEsvcsEnrollment>) =>
    getInstances([...(filters!.EnrollmentStatus as Set<EsvcsEnrollmentStatuses>).values()], pageNum, pageSize, sorting, customerId, sapNameSelection,
      submittedBySelection, productSelection, partnerSelection, dmpsSelections)
  , [customerId, dmpsSelections, partnerSelection, sapNameSelection, productSelection, submittedBySelection]);

  useEffect(() => {
    async function loadPartnerList() {
      try {
        partners = await getPartners();
        const options = partners.map((p) => ({ value: p.Id, text: p.Name }));
        options.unshift({ value: undefined as any,  text: "(Select a partner)" })
        setPartnerList(options);
      } catch (e) {
        showToast("Failed to load partner list!", Intent.DANGER);
        setPartnerList([]);
      }
    }

    loadPartnerList();
  }, []);

  //const forceDataReload = useCallback(() => setStatuses(current => new Set<EsvcsEnrollmentStatuses>(current.values())), [])

  const onSAPNameSelectionChange = useCallback((value: string) => setSAPNameSelection(value), []);

  const onSubmittedBySelectionChange = useCallback((value: string) => setSubmittedBySelection(value), []);

  const onProductSelectionChange = useCallback((value: string) => {
    setProductSelection(value);

    // If this is not a partner program selection then clear out the filter for the partner.
    const partnerProgramWasSelected =
      (Number(value) === EsvcsProductOfferings.PartnerApi);

    !partnerProgramWasSelected && setPartnerSelection("");
    setIsPartnerSelection(partnerProgramWasSelected);
    setSelectedProductItemValue(partnerProgramWasSelected ? partnerSelection : undefined);

  }, [partnerSelection]);

  const onPartnerSelectionChange = useCallback((value: string) => {
    setPartnerSelection(value)
    setSelectedProductItemValue(value);

  }, []);

  const onStatusSelectionChange = useCallback((values: string[]) => setStatuses(new Set<EsvcsEnrollmentStatuses>(values.map(Number))), []);

  const onDPMSsSelectionChange = useCallback((values: string[]) => setDMSPSelections(values), []);


  return (

    <Page header="Enrollment Status">

      <SectionLite
        title="Filter Selections"
        icon={IconNames.FILTER_LIST}
        description=""
      >
        <div className="columns">

          <div className="column">
            <MultiSelection
              items={enrollmentStatusList}
              onChange={onStatusSelectionChange}
              defaultText="(Select statuses)"
            />
          </div>
          <div className="column">
            <MultiSelection
              items={dpmsList}
              onChange={onDPMSsSelectionChange}
              defaultText="(Select DPMSs)"
            />
          </div>
        </div>

        <div className="columns">

          <div className="column">
            <InlineTextInput
              value={sapNameSelection}
              fill={true}
              placeholder="Search SAP or Customer Name by keyword"
              allowEdit={true}
              disabled={false}
              onChanged={onSAPNameSelectionChange}
              maxLength={50}
            />
          </div>
          {isPartnerMode &&
            <div className="column">
            </div>
          }
          {!isPartnerMode &&
            <div className="column">
              <InlineTextInput
                value={submittedBySelection}
                fill={true}
                placeholder="Search submitted by personnel"
                allowEdit={true}
                disabled={false}
                onChanged={onSubmittedBySelectionChange}
                maxLength={50}
              />
            </div>}
          {!isPartnerMode &&
            <div className="column">
              <Selection
                items={productList}
                onChange={onProductSelectionChange}
                //defaultText="(Select a product)"
              />
            </div>}
          {!isPartnerMode &&
            <div className="column">
            <Selection
              items={partnerList || []}
              onChange={onPartnerSelectionChange}
              //defaultText="(Select a partner)"
              isDisabled={!isPartnerSelection}
              selectedItemValue={selectedProductItemValue!}
              key={productSelection}
              />
            </div>}

        </div>

      </SectionLite>

      <DataTableContainer
        pageSize={pageSize}
        columns={columns}
        dataLoader={dataLoader}
        getRowKey="RecordId"
        filters={currentFilters}
        defaultSort={defaultSort}
        containerStyle={{marginTop: 40}}
      />

    </Page>

  );
}

export default Comp;


