import React, { memo } from "react";
import isDate from "lodash.isdate";
import { IColumn, getContentTextAlignment } from "./models";
import { isNil } from "../../../utils/objectUtils";
import BoolIcon from "../BooleanIcon";



interface IProps<TRow> {
  column: IColumn<TRow, any>;
  data: TRow;
}



function getCellContent(value: any): React.ReactNode {
  if (isNil(value)) {
    return '';
  }

  if (isDate(value)) {
    return value.toLocaleString();
  }

  if (typeof value === "boolean") {
    return <BoolIcon value={value} />
  }

  if (typeof value === "object") {
    throw new Error("Can't render object value.  You should provide a custom rendered for the column.")
  }

  return String(value);
}

function  Render<TRow>({column: { styles = {}}, data}: IProps<TRow> ) {
  return (
    <div style={{display: 'flex'}}>
      <div style={{flex: 1, textAlign: getContentTextAlignment(styles.alignment)}}>
        {getCellContent(data)}
      </div>
    </div>)
}

export function getFieldValue<TRow>(data: TRow, column: IColumn<TRow>) {
  let value = column.accessor ? column.accessor(data) : data[column.name];
  if (column.formatter) {
    value = column.formatter(value);
  }

  return value;
}


const Cell = <TRow extends {}>({ column, data }: IProps<TRow>) => {
  const value = getFieldValue(data, column);
  const content = column.renderer ? column.renderer(value, data, column) : <Render column={column} data={value} />
  return (
    <td>{content}</td>
  )
}

const memoized = memo(Cell) as typeof Cell;
export default memoized;


