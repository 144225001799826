import React from 'react';
import { Button, Intent } from "@blueprintjs/core";
import ErrorBoundary from "../shared/ErrorBoundary";


const Content: React.FC = () => {
  return (
    <>
      <div>Please reload the page and try again.</div>
      <div style={{marginTop: '2em'}}>
        <Button
          intent={Intent.PRIMARY}
          text="Reload"
          onClick={() => window.location.reload(true)}
          style={{minWidth: 80}}
        />
      </div>
    </>
  );
}

const content = <Content />;

const AppErrorBoundary: React.FC = ({children}) => {
  return (
    <ErrorBoundary content={content} >
      {children}
    </ErrorBoundary>)
}


export default AppErrorBoundary;
