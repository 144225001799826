import React, { createContext, useState, useContext, useCallback, useMemo } from "react";
import { FormModes } from "./models/baseTypes";
import useLazyRef from "../useLazyRef";
import { createSignal, ISignalDispatcher3 } from "../../../utils/signalDispatcher";


interface IContextProviderProps {
  readonly locale: number;
  readonly formMode: FormModes;
  readonly disableAutoComplete?: boolean;
}

type FieldChangeEventSignal = Omit<ISignalDispatcher3<string, unknown, unknown>, "dispose">;


export interface IFormContext extends IContextProviderProps {
  readonly isPrinting: boolean;
  readonly isEditing: boolean;
  readonly isViewing: boolean;
  readonly setFormMode: (formMode: FormModes) => void;
  readonly fieldChangedSignal: FieldChangeEventSignal;
  readonly fieldPropOverrides: Readonly<Record<string, object>>;
  setFieldPropOverrides: (fieldName: string, overrides: object | undefined) => void,
}



function initContextData(
  {locale, formMode, disableAutoComplete = false} : IContextProviderProps,
  fieldChangedSignal: FieldChangeEventSignal,
  fieldPropOverrides: Readonly<Record<string, object>>,
): IFormContext {
  return {
    locale,
    formMode,
    disableAutoComplete,
    isEditing: formMode === FormModes.Edit,
    isPrinting: formMode === FormModes.Print,
    isViewing: formMode === FormModes.View,
    fieldChangedSignal,
    fieldPropOverrides,
    setFormMode:  () => { throw  new Error("Should have been reset"); },
    setFieldPropOverrides: () => { throw  new Error("Should have been reset"); }
  };
}


const formContext = createContext<Readonly<IFormContext> | undefined>(undefined);

export const FormContextProvider: React.FC<IContextProviderProps> = ({children, ...props}) => {
  const {current: fieldChangedSignal} = useLazyRef<FieldChangeEventSignal>(createSignal);

  const [state, setState] = useState(() => initContextData(props, fieldChangedSignal, {}));

  const setFormMode = useCallback((formMode: FormModes) => {
    setState(state => ({...state, formMode}));
  }, []);

  const setFieldPropOverrides = useCallback((fieldName: string, overrides: object = {}) => {
    //console.log("context: ", fieldName)
    setState((state: any) => {
      const fieldPropOverrides = {...state.fieldPropOverrides, [fieldName]: overrides };
      return {...state, fieldPropOverrides}
    });
  }, []);

  const initialValue: IFormContext = useMemo(() =>  ({
    ...state,
    setFormMode,
    setFieldPropOverrides
  }), [setFieldPropOverrides, setFormMode, state]);

  return (
    <formContext.Provider value={initialValue}>
      {children}
    </formContext.Provider>
  );
}


const useFormContext = () =>
{
  const context = useContext(formContext);
  if (!context) {
    throw new Error("No FormContext available!");
  }
  return context;
}

export default useFormContext;
