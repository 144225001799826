import { TenantLocales } from 'csd.phoenix.models/TenantLocales';
import getLoadedConfig from '../services/appConfigService';

const clientLanguage = navigator.language;

export function getLocale() {
  const localeId = getLoadedConfig().localeId;
   if (localeId)
    return localeId;

  // Note: Some browsers (i.e. Safari) returns the string as lowercase.
  console.assert(clientLanguage.length === 5);

  //return US locale if culture language ends with US else default to UK (England)
  const localId = toLocaleId(clientLanguage);
  return localId;
}

export const toLocaleId = (locale?: string) =>  !locale || locale.endsWith("-US") ? 5 : 1;

export function getLocaleLanguageCulture(locale = getLocale()) {

  switch (locale) {
    case TenantLocales.England:
    case TenantLocales.Wales:
    case TenantLocales.Scotland:
    case TenantLocales.Northen_Ireland:
    case 10:
      return "en-GB";
    case TenantLocales.USA:
      return "en-US";
    default: return clientLanguage;
  }
}


export const isLocaleUS = (locale = getLocale()) => locale === 5;
export const isLocaleUK = (locale = getLocale()) => locale !== 5;
