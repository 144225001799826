import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/App';
import * as serviceWorker from './serviceWorker';


if (!__csd__.isSupportedBrowser) {
  console.warn("Unsupported browser: ", navigator.userAgent);
}
else {
  ReactDOM.render(<App />, document.getElementById('root'));
}
serviceWorker.unregister();
