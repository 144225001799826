import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import { Alignment, Navbar, Icon } from "@blueprintjs/core";
import { IconNames, IconSize } from "@blueprintjs/icons";
import senseiLogo from "../../../../assets/images/21612 Sensei Logos_Services Enrollment.svg";
import routes from "../../../../routePaths";
import useAuthService from "../../../../services/authService";
import { isAppMode, AppModes } from "../../../../services/appModeService";
import css from "../layout.module.scss";
import UserMenu from "./UserMenu";




const NavBar: React.FC = memo(() => {
  const { getLoggedInUser, isInDefaultAccessGroup } = useAuthService();
  const user = getLoggedInUser();

  console.assert(user);

  const employeeTabs = (
    <Navbar.Group>
      <NavLink className={css.navLink} to={routes.home.path} exact={true} activeClassName={css.active}>
      <Icon icon={IconNames.ADD_TO_ARTIFACT} size={IconSize.LARGE} style={{ marginRight: 6 }} />
        New Enrollment
      </NavLink>

      <NavLink className={css.navLink} to={routes.status.path} exact={true}  activeClassName={css.active}>
        <Icon icon={IconNames.AUTOMATIC_UPDATES} size={IconSize.LARGE} style={{marginRight: 6}} />
        Enrollment Status
      </NavLink>

      <NavLink className={css.navLink} to={routes.support.root.path} activeClassName={css.active}>
      <Icon icon={IconNames.CHAT} size={IconSize.LARGE} style={{marginRight: 6}} />
      Support
      </NavLink>

      <NavLink className={css.navLink} to={routes.admin.path} exact={true}  activeClassName={css.active}>
      <Icon icon={IconNames.ID_NUMBER} size={IconSize.LARGE} style={{marginRight: 6}} />
      Admin
      </NavLink>
      </Navbar.Group>
  );


  return (
    <Navbar className={css.navBar}>
      <Navbar.Group>
        <Navbar.Heading><img src={senseiLogo} alt="Sensei Logo" style={{ height: 50, maxHeight: 'none', paddingTop: 5 }} /></Navbar.Heading>
        <div className="csd-gray" style={{ marginLeft: 30, fontVariant: 'small-caps', fontSize: '14pt' }}>E-Services Enrollment</div>
      </Navbar.Group>

      {isAppMode(AppModes.Employee) && !isInDefaultAccessGroup()  && employeeTabs}

      <Navbar.Group align={Alignment.RIGHT}>
        <UserMenu />
      </Navbar.Group>
    </Navbar>
  );
})

export default NavBar;


