import React, { useState, memo, useEffect, useCallback } from "react";
import { IColumnSort, SortDirection } from "./models";
import DataTable, { IDataTableProps } from "./index";
import { getFieldValue } from "./Cell";


type CommonProps<TRow> = Pick<IDataTableProps<TRow>, "columns" | "data" | "error" | "getRowKey" | "containerStyle">;

interface IProps<TRow> extends CommonProps<TRow> {
  defaultSort?: IColumnSort<TRow>;
}


const noop = () => {};

const ClientDataTable = <TRow extends {}>({ columns, data, error, getRowKey, defaultSort, containerStyle }: IProps<TRow>) => {
  const totalRecords = (data && data.length) || 0;
  const pageSize = totalRecords || 1;
  const pageNum = 1;
  const [sorting, setSorting] = useState(defaultSort);
  const [clientData, setClientData] = useState<TRow[]>();

  const loadData = useCallback(async () => {
    if (!data) {
      setClientData(undefined);
      return;
    }

    let temp = data.slice();
    if (sorting) {
      const sortCol = columns.find(c => c.name === sorting.fieldName);
      if (sortCol) {
        temp = temp.sort((r1, r2) => {
          let v1 = getFieldValue(r1, sortCol);
          let v2 = getFieldValue(r2, sortCol);
          if (typeof v1 === 'string' && typeof v2 === 'string') {
            // Do case-insensitive comparison
            v1 = v1.toLocaleLowerCase();
            v2 = v2.toLocaleLowerCase();
          }
          // Note: Standard comparison operator works on: strings, numbers, booleans and dates.
          const result = v1 === v2 ? 0 : v1 > v2 ? 1 : -1;
          return sorting.sortDirection === SortDirection.Desc ? -1 * result : result;
        });
      }
    }
    setClientData(temp);
  }, [columns, data, sorting]);


  useEffect(() => {
    loadData();
  }, [loadData]);


  return (
    <DataTable<TRow>
      pageSize={pageSize}
      pageNum={pageNum}
      setPageNum={noop}
      totalRecords={totalRecords}
      sorting={sorting}
      setSorting={setSorting}
      data={clientData}
      error={error}
      columns={columns}
      getRowKey={getRowKey}
      containerStyle={containerStyle}
    />
  );

}

const memoized = memo(ClientDataTable) as typeof ClientDataTable;
export default memoized;


