import { IFieldPartBase } from "./index";
import useFormContext from "../FormContext";
import { useMemo } from "react";
import { ColCount, ColumnWidth, ContentAlignment, IColumnCounts } from "./baseTypes";


export function useAutoComplete(field: IFieldPartBase, autocompleteToken?: string) {
  const { disableAutoComplete } = useFormContext();
  // Note: even thought `autocomplete=off` *should* do what we need,
  // in practice, it's ignored by Chrome/Chromium etc.
  const autoComplete = useMemo(() => disableAutoComplete
      ? `${field.binding.replace(/\./g, "-").toLowerCase()}-${new Date().valueOf()}`
      //? "new-password"
      : autocompleteToken,
    [autocompleteToken, disableAutoComplete, field.binding])

  return autoComplete;
}


export function colCountToColWidth(colCount?: ColCount): ColumnWidth | undefined {
  if (!colCount)
    return undefined;

  switch(colCount) {
    case 1: return ColumnWidth.FullWidth;
    case 2: return ColumnWidth.Half;
    case 3: return ColumnWidth.OneThird;
    case 4: return ColumnWidth.OneQuarter;
    default: return undefined;
  }
}

export function colWidthToDeviceClass(deviceFormFactor: "mobile" | "tablet" | "desktop", colWidth?: ColumnWidth) {
  return colWidth ?  `is-${colWidth}-${deviceFormFactor}` : undefined;
}


export function colCountToDeviceClass(deviceFormFactor: "mobile" | "tablet" | "desktop", colCount?: ColCount) {
  const width = colCountToColWidth(colCount);
  return colWidthToDeviceClass(deviceFormFactor, width);
}


export function colCountsToDeviceClasses(colCounts: IColumnCounts) {
  const classes = [
    colCountToDeviceClass("mobile", colCounts.mobileCols),
    colCountToDeviceClass("tablet", colCounts.tabletCols),
    colCountToDeviceClass("desktop", colCounts.desktopCols)
  ].filter(c => !!c);

  if (classes.length) {
    return classes.join(" ");
  }

  return undefined;
}

export function hasAlignment(json: any): json is ({ alignment: ContentAlignment }) {
  return typeof json === "object" && json.alignment;
}

export function toVerticaAlignment(alignment?: ContentAlignment) {
  switch(alignment) {
    case ContentAlignment.Left: return ContentAlignment.Top;
    case ContentAlignment.Center: return ContentAlignment.Middle;
    case ContentAlignment.Right: return ContentAlignment.Bottom;
  }

  return alignment;
}


export function toHorizontalAlignment(alignment?: ContentAlignment) {
  switch(alignment) {
    case ContentAlignment.Top: return ContentAlignment.Left;
    case ContentAlignment.Middle: return ContentAlignment.Center;
    case ContentAlignment.Bottom: return ContentAlignment.Right;
  }

  return alignment;
}


export function getAlignmentClass(alignment?: ContentAlignment) {
  if (!alignment)
    return undefined;
  const alignmentClass = "align" + alignment.substr(0,1).toUpperCase() + alignment.substr(1);
  //const className = (css as any)[alignmentClass];
  return alignmentClass;
}
