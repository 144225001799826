import React from "react";
import { Button, Intent, Icon } from "@blueprintjs/core";
import { useFormikContext } from "formik";
import { IconNames } from "@blueprintjs/icons";
import SectionLite from "../../shared/Page/SectionLite";
import TextField from "../../shared/form/fields/TextField";
import CheckboxField from "../../shared/form/fields/CheckboxField";
import {
  ValidationType,
  IValidation,
} from "../../shared/form/models/validation";
import { IEnrollmentConfirmation } from "../../../stores/enrollmentsStore";
import { isLocaleUS } from "../../../i18n";

const nameValidations: IValidation[] = [
  { type: ValidationType.Required },
  { type: ValidationType.Length, max: 128 }
];

const emailValidations: IValidation[] = [
  { type: ValidationType.Required },
  { type: ValidationType.Email },
  { type: ValidationType.Length, max: 128 }
];

const checkboxValidations: IValidation[] = [
  { type: ValidationType.Required },
  {
    type: ValidationType.Custom,
    validate: (value: string) => {
      if (value === "false") {
        return "Please accept terms and conditions";
      }
    },
  },
];

const adminUserDesc = (
  <div>
    <div>
      Primary/Admin person. Please specify details for the administration user
      account.
    </div>
    <div className="intent-warning">
      <Icon intent={Intent.DANGER} icon={IconNames.WARNING_SIGN} />
      &nbsp;<strong>Important:</strong>&nbsp;
      This user will become the admin
      user for any Sensei cloud services, and have access to all{" "}
      <strong>PHI</strong>
      &nbsp;(and potentially financial) data for the practice.

      This user will also have the ability to create/manage other users in the
      Sensei cloud services environment.
      As such, the provided email address should belong to an{" "}
      <em>individual</em> person (i.e. not an org, group, or distribution list
      address.)
    </div>
  </div>
);

const UserConfirmationDetails = () => {
  const { values, isValid } = useFormikContext();

  return (
    <div style={{ marginTop: "1em" }}>
      <SectionLite
        title="Admin User"
        icon={IconNames.PERSON}
        description={adminUserDesc}
      >
        <div className="columns">
          <div
            className="column is-3"
            style={{ padding: "12 0", height: "65px" }}
          >
            <TextField
              label={isLocaleUS() ? "First Name" : "Forename"}
              binding="firstName"
              validations={nameValidations}
            />
          </div>
          <div
            className="column is-3"
            style={{ padding: "12 0", height: "65px" }}
          >
            <TextField
              label={isLocaleUS() ? "Last Name" : "Surname"}
              binding="lastName"
              validations={nameValidations}
            />
          </div>
          <div
            className="column is-4"
            style={{ padding: "12 0", height: "65px" }}
          >
            <TextField
              label="Email"
              binding="email"
              validations={emailValidations}
            />
          </div>
        </div>
        <div className="right">
          <CheckboxField
            label="Accepted Terms &amp; Conditions"
            binding="acceptedTerms"
            uncheckedLabel="Please accept the terms and conditions to continue"
            checkedLabel="Accepted Terms &amp; Conditions"
            validations={checkboxValidations}
          />
        </div>
      </SectionLite>
      <div className="centered">
        <Button
          type="submit"
          text="Continue"
          disabled={
            !((values as IEnrollmentConfirmation).acceptedTerms && isValid)
          }
          icon={IconNames.LOG_IN}
          intent={Intent.SUCCESS}
        />
      </div>
    </div>
  );
};

export default UserConfirmationDetails;
