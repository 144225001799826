import React from "react";
import css from "../form.module.scss";


interface IProps {
  value: string;
  //rowCount?: number;
}

const style: React.CSSProperties = {
  width: '100%',
  borderBottom: `solid 1px #aaa`,
//  color: '#0066ff'
}

const PrintedTextField: React.FC<IProps> = ({value}) => {
  return (<div className={css.fieldValue} style={style}>{value || <>&nbsp;</>}</div>);
}


export default PrintedTextField;
