
import React, { useState, useCallback, useEffect } from "react";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import AnimatedReveal from "../AnimatedReveal";
import classNames from "classnames";
import css from "./styles.module.scss";



// Manage open/close state for a set of panels. If a persistence key is provided, the states will be persisted in session storage
export const useCollapsiblePanelStates = <T extends string,>(panelIds: T[], persistenceKey?: string): [(id: T) => boolean, (id: T) => void] => {

  const getInitialState = () => {
    if (!persistenceKey) return new Set<T>(); // All panels are closed by default
    // load open panel list from storage
    const json = sessionStorage.getItem(persistenceKey!);
    panelIds = !!json ?  JSON.parse(json) : [];
    return new Set<T>(panelIds);
  }

  const [openPanelList, setOpenPanelList] = useState(getInitialState);

  const isOpen = useCallback((panelId: T) =>  openPanelList.has(panelId), [openPanelList]);

  const toggleIsOpen = useCallback((panelId: T) => {
    setOpenPanelList(openPanels => {
      if (openPanels.has(panelId))
        openPanels.delete(panelId)
      else
        openPanels.add(panelId);
      return new Set(openPanels);
    });
  }, []);

  useEffect(() => {
    if (!persistenceKey) return;
    // Save open panels list to storage
    const openPanelIds =  Array.from(openPanelList.values());
    sessionStorage.setItem(persistenceKey!, JSON.stringify(openPanelIds));
    }, [openPanelList, persistenceKey]);

  return [isOpen, toggleIsOpen];
}


interface IProps {
  title: React.ReactNode;
  contentPlaceHolder?: React.ReactNode;
  isDisabled?: boolean;
  isOpen: boolean;
  toggleIsOpen: () => void;
}

const CollapsiblePanel:React.FC<IProps> = ({
  title,
  contentPlaceHolder = "(Expand to view)",
  isDisabled = false,
  isOpen,
  toggleIsOpen,
  children
}) => {
  return (
    <div className={classNames(css.panel, { [css.open]: isOpen, [css.disabled]: isDisabled })}>
      <header
        className={css.header}
        title={isDisabled ? "Disabled" : (isOpen ? "Collapse" : "Expand")}
        onClick={() => {if (!isDisabled) toggleIsOpen();}}
      >
        <Icon className={css.icon} icon={isDisabled ? IconNames.DOT : isOpen ? IconNames.CARET_DOWN : IconNames.CARET_RIGHT} />
        <div>{title}</div>
      </header>

      <AnimatedReveal show={true} >
        <section className={classNames(css.content, {[css.placeHolder]: !isOpen})} >
          {isOpen ? children : contentPlaceHolder}
        </section>
      </AnimatedReveal>
    </div>);
}

export default CollapsiblePanel;
