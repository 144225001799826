import React, { useMemo } from "react";
import ConfirmationContent from "./ConfirmationContent";
import { getOfficeEnrollmentData } from "../../../stores/enrollmentsStore";
import PromiseWait from "../../shared/PromiseWait";


const ConfirmationPage = () => {
  const promise = useMemo(getOfficeEnrollmentData, []);

  return (
    <PromiseWait promise={promise} component={ConfirmationContent} mapComponentProps={PromiseWait.mapAsIs} />
  );
};

export default ConfirmationPage;
