import React, { memo } from "react";
import Wait from "../Wait";


interface IProps {
  isLoading: boolean;
  colSpan: number;
}

const Loading: React.FC<IProps> = memo(({isLoading, colSpan}) => {
  if (!isLoading) {
    return null;
  }

  return (
    <tr>
      <td colSpan={colSpan}>
        <Wait showBorder={false} style={{padding: '1em'}}  />
      </td>
    </tr>
  );
})


export default Loading;
