import React, { useCallback, useState } from "react";
import { Button, Callout, FormGroup, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useFormikContext } from "formik";
import { IAppointmentSearchResult } from "../../../../models/Appointment";
import { getAppointments } from "../../../../stores/entitySnapshotsStore";
import { today } from "../../../../utils/dateUtils";
import AnimatedReveal from "../../../shared/AnimatedReveal";
import DataTableContainer from "../../../shared/DataTable/DataTableContainer";
import { IColumn, IColumnFilters, IColumnSort, SortDirection } from "../../../shared/DataTable/models";
import FormHost from "../../../shared/form/FormHost";
import DateField from "../../../shared/form/fields/DateField";
import TextField from "../../../shared/form/fields/TextField";
import { FormModes } from "../../../shared/form/models/baseTypes";
import { FormikErrorsEx, IValidation, ValidationType, groupErrorsKey } from "../../../shared/form/models/validation";
import useDialogState from "../../../shared/useDialogState";
import JsonDisplayDialog, { FnOpenJsonDialog, TContentFragment } from "./JsonDisplayDialog";


interface IAppointmentFormState {
  appointmentId?: string;     // Substring of ApptID to search LIKE
  appointmentDate?: Date;     // Date to search for
};

interface IAppointmentFilters extends IAppointmentFormState {
  customerId: string;     // REQUIRED
};

const defaultSort: IColumnSort<IAppointmentSearchResult> = {
  fieldName: "id",
  sortDirection: SortDirection.Asc
};

type TActionsColumn = { _actions_?: string };
type TAppointmentSearchColDef = IColumn<IAppointmentSearchResult & TActionsColumn>;

const getAppointmentColumns = (openJsonDlg: FnOpenJsonDialog) => {
  const formatDate = (date?: Date) => !!date ? date.toLocaleString() : '';

  const columns: TAppointmentSearchColDef[] = [
    {
      header: "Appointment ID",
      name: "id",
      disableSort: true
    },

    {
      header: "Appointment Date",
      name: "appointmentDate",
      disableSort: true
    },

    {
      header: "Active?",
      name: "isActive",
      disableSort: true
    },

    {
      header: "Patient Last Name",
      name: "patientLastName",
      disableSort: true
    },

    {
      header: "Patient First Name",
      name: "patientFirstName",
      disableSort: true
    },

    {
      header: "Patient ID",
      name: "patientId",
      disableSort: true
    },

    {
      header: "Created On",
      name: "createdOn",
      disableSort: true,
      renderer: (value: Date, row: IAppointmentSearchResult, col: IColumn<IAppointmentSearchResult, Date>) => {
        if (!value) return null;
        return (<div>{formatDate(value)}</div>);
      }
    },

    {
      header: "Last Sync Date",
      name: "lastSyncedOn",
      disableSort: true,
      renderer: (value: Date, row: IAppointmentSearchResult, col: IColumn<IAppointmentSearchResult, Date>) => {
        if (!value) return null;
        return (<div>{formatDate(value)}</div>);
      }
    },

    {
      header: "",
      name: "_actions_",  // TActionsColumn
      disableSort: true,
      accessor: (data: IAppointmentSearchResult) => data,
      renderer: (data: IAppointmentSearchResult) => {
        return (
          <Button onClick={() => openJsonDlg(data)}
            style={{ fontSize: "smaller" }}
            rightIcon={IconNames.EYE_OPEN}
            title="View Appointment Details"
            small={true}
            minimal={true}>
          </Button>
        );
      }
    }
  ];

  return columns;
};


const now = today();
const minAppointmentDate = new Date(now.getFullYear() - 7, now.getMonth(), now.getDate());
const maxAppointmentDate = new Date(now.getFullYear() + 2, now.getMonth(), now.getDate());

//#region Form handling
const appointmentIdValidations: IValidation[] = [
  { type: ValidationType.Length, min: 2, max: 20 },
];


const appointmentDateValidations: IValidation[] = [
  { type: ValidationType.Date },
];

//#endregion

const FormHeader: React.FC = () => {
  const { errors } = useFormikContext<IAppointmentFormState>();
  const formLevelError = (errors as FormikErrorsEx<IAppointmentFormState>)[groupErrorsKey]?.["$form"];

  return (
    <AnimatedReveal show={!!formLevelError} containerStyle={{ width: 500, paddingBottom: 10 }}>
      <Callout intent={Intent.DANGER}>{formLevelError}</Callout>
    </AnimatedReveal>)
}

interface IFormRunControlProps {
  isApiActive: boolean;
};

const FormRunControl: React.FC<IFormRunControlProps> = (props) => {
  const { isSubmitting, isValid, isValidating, values: formFields, submitForm } = useFormikContext<IAppointmentFormState>();
  const canRunQuery = !!formFields.appointmentId || !!formFields.appointmentDate;
  const isSubmitDisabled = isSubmitting || isValidating || !isValid || !canRunQuery || props.isApiActive;

  // Clever Dirty Tricks Department:
  // Since the button below is intended to align with the row of input fields
  // to the right of it, (all of which have labels above them), by wrapping
  // the button with a FormGroup having a nonbreaking space label, that label
  // automatically assumes the same vertical size as the field labels, causing
  // the button to automatically align with the input fields.
  return (
    <FormGroup label="&nbsp;">
      <Button
        icon={IconNames.SEARCH}
        intent={Intent.PRIMARY}
        onClick={submitForm}
        disabled={isSubmitDisabled}
      >
        Run Query
      </Button>
    </FormGroup>
  );
}



interface IProps {
  customerId: string;
  pageSize: number;
};

const AppointmentSearchPanel: React.FC<IProps> = (props) => {
  const [apiFilters, setApiFilters] = useState<IAppointmentFilters|null>(null);
  const [isApiCallActive, setIsApiCallActive] = useState(false);

  // Lifted state info for JsonDisplayDialog
  const [dlgContent, setDlgContent] = useState<TContentFragment>(null);
  const [dlgTitle, setDlgTitle] = useState<string>("Appointment Detail");
  const [isDlgOpen, setDlgOpen, onDlgClose] = useDialogState();
  const openJsonDlg = (apiObject: TContentFragment) => {
    // This hoop-jumping is simply to allow us to pass the entire API object in
    // to openJsonDlg() so we can grab patient lastname,firstname.  We can just
    // grab the actual dialog content as a separate fragment from the entitySnapshot.
    const data = apiObject as IAppointmentSearchResult;
    const dlgContent = data.entitySnapshot as TContentFragment;
    setDlgContent(dlgContent);
    setDlgTitle(`Customer [${props.customerId}]   Appointment [${data.id}]   Patient [${data.patientId}: ${data.patientLastName}, ${data.patientFirstName}]`);
    setDlgOpen();
  };

  const [initialFormValues, setInitialFormValues] = useState<IAppointmentFormState>({
    appointmentId: undefined,
    appointmentDate: undefined
  });

  const onValidateForm = useCallback((data: IAppointmentFormState) => {
    let errors: FormikErrorsEx<IAppointmentFormState> = {};
    if ((!!data.appointmentId) && (!!data.appointmentDate)) {
      errors[groupErrorsKey] = { "$form": "Either Appointment ID or Date should be specified, not both." }
    }
    return errors;
  }, []);

  const onFormSubmit = useCallback(
    async ({ appointmentId, appointmentDate }: IAppointmentFormState) => {
      setInitialFormValues({ appointmentId, appointmentDate });   // This preserves our last entries for any subsequent run
      setApiFilters({ customerId: props.customerId, appointmentId, appointmentDate });
      return true;
    },
    [props.customerId]);

  const dataLoader = useCallback(
    async (pageNum: number,
      pageSize: number,
      sorting?: IColumnSort<IAppointmentSearchResult>,
      filters?: IColumnFilters<IAppointmentSearchResult>) => {
      // This method should not be called until filter is set.
      // User must deliberately run the query (which sets API filter from UI filter).
      if (!apiFilters) return await Promise.reject();
      setIsApiCallActive(true);
      try {
        const results = await getAppointments(
          apiFilters.customerId,
          apiFilters.appointmentId,
          apiFilters.appointmentDate,
          { pageNum, pageSize });

        return results;
      }
      finally {
        setIsApiCallActive(false);
      }
    }, [apiFilters]);


  return (
    <div style={{ marginTop: 0, marginBottom: 40 }}>
      <FormHost<IAppointmentFormState>
        formMode={FormModes.Edit}
        initialValues={initialFormValues}
        dontWarnIfDirty={true}
        formContentStyle={{ margin: "20px 0" }}
        onSave={onFormSubmit}
        validate={onValidateForm}
      >
        <FormHeader />

        <div className="columns">
          <div className="column is-2">
            <TextField
              label="Appointment ID"
              binding="appointmentId"
              validations={appointmentIdValidations}
            />
          </div>
          <div className="column is-2">
            <DateField
              label="Appointment Date"
              binding="appointmentDate"
              validations={appointmentDateValidations}
              maxDate={maxAppointmentDate}
              minDate={minAppointmentDate}
              showShortcuts={true}
            />
          </div>
          <div className="column is-2">
            <FormRunControl isApiActive={isApiCallActive} />
          </div>
        </div>
      </FormHost>
      {
        apiFilters && <DataTableContainer
          pageSize={props.pageSize}
          columns={getAppointmentColumns(openJsonDlg)}
          dataLoader={dataLoader}
          getRowKey="id"
          defaultSort={defaultSort}
          containerStyle={{ marginTop: 40 }}
        />
      }
      <JsonDisplayDialog title={dlgTitle} content={dlgContent} isOpen={isDlgOpen} onClose={onDlgClose} />
    </div >
  );
};

export default AppointmentSearchPanel;
