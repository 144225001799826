import { isNil } from '../../../utils/objectUtils';
import  UrlAssembler from "url-assembler";
import { ODataQueryParams, IODataExpand } from "./models";
import { buildODataExpansion } from "./ExpansionBuilder";
import { IApiQueryOptions } from "./models";


// Map each optional propety of `ODataQueryParams` to a required property
type ODataQueryParamsAsRequiredFields = { [key in keyof ODataQueryParams]-?: ODataQueryParams[key] }
// Map each property to a funciton
type IODataQueryOptionImpl = {
  [key in keyof ODataQueryParamsAsRequiredFields]: (arg: (ODataQueryParamsAsRequiredFields[key]) ) => QueryOptionsBuilder;
}


export function getDateFilterExpression(field: string, date: Date) {
  return `year(${field}) eq ${date.getFullYear()} and month(${field}) eq ${date.getMonth()+1} and day(${field}) eq ${date.getDate()}`
}


// https://www.odata.org/documentation/odata-version-3-0/url-conventions/
export default class QueryOptionsBuilder implements IODataQueryOptionImpl {
  url: UrlAssembler;

  constructor(basePath: string | UrlAssembler = '', queryParams?: ODataQueryParams) {
    this.url = basePath instanceof UrlAssembler
      ? basePath
      : new UrlAssembler(basePath);

    if (queryParams) {
      this.applyQueryParams(queryParams)
    }
  }

  get [Symbol.toStringTag]() {
      return 'OData.QueryOptionsBuilder';;
  }

  asString() {
        // We assume the base part for the URL has already been encoded
        return this.url.valueOf();
  }

  toString() {
    return this.asString();
  }


  private apiQueryOptionsToODataQueryParams(apiQueryOptions: IApiQueryOptions) {
    const queryParams: ODataQueryParams = {};
    if (apiQueryOptions.includeCount === true) {
      queryParams.count = true;
    }

    if (apiQueryOptions.pageSize) {
      queryParams.top = apiQueryOptions.pageSize;
    }

    if (apiQueryOptions.pageSize && apiQueryOptions.pageNum) {
      queryParams.skip = apiQueryOptions.pageNum * apiQueryOptions.pageSize;
    }

    return queryParams;
  }


  applyApiQueryOptions(apiQueryOptions?: IApiQueryOptions) {
    if (apiQueryOptions) {
      const queryParams = this.apiQueryOptionsToODataQueryParams(apiQueryOptions);
      this.applyQueryParams(queryParams);
    }

    return this;
  }


  applyQueryParams(queryParams: ODataQueryParams) {
    // For each query option, call corresponding function to build up the url
    for (let [key, value] of Object.entries(queryParams)) {
      if (!isNil(value)) {
        const methodName = key as keyof ODataQueryParams;
        const method = this[methodName] as Function;
        if (!method)
          throw new TypeError(`Method '${key}' doesn't exist on QueryOptions.`);
        method.call(this, value);
      }
    };

    return this;
  }


  private _appendSegment(segment: string) {
    this.url = this.url.segment("/" + segment);
    return this;
  }


  addRaw(key: string, value: any) {
    this.url = this.url.param(key, value);
    return this;
  }


  count(value: boolean = true) {
    return this.addRaw("$count", value.toString());
  }


  expand(expression: string | IODataExpand) {
    if (typeof expression !== 'string') {
      expression = buildODataExpansion(expression);
    }
    return this.addRaw("$expand", expression);
  }


  filter(expresison: string) {
    return this.addRaw("$filter", expresison);
  }

  format(mimeType: string) {
    return this.addRaw("$format", mimeType);
  }


  orderBy(expresison: string) {
    return this.addRaw("$orderby", expresison);
    }


  search(expresison: string) {
    return this.addRaw("$search", expresison);
    }


  select(expresison: string) {
    return this.addRaw("$select", expresison);
  }


  skip(howMany: number) {
        return this.addRaw("$skip", howMany);
  }


  top(howMany: number) {
    return this.addRaw("$top", howMany);
    }
}

