import React, { useCallback, useState } from "react";
import { Button, Callout, FormGroup, Intent } from "@blueprintjs/core";
import { IconName, IconNames } from "@blueprintjs/icons";
import { useFormikContext } from "formik";
import { ISyncAgentResetResults } from "../../../models/SyncAgentResetResults";
import { HttpError } from "../../../services/api/http";
import { resetAgentInstallation } from "../../../stores/syncAgentsStore";
import AnimatedReveal from "../../shared/AnimatedReveal";
import { errorDialogPromise } from "../../shared/dialogs/ErrorDialog";
import FormHost from "../../shared/form/FormHost";
import TextField from "../../shared/form/fields/TextField";
import { FormModes } from "../../shared/form/models/baseTypes";
import { FormikErrorsEx, IValidation, ValidationType, groupErrorsKey } from "../../shared/form/models/validation";


const agentIdValidations: IValidation[] = [
  { type: ValidationType.SapId, fixedLengthOnly: true },
];

const formatDateTime = (value?: Date) => !!value ? value.toLocaleString() : '';

function anyErrorToString(value: any) {
  if (value instanceof Error)
    return value.message;
  else if (typeof value === "object")
    return JSON.stringify(value);
  else
    return String(value);
}

const Content: React.FC<ISyncAgentResetResults> = (data: ISyncAgentResetResults) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ marginRight: 20, marginBottom: 40 }}>
        <div>Results:</div>
        <table className="staticTable">
          <thead>
            <tr>
              <th>Max Usage</th>
              <th>Current Usage</th>
              <th>Expires On</th>
              {/* <th>Last Used On</th> */}
              {/* <th>Issued On</th> */}
              <th>Install Key</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">{data.maxUsageLimit}</td>
              <td align="center">{data.currentUsageCount}</td>
              <td>{formatDateTime(data.expiresOn)}</td>
              {/* <td>{formatDate(data.lastUsedOn)}</td> */}
              {/* <td>{formatDate(data.issuedOn)}</td>   */}
              <td>{data.installKey}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

interface IFormState {
  agentId: string;
};

const FormHeader: React.FC = () => {
  const { errors } = useFormikContext<IFormState>();
  const formLevelError = (errors as FormikErrorsEx<IFormState>)[groupErrorsKey]?.["$form"];

  return (
    <AnimatedReveal show={!!formLevelError} containerStyle={{ width: 500, paddingBottom: 10 }}>
      <Callout intent={Intent.DANGER}>{formLevelError}</Callout>
    </AnimatedReveal>)
}


interface IFormRunControlProps {
  isApiActive: boolean;
};

const FormRunControl: React.FC<IFormRunControlProps> = (props) => {
  const { isSubmitting, isValid, isValidating, values: formFields, submitForm } = useFormikContext<IFormState>();
  const canRunQuery = !!formFields.agentId;
  const isSubmitDisabled = isSubmitting || isValidating || !isValid || !canRunQuery || props.isApiActive;

  return (
    <FormGroup label="&nbsp;">
      <Button
        icon={IconNames.SEARCH}
        intent={Intent.PRIMARY}
        onClick={submitForm}
        disabled={isSubmitDisabled}
        style={{ whiteSpace: "nowrap", flexWrap: "nowrap" }}
      >
        Reset Max Usage
      </Button>
    </FormGroup>
  );
}


const formInitialValues: IFormState = { agentId: "" };

const AgentResetPanel: React.FC = () => {
  const [isApiCallActive, setIsApiCallActive] = useState(false);
  const [results, setResults] = useState<ISyncAgentResetResults | undefined>();

  const onFormSubmit = useCallback(async ({ agentId }: IFormState) => {
    setIsApiCallActive(true);
    try {
      const retval = await resetAgentInstallation(agentId);
      setResults(retval);
      return true;
    }
    catch (ex) {
      let message = "";
      let title = "WARNING";
      let iconType = IconNames.WARNING_SIGN;
      if (ex instanceof HttpError && ex.statusCode === 404) {
        message = `The specified Agent ID ${agentId} does not exist.`;
      }
      else {
        message = anyErrorToString(ex);
        title = "ERROR";
        iconType = IconNames.ERROR;
      }
      await errorDialogPromise(message, title, iconType as IconName);
      return false;
    }
    finally {
      setIsApiCallActive(false);
    }
  }, [setResults]);


  return (
    <div style={{ marginTop: 0, marginBottom: 0 }}>
      <FormHost<IFormState>
        formMode={FormModes.Edit}
        initialValues={formInitialValues}
        dontWarnIfDirty={true}
        formContentStyle={{ margin: "0 0" }}
        onSave={onFormSubmit}
      >

        <FormHeader />
        <div className="columns">
          <div className="column" style={{ minWidth: "10em", maxWidth: "10em" }}>
            <TextField
              label="Sync Agent ID"
              binding="agentId"
              validations={agentIdValidations}
            />
          </div>
          <div className="column">
            <FormRunControl isApiActive={isApiCallActive} />
          </div>
        </div>
      </FormHost>
      <div>
        {results &&
          <Content
            issuedOn={results.issuedOn}
            expiresOn={results.expiresOn}
            lastUsedOn={results.lastUsedOn}
            installKey={results.installKey}
            maxUsageLimit={results.maxUsageLimit}
            currentUsageCount={results.currentUsageCount}
          />
        }
      </div>
    </div>
  )
};


export default AgentResetPanel;
