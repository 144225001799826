import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { Classes, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { getIn, setIn } from "formik";
import { IEsvcsEnrollment } from "csd.phoenix.models/EsvcsEnrollment";
import { createNewEnrollment } from "../../../stores/enrollmentsStore";
import routes from "../../../routePaths";
import FormHost from "../../shared/form/FormHost";
import { FormModes, CancelButtonState } from '../../shared/form/models/baseTypes';
import { FormikErrorsEx } from "../../shared/form/models/validation";
import DefaultHeader, { IHeaderProps } from "../../shared/form/FormHost/Header";
import { HttpError } from '../../../services/api/http';
import { errorDialogPromise } from "../../shared/dialogs/ErrorDialog";
import { messageDialogPromise } from "../../shared/dialogs/MessageDialog";
import FormContent from "./FormContent";
import { DpmsTypes } from 'csd.phoenix.models/DpmsTypes';


async function saveForm(enrollment: IEsvcsEnrollment) {
  try {
    await createNewEnrollment(enrollment);
    await messageDialogPromise(
      "Data Saved",
      <div>
        <div><Icon intent={Intent.SUCCESS} icon={IconNames.TICK} /> A new enrollment record has been created.</div>
        <div><Icon intent={Intent.SUCCESS} icon={IconNames.TICK} /> An email was sent to the contact email address you entered.</div>
      </div>
      );

  } catch (error) {
    if (error instanceof HttpError) {
        // Http status == Conflict ?
        if (error.statusCode === 409) {
          await errorDialogPromise("An enrollment with the same customer, dpms and product type already exists.");
          return false;
        }
        else if (error.statusCode === 422) {
          await errorDialogPromise("Requested DPMS type does not match customer's current DPMS.");
          return false;
        }
    }
    throw error;
  }

  return true;
}


const initialValues: Partial<IEsvcsEnrollment> = {
  ProductOffering: undefined,
  PartnerId: undefined,
  DpmsType: undefined,
  DpmsVersion: "",
  CustomerId: "",
  CustomerName: "",
  AdminUserFirstName: "",
  AdminUserLastName: "",
  AdminUserEmail: "",
  CCUserEmail: "",
}



const validate = (values: IEsvcsEnrollment) => {
  let errors: FormikErrorsEx<IEsvcsEnrollment> = {};

  // const isAPartnerApi = getIn(values, "ProductOffering") === EsvcsProductOfferings.PartnerApi;
  // if (isAPartnerApi) {
  //   if (!getIn(values, "PartnerId")) {
  //     errors = setIn(errors, "PartnerId", "Partner selection required")
  //   }
  // }

  //const isSenseiCloud = getIn(values, "DpmsType") === DpmsTypes.CMP;
  const dmpsType = getIn(values, "DpmsType");

  if ((dmpsType !== undefined) && (dmpsType !== DpmsTypes.CMP)) {

    if (!getIn(values, "DpmsVersion")) {
      errors = setIn(errors, "DpmsVersion", "Version selection required")
    }

    if (!getIn(values, "TimeZone")) {
      errors = setIn(errors, "TimeZone", "Timezone selection required")
    }
  }

  return errors;
}


const Header: React.FC<IHeaderProps> = (props) => {
  // Make the header the same size as the standard page header
  return <DefaultHeader {...props} containerStyle={{padding: '8px 0'}} />
}



const Comp: React.FC = () => {
  const history = useHistory();

  const handleFormCompleted = useCallback((saved: boolean) => {
    if (saved) {
      history.push(routes.status.path);
    }
  }, [history]);

  return (
    <FormHost<IEsvcsEnrollment>
      formMode={FormModes.Edit}
      initialValues={initialValues as IEsvcsEnrollment}
      title="New Enrollment"
      HeaderComponent={Header}
      onSave={saveForm}
      onFormCompleted={handleFormCompleted}
      cancelButtonState={CancelButtonState.AlwayHide}
      validate={validate}
      disableAutoComplete={true}
      wrapContentInPageComponent={true}
    >
      <div className={Classes.RUNNING_TEXT} style={{marginBottom: '3em'}}>
        Use this form to create a new enrollment request.
        <br/>
        Once you submit this form, a welcome email will be sent to the customer email address you specify.
        <br/>The user will need to click a link in the email, and agree to terms and conditions,
        before the provisioning will start.
      </div>
      <FormContent />
    </FormHost>
  );
}


export default Comp;
