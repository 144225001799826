import React from "react";
import { Redirect } from "react-router";
import { H3, H5 } from "@blueprintjs/core";
import Layout from "../Layout";
import { IEnrollmentConfirmation } from "../../../stores/enrollmentsStore";
import { EsvcsEnrollmentStatuses } from 'csd.phoenix.models/EsvcsEnrollmentStatuses';
import { EsvcsProductOfferings } from 'csd.phoenix.models/EsvcsProductOfferings';
import routes from '../../../routePaths';

const OfficeAcceptedTermsContent: React.FC<IEnrollmentConfirmation> = ({ enrollmentStatus, productOfferingDescription, productOffering, onlineHelpUrl }) => {
  if (enrollmentStatus < EsvcsEnrollmentStatuses.Confirmed) {
    return (
      <Redirect to={routes.officeMode.confirmation.path} />
    );
  }

  return (
    <Layout>
      <div>
        <div className="centered">
          <div
            style={{
              width: "80%",
              margin: "40px 0",
            }}
          >
            <H3>Thank You!</H3>
            <H5>You have completed your enrollment into Carestream Dental's {productOfferingDescription} </H5>
          </div>
        </div>
        <div className="centered">
          <div
            style={{
              width: "80%",
            }}
          >
            Thank you for joining the next generation of Carestream Dental Solutions. You'll receive additional emails with
            instructions.
          </div>
        </div>
        {productOffering === EsvcsProductOfferings.PatientForms && onlineHelpUrl && onlineHelpUrl.length > 0 &&
          <div className="centered">
            <div
              style={{
                width: "80%",
                margin: "40px 0",
              }}
            >
              To get started on how to use Digital Patient Forms, please click <a href={onlineHelpUrl}>here.</a>
            </div>
          </div>
        }
      </div>
    </Layout>
  );
};

export default OfficeAcceptedTermsContent;
