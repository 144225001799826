import React, { useState, useEffect } from "react";
import { H2 } from "@blueprintjs/core";
import PromiseWait from "../shared/PromiseWait";
import { getUserAuthClaims, IAuthClaim } from "../../stores/enrollmentsStore";
import { IColumn } from "../shared/DataTable/models";
import ClientDataTable from "../shared/DataTable/ClientDataTable";

// async function test() {
//   // const response = await http.get<object>(apiUrl.segment("enrollment/test").toString());
//   // console.log("API RESPONSE:",  response.data);
//   // return response;

//   const partners = await getPartners();
//   console.log("Partners: ", partners)
// }


const columns: IColumn<IAuthClaim>[] = [
  {header: "Claim", name: "Type"},
  {header: "Value", name: "Value"}
]

const  getRowKey = (_: IAuthClaim, index: number) => index.toString();


const Comp: React.FC = () => {
  const [promise, setPromise] = useState<Promise<IAuthClaim[]>>();

  useEffect(() => {
    setPromise(getUserAuthClaims())
  }, []);

  return (
    <div>
      <H2>Azure AD Claims</H2>
      {promise && <PromiseWait promise={promise} >
        {(claims) =>
          <div>
            <ClientDataTable<IAuthClaim>
              columns={columns}
              data={claims}
              getRowKey={getRowKey}
            />
          </div>
        }
      </PromiseWait>}
    </div>
  );
}


export default Comp;
