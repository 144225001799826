import React, { memo, useCallback } from "react";
import { Icon, Popover, Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { AccountInfo } from "@azure/msal-browser";
import useAuthService, { getAccountEmail } from "../../../../services/authService";



const MenuContent: React.FC<{user: AccountInfo}> = ({ user }) => {
  const authService = useAuthService();
  const logout = useCallback(async () => await authService.logout(), [authService]);

  return (
    <div style={{padding: '1em', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Icon icon={IconNames.USER} intent={Intent.PRIMARY} iconSize={32} style={{paddingBottom: 10}} />
      <div><strong>{user.name}</strong></div>
      <div>{getAccountEmail(user)}</div>
      <div style={{paddingTop: '1em'}}>
        <Button icon={IconNames.LOG_OUT} text="Logout" intent={Intent.WARNING} onClick={logout} />
      </div>
    </div>
  );
}

const UserMenu: React.FC = memo(() => {
  const { getLoggedInUser } = useAuthService();
  const user = getLoggedInUser();
  console.assert(user);

  return (
    <Popover content={<MenuContent user={user!} />} >
      <Button icon={IconNames.USER} intent={Intent.PRIMARY} title={user!.username} minimal={true}>{user?.name}</Button>
    </Popover>
  );
})

export default UserMenu;


