import React, { memo } from "react";
import Content from "../NoData";

interface IProps {
  show: boolean;
  colSpan: number;
  message?: string;
}

const Loading: React.FC<IProps> = memo(({show, colSpan, message}) => {
  if (!show) {
    return null;
  }

  return (
    <tr>
      <td colSpan={colSpan} style={{padding: '1em'}}>
        <Content hide={!show} label={message} />
      </td>
    </tr>
  );
})


export default Loading;
