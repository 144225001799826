import React from "react";
import { Callout, Intent } from "@blueprintjs/core";


interface IProps {
  title?: string;
  description: React.ReactNode
  style?: React.CSSProperties;
}


const Error: React.FC<IProps> = ({title = "Oops! Something went wrong :(", description, style}) => {
  const _style = { maxWidth: 600,  ...style }

  return (
    <div className="centered">
      <Callout
            title={title}
            intent={Intent.DANGER}
            className="elevated"
            style={_style}
      >
        {description}
      </Callout>
    </div>
  );
}


export default Error;
