import React from "react";
import { Dialog, Button, Classes  } from "@blueprintjs/core";

export type TContentFragment = object | string | null;

export type FnOpenJsonDialog = (content: TContentFragment) => void;

export interface IDlgProps  {
    title: string;
    content: TContentFragment;
    isOpen: boolean;
    onClose: () => void;
};

const JsonDisplayDialog: React.FC<IDlgProps> = (props: IDlgProps) => {
    if ( !props.isOpen ) return null;
    return (
        <>
            <Dialog isOpen={true} style={{ width: '80%' }} >
                <div className={Classes.DIALOG_HEADER}>
                    {props.title}
                </div>
                <div className={Classes.DIALOG_BODY} >
                    <pre style={{overflowY:'auto', overflowX:'auto', maxHeight:'500px'}} >
                        {JSON.stringify(props.content, null, 2)}
                    </pre>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button className={Classes.DIALOG_CLOSE_BUTTON} onClick={props.onClose}>Close</Button>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default JsonDisplayDialog;
