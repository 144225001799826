import React from "react";
import { IEsvcsEnrollment } from "csd.phoenix.models/EsvcsEnrollment";
import { Button } from "@blueprintjs/core";
import { EmailDialog } from "../../shared/dialogs/EmailDialog";
import { ProvisionDialog } from "../../shared/dialogs/ProvisionDialog";
import useDialogState from "../../shared/useDialogState";
import { EsvcsEnrollmentStatuses } from 'csd.phoenix.models/EsvcsEnrollmentStatuses';
import { DpmsTypes } from 'csd.phoenix.models/DpmsTypes';

interface IProps {
 enrollment: IEsvcsEnrollment;
}

const StatusUtils: React.FC<IProps> = ({enrollment}) => {
  const [emailIsOpen, openEmail, closeEmail] = useDialogState();
  const [provisionIsOpen, openProvision, closeProvision] = useDialogState();

  const enableReprovisioning = (enrollment.EnrollmentStatus === EsvcsEnrollmentStatuses.Confirmed)
    || ((enrollment.EnrollmentStatus > EsvcsEnrollmentStatuses.Confirmed)
    && (enrollment.EnrollmentStatus <= EsvcsEnrollmentStatuses.GatewayProvisioning)
      && (enrollment.DpmsType !== DpmsTypes.CMP));

  const enableResendingEmail =
    (enrollment.EnrollmentStatus < EsvcsEnrollmentStatuses.SenseiDBProvisioning)
    || ((enrollment.EnrollmentStatus === EsvcsEnrollmentStatuses.GatewayProvisioned && !enrollment.IsHosted)
      || (enrollment.EnrollmentStatus === EsvcsEnrollmentStatuses.Completed));


  return (
    <>
      <Button icon="envelope"
        intent="none"
        title="Re-send Email"
        minimal={true}
        onClick={openEmail}
        disabled={!enableResendingEmail}>
      </Button>

      {emailIsOpen ?
        <EmailDialog isOpen={true}
          onClose={closeEmail}
          enrollment={enrollment}>
        </EmailDialog>
        : null}

      <Button icon="cloud-upload"
        intent="none"
        title="Resume/Restart Provisioning"
        minimal={true}
        onClick={openProvision}
        disabled={!enableReprovisioning}>
      </Button>

      {provisionIsOpen ?
        <ProvisionDialog
          isOpen={true}
          onClose={closeProvision}
          enrollment={enrollment}>
        </ProvisionDialog>
        : null}
    </>
  );
}

export default StatusUtils;
