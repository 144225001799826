import React, { memo } from "react";
import { Intent } from "@blueprintjs/core";
import TextSpan from "../TextSpan";

interface IProps {
  error?: Error | unknown;
  colSpan: number;
}

const Loading: React.FC<IProps> = memo(({colSpan, error}) => {
  if (!error) {
    return null;
  }

  return (
    <tr>
      <td colSpan={colSpan} style={{padding: '1em'}}>
        <TextSpan className="centered" intent={Intent.DANGER}>Oops! There was an error loading data :(</TextSpan>
      </td>
    </tr>
  );
})


export default Loading;
