import React, { useCallback } from "react";
import classnames from "classnames";
import {
  FormGroup,
  Intent,
  Checkbox,
  Switch,
  CheckboxProps,
} from "@blueprintjs/core";
import { isNil } from "../../../../utils/objectUtils";
import css from "../form.module.scss";
import { ICheckboxFieldProps } from "../models";
import { validationMethodFactory } from "../models/validation";
import useFormContext from "../FormContext";
import { FastField, FieldProps, getIn } from "formik";
import PrintedCheckIcon from "./PrintedCheckIcon";

// function getFieldLabel(fieldProps: ICheckboxFieldProps) {
//   return `${field.value === true ? fieldProps.checkedLabel || fieldProps.label : fieldProps.uncheckedLabel || fieldProps.label}`;
// }

type Props<T = any> = FieldProps<T> & {
  fieldProps: ICheckboxFieldProps;
  className: string;
};

const BoundCheckboxField = ({ field, form, fieldProps }: Props) => {
  const {
    isEditing,
    isPrinting,
    fieldChangedSignal,
    fieldPropOverrides,
  } = useFormContext();
  const error =
    (form.submitCount || getIn(form.touched, field.name)) &&
    getIn(form.errors, field.name);
  const isChecked = field.value === true;
  const intent = error ? Intent.DANGER : Intent.NONE;
  const className = classnames(css.formPart);
  const label = `${
    field.value === true
      ? fieldProps.checkedLabel || fieldProps.label
      : fieldProps.uncheckedLabel || fieldProps.label
  }`;

  const onChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      field.onChange(e);
      fieldChangedSignal.dispatch(field.name, !field.value, field.value);
    },
    [field, fieldChangedSignal]
  );

  const controlProps: CheckboxProps = {
    ...field,
    onChange: onChange,
    checked: isChecked,
    disabled: !isEditing,
    className: classnames(error && "error"),
    ...fieldPropOverrides[field.name],
  };

  const control = isPrinting ? (
    <PrintedCheckIcon
      isChecked={isChecked}
      label={label}
      className={className}
    />
  ) : fieldProps.useAltStyle ? (
    <Switch {...controlProps}>{label}</Switch>
  ) : (
    <Checkbox {...controlProps} indeterminate={isNil(field.value)}>
      {label}
    </Checkbox>
  );

  return (
    <FormGroup
      intent={intent}
      helperText={isEditing ? error || <>&nbsp;</> : undefined}
      style={fieldProps.style}
      className={className}
    >
      {control}
    </FormGroup>
  );
};

const CheckboxField = (props: ICheckboxFieldProps) => {
  console.assert(props && props.binding);

  const validate = validationMethodFactory(props);
  return (
    <FastField
      name={props.binding}
      fieldProps={props}
      component={BoundCheckboxField}
      validate={validate}
    />
  );
};

export default CheckboxField;
