import React from "react";
import { Callout, Intent } from "@blueprintjs/core";
import NotFound from "../routing/NotFound";
import { AppModeTransitionError } from "../../services/appModeService";


interface IProps {
  title?: string;
  content?: string | React.ReactElement | React.ReactFragment;
}

interface IState {
  error?: Error;
}


class ErrorBoundary extends React.PureComponent<IProps, IState> {
  state: IState = { };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {

    this.setState({ error });
    console.error("Render Error:", error, errorInfo);
  }


  render() {
    if (this.state.error instanceof AppModeTransitionError) {
      return <NotFound />
    }

    if (this.state.error) {
      const { title = "Oops! Something went wrong :("} = this.props;
      const content = this.props.content || "Please reload the page and try again."

      return (
        <div className="centered">
          <Callout title={title} intent={Intent.DANGER} icon={null} className="elevated" style={{maxWidth: 400}} >
            {content}
          </Callout>
        </div>
      );
    }

    return this.props.children;
  }
}


export default ErrorBoundary;
