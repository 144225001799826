import React, { memo, useMemo, useCallback, useEffect } from "react";
import { useFormikContext } from "formik";
import classnames from "classnames";
import { showFormValidationWarning } from "../../../../services/ToasterService";
import Page from "../../Page";
import ModalWait from "../../ModalWait";
import useFormContext from "../FormContext";
import { CancelButtonState } from "../models/baseTypes";
import css from "../form.module.scss";
import { IHeaderProps } from "./Header";
import { IFooterProps } from "./Footer";


interface IProps {
  warnIfDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
  cancelButtonState?: CancelButtonState;
  Header:  React.ElementType<IHeaderProps>;
  Footer:  React.ElementType<IFooterProps>;
  title: React.ReactNode;
  isLoading: boolean;
  style?: React.CSSProperties,
  wrapContentInPageComponent?: boolean;
}


const headerContainerStyle: React.CSSProperties = { padding: 0 };
const footerContainerStyle: React.CSSProperties = { padding: 0 };

const Content = <T extends {}>(props: React.PropsWithChildren<IProps>) => {
  const { warnIfDirty, setIsDirty, Header, Footer, title, cancelButtonState, isLoading } = props;
  const { disableAutoComplete } = useFormContext();
  const formik = useFormikContext<T>();

  //console.log("Is dirty: ", formik.dirty)
  useEffect(() => {
    if (warnIfDirty) {
      setIsDirty(formik.dirty);
    }
  }, [warnIfDirty, formik.dirty, setIsDirty]);

  const isBusy = formik.isSubmitting || formik.isValidating;
  const canSubmit = !isBusy; // && formik.isValid;
  //console.log(`is-valid: ${formik.isValid}, is-dirty: ${formik.dirty}, can-submit: ${canSubmit}, isBusy: ${isBusy}, touched: `, formik.touched)

  const header = useMemo(() =>
    <Header warnIfDirty={warnIfDirty} isDirty={formik.dirty}>
      {typeof title === "string" ? <h2>{title}</h2> : title}
    </Header>
    , [formik.dirty, title, warnIfDirty, Header]);

  const triggerSubmit = useCallback(async () => {
    // Note: We must call `formik.submitForm` even if the form is invalid,
    // so that Formik increases the `forms.submitCount` value -- which we
    // utilize to force displaying validation errors.
    await formik.submitForm();
    if (!formik.isValid) {
      showFormValidationWarning();
    }
  }, [formik]);

  const footer = useMemo(() =>
    <Footer
      className={classnames("centered", css.footer)}
      isDirty={formik.dirty}
      cancelButtonState={cancelButtonState}
      resetForm={formik.handleReset}
      submitForm={canSubmit ? triggerSubmit : undefined}
    />, [canSubmit, cancelButtonState, formik.dirty, formik.handleReset, triggerSubmit, Footer]);

  const InnerContent = useCallback(() => (
    <>
      <ModalWait isOpen={isBusy} rightOrBottomText="Please Wait ..." showBorder={true} />
      <form
        autoComplete={disableAutoComplete ? 'off' : undefined} // Note: Chrome ignores this :(
        onSubmit={formik.handleSubmit}
        className={css.form}
        style={props.style}
      >
          {props.children}
      </form>
    </>
  ),[disableAutoComplete, formik.handleSubmit, isBusy, props.children, props.style]);

  if (props.wrapContentInPageComponent !== true) {
    return <InnerContent />;
  }

  // Use `Page` container
  return (
    <Page
      //className={css.formHost}
      isLoading={isLoading}
      header={header}
      footer={footer}
      headerContainerStyle={headerContainerStyle}
      footerContainerStyle={footerContainerStyle}
    >
      <InnerContent />
    </Page>

  );
};


export default memo(Content) as typeof Content;
