import React from "react";
import { NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";


const title = "Access Restricted";
const desc = "Sorry, your account doesn't have access to this app/page."
const msg = "If you believe you should have required access, please contact E-Services.";

const AccessDenied: React.FC = () => <NonIdealState className="centered" icon={IconNames.SHIELD} title={title}  description={desc} >
  {msg}
</NonIdealState>;

export default AccessDenied;
