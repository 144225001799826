import React from "react";
import { Intent } from "@blueprintjs/core";
import { ICustomer } from "../../../../models/Customer";
import { addDays } from "../../../../utils/dateUtils";
import CollapsiblePanel, { useCollapsiblePanelStates } from "../../../shared/CollapsiblePanel";
import Page from "../../../shared/Page";
import TextSpan from "../../../shared/TextSpan";
import AppointmentSearchPanel from "./AppointmentSearchPanel";
import ClientLogsPanel from "./ClientLogsPanel";
import CustomerInfo from "./CustomerInfo";
import EgressLogsPanel from "./EgressLogsPanel";
import PatientSearchPanel from "./PatientSearchPanel"
import ResponsiblePartySearchPanel from "./ResponsiblePartySearchPanel";
import ServiceLogsPanel from "./ServiceLogsPanel";


const CustomerPageContent: React.FC<ICustomer> = (props) => {
  // Main component state
  // Note that it is IMPORTANT to set the min date possible to 92 days prior
  // to today, if you want the user to be able to quickly select to view all
  // logs despite the fact we currently (Feb 2024) store only 2 months of logs.
  // This is a limitation of the blueprint.js DateRange control, which has
  // "One Month Prior" and "3 Months Prior" as speed-selection options.
  // We want it to always enable the 3-month option as a proxy for "all recs".
  let today = new Date();
  const minDatePossible = new Date(addDays(today, -92).setHours(0, 0, 0, 0));
  const initialMinDate = new Date(addDays(today, -3).setHours(0, 0, 0, 0));
  const maxDatePossible = new Date(today.setHours(23, 59, 59, 999));
  const isEgressEnabled = !!props.tenantInstallationId;

  // Collapsible panel collection state
  const [IsPanelOpen, togglePanelOpenState] = useCollapsiblePanelStates(
    ["clientLogs", "serviceLogs", "egressLogs", "patientSearch", "responsiblePartySearch", "appointmentSearch"],
    "uiState.logs.openPanels"
  );

  return (
    <Page header={`${props.id}: ${props.name}`} >
      <CustomerInfo {...props} />
      {/* Panels */}
      <div style={{ marginTop: 20, marginBottom: 40 }}>
        <CollapsiblePanel
          title="Client Logs"
          contentPlaceHolder="Display logs sent by this customer's Sync Agent"
          isOpen={IsPanelOpen("clientLogs")}
          toggleIsOpen={() => togglePanelOpenState("clientLogs")}
        >
          <ClientLogsPanel {...props}
            minDate={minDatePossible}
            maxDate={maxDatePossible}
            initMinDate={initialMinDate}
            pageNum={1} pageSize={20} />
        </CollapsiblePanel>

        <CollapsiblePanel
          title="Server Logs"
          contentPlaceHolder="Display server side logs for this customer"
          isOpen={IsPanelOpen("serviceLogs")}
          toggleIsOpen={() => togglePanelOpenState("serviceLogs")}
        >
          <ServiceLogsPanel {...props}
            minDate={minDatePossible}
            maxDate={maxDatePossible}
            initMinDate={initialMinDate}
            pageNum={1} pageSize={20} />
        </CollapsiblePanel>

        <CollapsiblePanel
          title="Egress Logs"
          contentPlaceHolder={isEgressEnabled
            ? "Display server side egress logs for this customer"
            : <TextSpan intent={Intent.WARNING}>Customer is not enabled for egress</TextSpan>}
          isDisabled={!isEgressEnabled}
          isOpen={IsPanelOpen("egressLogs") && (!!isEgressEnabled)}
          toggleIsOpen={() => togglePanelOpenState("egressLogs")}
        >
          <EgressLogsPanel  {...props}
            minDate={minDatePossible}
            maxDate={maxDatePossible}
            initMinDate={initialMinDate}
            pageNum={1} pageSize={20}
          />
        </CollapsiblePanel>

        <CollapsiblePanel
          title="Patient Search"
          contentPlaceHolder="Search for patient(s) of this customer."
          isOpen={IsPanelOpen("patientSearch")}
          toggleIsOpen={() => togglePanelOpenState("patientSearch")}
        >
          <PatientSearchPanel customerId={props.id} pageSize={20} />
        </CollapsiblePanel>

        <CollapsiblePanel
          title="Responsible Party Search"
          contentPlaceHolder="Search for Responsible Part(ies) of this customer."
          isOpen={IsPanelOpen("responsiblePartySearch")}
          toggleIsOpen={() => togglePanelOpenState("responsiblePartySearch")}
        >
          <ResponsiblePartySearchPanel customerId={props.id} pageSize={20} />
        </CollapsiblePanel>

        <CollapsiblePanel
          title="Appointment Search"
          contentPlaceHolder="Search for appointment(s) of this customer."
          isOpen={IsPanelOpen("appointmentSearch")}
          toggleIsOpen={() => togglePanelOpenState("appointmentSearch")}
        >
          <AppointmentSearchPanel customerId={props.id} pageSize={20} />
        </CollapsiblePanel>
      </div>
    </Page >

  );
}

export default CustomerPageContent;
