import { useEffect } from "react";
import { useFormikContext } from "formik";
import usePrevious from "../usePrevious";
import useFormContext, { IFormContext } from "./FormContext";


export type FormLoadedEventHandler<T> = (values: T, context: IFormContext) => void;
export type FieldChangedEventHandler<T> = (
  fieldName: string,
  value: unknown,
  oldValue: unknown,
  values: T,
  setFieldValue: (field: string, value: any) => void,
  context: IFormContext,
  ) => void;

const useFieldChangeTracker = <T extends {}>(fieldChangedEventHandler: FieldChangedEventHandler<T>, formLoadedEventHandler?: FormLoadedEventHandler<T>) => {
  const formik = useFormikContext<T>();
  const prevInitialValues = usePrevious(formik.initialValues);
  const context  = useFormContext();

  useEffect(() => {
    //the check has the effect of only entering the event handler on the initial load and when the form is submitted
    if (formLoadedEventHandler && formik.initialValues !== prevInitialValues) {
      formLoadedEventHandler(formik.values, context);
    }
  }, [context, formLoadedEventHandler, formik.initialValues, prevInitialValues, formik.values])


  useEffect(() => {
    if (!context.isEditing) {
      return;
    }

    //console.log("change handler reset");
    const onFieldChanged = (fieldName: string, value: unknown, oldValue: unknown) =>
      fieldChangedEventHandler(fieldName, value, oldValue, formik.values, formik.setFieldValue, context);

    context.fieldChangedSignal.addListener(onFieldChanged);
    return () => context.fieldChangedSignal.removeListener(onFieldChanged);
  }, [context, fieldChangedEventHandler, formik.values, formik.setFieldValue]);
}

export default useFieldChangeTracker;
