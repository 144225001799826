import React, { memo, useCallback, useState } from "react";
import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import BaseDialog from "./BaseDialog";
import { IEsvcsEnrollment } from "csd.phoenix.models/EsvcsEnrollment";
import { retryProvisioning } from "../../../stores/enrollmentsStore";
import { showToast } from "../../../services/ToasterService";

interface IProvisionActions {
  okButtonLabel?: string;
  okButtonIntent?: Intent;
  onOk: () => void;
  onCancel: () => void;
  disableButtons: boolean;
}

const ProvisionActions: React.FC<IProvisionActions> = memo(({ okButtonLabel, okButtonIntent = Intent.SUCCESS, onOk, onCancel, disableButtons: disabledButtons }) => {
  return (
    <div className="centered">
      <Button
        text={okButtonLabel}
        intent={okButtonIntent}
        onClick={onOk}
        style={{ minWidth: 120, margin: '0 1em' }}
        disabled={disabledButtons}
      />
      <Button
        text="Cancel"
        intent={Intent.WARNING}
        onClick={onCancel}
        style={{ minWidth: 120, margin: '0 1em' }}
        disabled={disabledButtons}
      />
    </div>)
})

interface IProvisionDialogProps {
  title?: string;
  buttonLabel?: string;
  isOpen: boolean;
  enrollment: IEsvcsEnrollment;
  onClose: () => void;
}

export const ProvisionDialog: React.FC<IProvisionDialogProps> = ({ title = "Resume/Restart Provisioning", buttonLabel = "OK", isOpen, onClose, enrollment }) => {
  const [secondConfirm, setSecondConfirm] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const handleProvisionConfirm = useCallback(() => {
    if (enrollment.RecordId)
      setSecondConfirm(true);
  }, [enrollment]);

  const handleProvisionFinal = useCallback(() => {
    setDisableButtons(true);
    if (enrollment.RecordId)
      retryProvisioning(enrollment.RecordId).then((r) => {
        (r) ? showToast("Provisioning request has been sent.", Intent.SUCCESS)
          : showToast("There was a problem resuming/restarting provisioning.  Please try again.", Intent.DANGER);

        onClose();
      });
  }, [enrollment, onClose]);


  return (
    <>
      <BaseDialog
        title={title}
        isOpen={isOpen}
        onClose={onClose}
        icon={IconNames.CLOUD_UPLOAD}
        canEscapeKeyClose={true}
        isCloseButtonShown={true}
        canOutsideClickClose={true}
        actions={<ProvisionActions onOk={secondConfirm ? handleProvisionFinal : handleProvisionConfirm} onCancel={onClose} okButtonLabel={buttonLabel} disableButtons={disableButtons} />}
      >
        <div style={{ textAlign: 'center' }}>
          {secondConfirm ? "Are you absolutely sure you would like to proceed?"
            : `Would you like to resume or restart the provisioning process for ${enrollment.CustomerName}?`}
        </div>
      </BaseDialog>
    </>
  )
}
