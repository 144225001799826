import React, { useMemo } from "react";
import { useParams } from "react-router";
import validator from "validator";
import { HttpError } from "../../../services/api/http";
import { markEnrollmentAsViewed } from "../../../stores/enrollmentsStore";
import PromiseWait from "../../shared/PromiseWait";
import Layout from "../Layout";
import ErrorCallout from "../../shared/ErrorCallout";
import css from "../styles.module.scss";
import Content from "./Content";

async function loadData(recordId: string) {
  try {
    const result = await markEnrollmentAsViewed(recordId);
    return result;
  } catch (error) {
    if (error instanceof HttpError && error.statusCode === 410) {
      // The GUID dind't match any record with status new or viewed
      throw new Error("Looks like you've followed an invalid or expired link!");
    }
    throw error;
  }
}

const Welcome = () => {
  const { id } = useParams<{ id: string }>();
  const isValidGuidFormat = useMemo(() => validator.isUUID(id), [id]);
  const promise = useMemo(
    () => (isValidGuidFormat ? loadData(id) : Promise.resolve(null)),
    [id, isValidGuidFormat]
  );

  return (
    <Layout contentContainerClassName={css.welcome} showCsdFlow={true}>
      {!isValidGuidFormat && (
        <ErrorCallout
          title="Sorry, we can't process your request!"
          description={
            <>
              <div>We don't recognize the link you used!</div>
              <div>Please check to make sure you've followed a valid link.</div>
            </>
          }
        />
      )}
      {isValidGuidFormat && (
        <PromiseWait
          promise={promise}
          component={Content}
          mapComponentProps={PromiseWait.mapAsIs}
          useModalWait={false}
          waitProps={{ showBorder: false }}
          errorMessage="Looks like you may have followed an invalid or expired link."
        />
      )}
    </Layout>
  );
};

export default Welcome;
