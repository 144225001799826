import React, { memo } from "react";
import { Icon, Classes, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classnames from "classnames";

interface IProps {
  hide?: boolean;
  label?: string;
}

const NoData: React.FC<IProps> = memo(({hide = false, label = "No Data"}) => {
  if (hide) {
    return null;
  }

  return (
    <div className={classnames("centered", Classes.TEXT_MUTED)}>
      <Icon icon={IconNames.TH_DISCONNECT} intent={Intent.NONE} style={{opacity: 0.5}} />
      &nbsp; &nbsp;<em>{label}</em>
    </div>);
})


export default NoData;
