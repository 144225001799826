
export interface IPagedApiResponse<T> {
  totalRecords?: number;
  currentPage: number;
  pageSize: number;
  data: T[];
}

// export interface IFirstPageApiResponse<T> extends IPagedApiResponse<T> {
//   CurrentPage: 1;
//   TotalRecords: number;
// }

export function isFirstPageOfApiResponse(response: IPagedApiResponse<any>) {
  return response.currentPage === 1 && typeof response.totalRecords !== 'undefined';
}

// export type PagedApiResponse<T> = IFirstPageApiResponse<T> | INextPageApiResponse<T>;

// export interface PagedApiResponse<T> {
//   TotalRecords?: number;
//   CurrentPage: number;
//   PageSize: number;
//   Data: T[];
// }



