import React, { useEffect, useState } from 'react';
import { Route, useLocation } from "react-router-dom";
import Login from "../auth/Login";
import { AppModes, isAppMode, useAppMode } from '../../services/appModeService';
import useAuthService, { UnauthenticatedTemplate, AuthenticatedTemplate,  } from "../../services/authService";
import EnrollmentStatus from "../Pages/EnrollmentStatus";
import PageLayout from "../Pages/PageLayout";


const PartnerRoutes: React.FC = () => {
  const [isRedeemingInvitation, setIsRedeemingInvitation ] = useState(false);
  const { setAppMode } = useAppMode();
  const authService = useAuthService();
  const { hash } = useLocation();
  //console.log(hash);

  useEffect(() => {
    setAppMode(AppModes.Partner);
    // Are we being re-directed here after because a partner user redeemed a B2C invitation?
    if (hash) {
      const searchParams = new URLSearchParams(hash.substring(1));
      // If both id_token and state params are present, then we're handling an invitation redemption
      const idToken = searchParams.get("id_token");
      const encodedState = searchParams.get("state");
      if (idToken && encodedState) {
        //console.log(decodeURIComponent(encodedState!));
        const { redeemInvitation = false } = JSON.parse(decodeURIComponent(encodedState!)) || {};
        console.log("Redeeming Azure AD B2C Invitation:", redeemInvitation);
        setIsRedeemingInvitation(true);
        // The user should already have an active login session. However our instance
        // of MSAL doesn't know about it.  So force MSAL to use the login session.
        authService.login();
      }
    }
  }, [authService, hash, setAppMode]);


  if (!isAppMode(AppModes.Partner) || isRedeemingInvitation)
    return null; // Wait until mode switch effect runs

  return (
    <>
      <UnauthenticatedTemplate>
        <Login isPartnerLogin={true} />
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <PageLayout>
          <Route component={EnrollmentStatus} />
        </PageLayout>
      </AuthenticatedTemplate>
    </>
  );
}

export default PartnerRoutes;
