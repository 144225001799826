import {
  EsvcsProductOfferings,
  EsvcsProductOfferings_Descriptions,
} from "csd.phoenix.models/EsvcsProductOfferings";
import {
  EsvcsEnrollmentStatuses,
  EsvcsEnrollmentStatuses_Descriptions,
} from "csd.phoenix.models/EsvcsEnrollmentStatuses";
import {
  DpmsTypes,
  DpmsTypes_Descriptions,
} from "csd.phoenix.models/DpmsTypes";
import { IFieldOption } from "./form/models";
import { getEnumValues } from "../../utils/objectUtils";
import { isLocaleUK } from "../../i18n";

export function getProductList(excludePartner: boolean = false) {

  const ProductList: IFieldOption[] = getEnumValues(EsvcsProductOfferings)
    .map((p) => ({ value: p, text: EsvcsProductOfferings_Descriptions[p] }));

  excludePartner && ProductList.splice(ProductList.findIndex(p => p.value === EsvcsProductOfferings.PartnerApi), 1);

  return ProductList;
}

export function getEnrollmentStatusList() {

  const statusList: IFieldOption[] = getEnumValues(
    EsvcsEnrollmentStatuses
  ).map((s) => ({ value: s, text: EsvcsEnrollmentStatuses_Descriptions[s] }));

  return statusList;
}

export function getDpmsList() {
  let dmpsList: IFieldOption[] = [];

  if (isLocaleUK())
    dmpsList = [
      { value: DpmsTypes.R4, text: DpmsTypes_Descriptions.R4 },
      { value: DpmsTypes.CMP, text: DpmsTypes_Descriptions.CMP }
    ];
  else
    dmpsList = [
      { value: DpmsTypes.SoftDent, text: DpmsTypes_Descriptions.SoftDent },
      { value: DpmsTypes.Orthotrac, text: DpmsTypes_Descriptions.Orthotrac },
      { value: DpmsTypes.WinOMS, text: DpmsTypes_Descriptions.WinOMS },
      { value: DpmsTypes.PracticeWorks, text: DpmsTypes_Descriptions.PracticeWorks },
      { value: DpmsTypes.CMP, text: DpmsTypes_Descriptions.CMP }
    ];

  return dmpsList;
}
