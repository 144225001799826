import React, { useMemo } from "react";
import { IFieldOption, ITimeZoneFieldProps } from "../../models";
import SelectField from "../SelectField";
import { getTimeZones } from "../../../../../utils/dateUtils";

const getFieldList = (): IFieldOption[] => {

  var timeZones: IFieldOption[] = getTimeZones()
    .filter(tz => tz.startsWith("America/", 0)
      || tz.startsWith("Europe/", 0))
    .map((value) => ({
      value,
      text: value
    }));

  return timeZones;
}


const TimeZoneField: React.FC<ITimeZoneFieldProps> = (props) => {
  let options = useMemo(() => getFieldList(), []);

  return (
    <SelectField {...props} options={options} />
  );
}


export default TimeZoneField;
