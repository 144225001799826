import React, {  useCallback, useMemo } from "react";
import { IconNames } from "@blueprintjs/icons";
import { Button, Callout, Intent } from "@blueprintjs/core";
import { DpmsTypes } from 'csd.phoenix.models/DpmsTypes';
import useAppConfig from "../../../shared/useAppConfig";
import FormHost from "../../../shared/form/FormHost";
import { FormModes } from "../../../shared/form/models/baseTypes";
import { FormikErrorsEx, IValidation, ValidationType, groupErrorsKey } from "../../../shared/form/models/validation";
import TextField from "../../../shared/form/fields/TextField";
import SelectField from "../../../shared/form/fields/SelectField";
import { sendAgentUpdates } from "../../../../stores/syncAgentsStore";
import { getDpmsList } from "../../../shared/ListData";
import { useFormikContext } from "formik";
import { IFieldOption } from "../../../shared/form/models";
import AnimatedReveal from "../../../shared/AnimatedReveal";
import { IAgentUpdateResponse  } from "../../../../models/AgentUpdateResponse";


interface IProps {
  onAgentUpdatesSent: (results: IAgentUpdateResponse[]) => void;
}

//#region Form handling
interface IFormState {
  agentId?: string;
  dpmsType?: DpmsTypes;
  versionThreshold?: number;
  installerUrl: string;
}

const sapIdValidations: IValidation[] = [
  { type: ValidationType.SapId, fixedLengthOnly: true },
];

const versionNumberValidations: IValidation[] = [
  { type: ValidationType.Number, min: 0, max: 9999 },
];

// We will limit this to our specific instaler URL format in order to reduce attack surface
const installerUrlRegEx = /^https:\/\/esvcsgatewayv2stor(e|age)[a-z0-9-]{2,10}\.blob\.core\.windows\.net\/[a-zA-Z0-9/\-_.]{2,256}\.exe$/
const urlValidations: IValidation[] = [
  { type: ValidationType.Required },
  {
    type: ValidationType.Custom,
    validate: (value => installerUrlRegEx.test(value) ? undefined: "Expected format: https://esvcsgatewayv2stor(e/age){env}.blob.core.windows.net/...{installerPath}.exe")
  },
];

//#endregion

const FormHeader: React.FC = () => {
  const { errors } = useFormikContext<IFormState>();
  const formLevelError = (errors as FormikErrorsEx<IFormState>)[groupErrorsKey]?.["$form"];

  return (
    <AnimatedReveal show={!!formLevelError} containerStyle={{width: 500, paddingBottom: 10}}>
      <Callout intent={Intent.DANGER}>{formLevelError}</Callout>
    </AnimatedReveal>)
}


const FormFooter: React.FC = () => {
  const { isSubmitting, isValid, isValidating, submitForm } = useFormikContext<IFormState>();
  const isSubmitDisabled = isSubmitting || isValidating || !isValid;

  return (
    <div>
      <Button
        icon={IconNames.COMPRESSED}
        disabled={isSubmitDisabled}
        intent={Intent.PRIMARY}
        onClick={submitForm}
      >Send Agent Updates
      </Button>
    </div>
  );
}

const AgentUpdateForm: React.FC<IProps> = ({onAgentUpdatesSent}) => {
  const { agentInstallerUrl = '' } = useAppConfig();
  const initialValues: IFormState = useMemo(() => ({
    agentId: undefined,
    dpmsType: undefined,
    versionThreshold: undefined,
    installerUrl: agentInstallerUrl,
  }), [agentInstallerUrl]);

  const dpmsList = useMemo<IFieldOption[]>(() => getDpmsList().filter(dpms => dpms.value !== 0), []);

  const onValidateForm  = useCallback((data: IFormState) => {
    let errors: FormikErrorsEx<IFormState> = {};
    if (!!data.agentId && !!data.dpmsType) {
      errors[groupErrorsKey] = { "$form": "Either Agent ID or DPMS type should be specified, not both." }
    }
    return errors;
  }, []);

  const onFormSubmit = useCallback(async ({ installerUrl, dpmsType, versionThreshold, agentId }: IFormState) => {
    if (agentId === '') agentId = undefined;
    var results = await sendAgentUpdates(installerUrl, dpmsType, !!versionThreshold ? `6.0.0.${versionThreshold}` : undefined, agentId);
    onAgentUpdatesSent(results);
    return true;
  }, [onAgentUpdatesSent]);

  return(
    <>
      <FormHost<IFormState>
        formMode={FormModes.Edit}
        initialValues={initialValues}
        dontWarnIfDirty={true}
        formContentStyle={{margin: "20px 0"}}
        onSave={onFormSubmit}
        validate={onValidateForm}
        >
          <FormHeader />

          <div className="columns">
            <div className="column  is-2">
              <SelectField
                label="DPMS"
                binding="dpmsType"
                options={dpmsList}
                validations={[]}
              />
            </div>
            <div className="column is-2">
              <TextField
                label="Max version to update"
                binding="versionThreshold"
                validations={versionNumberValidations}
              />
            </div>
            <div className="column is-2">
              <TextField
                label="Sync Agent ID"
                binding="agentId"
                validations={sapIdValidations}
              />
            </div>
          </div>
          <div className="columns" style={{marginTop:0}}>
            <div className="column is-10">
              <TextField
                  label="Installer URL"
                  binding="installerUrl"
                  validations={urlValidations}
              />
            </div>
          </div>
        <FormFooter />
      </FormHost>
    </>
  );
}

export default AgentUpdateForm;
