"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantLocalesExtensions_Factory = exports.Date_Factory = exports.TenantLocales_DisplayOrders = exports.TenantLocales_Descriptions_English = exports.TenantLocales_Descriptions = exports.TenantLocales_translateDescriptionBack = exports.TenantLocales_translateDescriptions = exports.TenantLocales = void 0;
var TenantLocales;
(function (TenantLocales) {
    TenantLocales[TenantLocales["England"] = 1] = "England";
    TenantLocales[TenantLocales["Scotland"] = 2] = "Scotland";
    TenantLocales[TenantLocales["Wales"] = 3] = "Wales";
    TenantLocales[TenantLocales["Northen_Ireland"] = 4] = "Northen_Ireland";
    TenantLocales[TenantLocales["USA"] = 5] = "USA";
    TenantLocales[TenantLocales["Saudi"] = 6] = "Saudi";
})(TenantLocales = exports.TenantLocales || (exports.TenantLocales = {}));
function TenantLocales_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.TenantLocales_Descriptions);
    allKeys.forEach(function (key) {
        exports.TenantLocales_Descriptions[key] = translationFunction(exports.TenantLocales_Descriptions[key]);
    });
}
exports.TenantLocales_translateDescriptions = TenantLocales_translateDescriptions;
function TenantLocales_translateDescriptionBack() {
    var allKeys = Object.keys(exports.TenantLocales_Descriptions);
    allKeys.forEach(function (key) {
        exports.TenantLocales_Descriptions[key] = exports.TenantLocales_Descriptions_English[key];
    });
}
exports.TenantLocales_translateDescriptionBack = TenantLocales_translateDescriptionBack;
var _TenantLocales_Descriptions = {};
_TenantLocales_Descriptions.England = _TenantLocales_Descriptions[1] = "England";
_TenantLocales_Descriptions.Scotland = _TenantLocales_Descriptions[2] = "Scotland";
_TenantLocales_Descriptions.Wales = _TenantLocales_Descriptions[3] = "Wales";
_TenantLocales_Descriptions.Northen_Ireland = _TenantLocales_Descriptions[4] = "Northern Ireland";
_TenantLocales_Descriptions.USA = _TenantLocales_Descriptions[5] = "United States of America";
_TenantLocales_Descriptions.Saudi = _TenantLocales_Descriptions[6] = "Kingdom of Saudi Arabia";
var _TenantLocales_Descriptions_English = {};
_TenantLocales_Descriptions_English.England = _TenantLocales_Descriptions_English[1] = "England";
_TenantLocales_Descriptions_English.Scotland = _TenantLocales_Descriptions_English[2] = "Scotland";
_TenantLocales_Descriptions_English.Wales = _TenantLocales_Descriptions_English[3] = "Wales";
_TenantLocales_Descriptions_English.Northen_Ireland = _TenantLocales_Descriptions_English[4] = "Northern Ireland";
_TenantLocales_Descriptions_English.USA = _TenantLocales_Descriptions_English[5] = "United States of America";
_TenantLocales_Descriptions_English.Saudi = _TenantLocales_Descriptions_English[6] = "Kingdom of Saudi Arabia";
exports.TenantLocales_Descriptions = _TenantLocales_Descriptions;
exports.TenantLocales_Descriptions_English = _TenantLocales_Descriptions_English;
var _TenantLocales_DisplayOrders = {};
_TenantLocales_DisplayOrders.England = _TenantLocales_DisplayOrders[1] = 1;
_TenantLocales_DisplayOrders.Scotland = _TenantLocales_DisplayOrders[2] = 2;
_TenantLocales_DisplayOrders.Wales = _TenantLocales_DisplayOrders[3] = 3;
_TenantLocales_DisplayOrders.Northen_Ireland = _TenantLocales_DisplayOrders[4] = 4;
_TenantLocales_DisplayOrders.USA = _TenantLocales_DisplayOrders[5] = 5;
_TenantLocales_DisplayOrders.Saudi = _TenantLocales_DisplayOrders[6] = 6;
exports.TenantLocales_DisplayOrders = _TenantLocales_DisplayOrders;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var TenantLocalesExtensions_Factory = /** @class */ (function () {
    function TenantLocalesExtensions_Factory() {
    }
    TenantLocalesExtensions_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {};
        return result;
    };
    TenantLocalesExtensions_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {};
        return result;
    };
    return TenantLocalesExtensions_Factory;
}());
exports.TenantLocalesExtensions_Factory = TenantLocalesExtensions_Factory;
