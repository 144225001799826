import React, { useCallback } from "react";
import { Redirect } from "react-router-dom";
import { Text, Button, Classes, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classnames from "classnames";
import routes from "../../routePaths";
import useAppConfig from "../shared/useAppConfig";
import useAuthService from "../../services/authService";
import { useAppMode, AppModes } from "../../services/appModeService";
import senseiLogo from "../../assets/images/21612 Sensei Logos_Services Enrollment.svg";
import css from "./login.module.scss";


const Login: React.FC<{isPartnerLogin: boolean}> = ({isPartnerLogin}) => {
  const authService = useAuthService();
  const appConfig = useAppConfig();
  const { setAppMode } = useAppMode();

  const doLogin = useCallback(async () => {
    // When degbuggin the app, it always launches to the "/" route.
    // We delay setting the app mode to employee because otherwise the
    // mode would always get set to employee when the app first launches.

    if (!isPartnerLogin) {
      setAppMode(AppModes.Employee);
    }

    await authService.login();


  }, [authService, isPartnerLogin, setAppMode]);



  if (authService.isLoggedIn) {

    const redirectAfterLoginTo = isPartnerLogin ? routes.partnerMode.root.path : routes.home.path;
    return <Redirect to={redirectAfterLoginTo} />
  }

  const message = isPartnerLogin
    ? "Please login using your Carestream Dental Partner Credentials"
    : "Please login using your Carestream Dental credentials";

  return (
    <div className={isPartnerLogin ? css.csdPurpleFlow : css.csdBlueFlow} style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
      <div className="centered" style={{flex: 1, flexDirection: 'column'}}>
        <div><img src={senseiLogo} alt="Sensei Logo" style={{ width: 370, paddingRight: 70 }} /></div>

        <section style={{ paddingTop: '2em', maxWidth: 600, textAlign: 'center' }}>
          <Text>{message}</Text>
        </section>

        <div style={{paddingTop: 40}}>
          <Button intent={Intent.SUCCESS} text="LOGIN" icon={IconNames.LOG_IN} style={{minWidth: 120}} onClick={doLogin} />
        </div>
      </div>

      <div className={classnames(Classes.TEXT_MUTED, Classes.TEXT_SMALL)} style={{position: "absolute", bottom: '1em', left: '1em'}}>
        <Text>{`v${appConfig.buildVersion} (${appConfig.name})`}</Text>
      </div>
    </div>
  );
}


export default Login;
