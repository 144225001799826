import React, { useState, memo, useEffect, useCallback } from "react";
import { ServerDataLoader, IColumnSort, IColumnFilters } from "./models";
import { isFirstPageOfApiResponse } from "../../../models/PagedApiResponse";
import DataTable, { IDataTableProps } from "./index";
import { showToast } from "../../../services/ToasterService";


type CommonProps<TRow> = Pick<IDataTableProps<TRow>, "pageSize" | "columns" | "getRowKey" | "containerStyle">;

interface IProps<TRow> extends CommonProps<TRow> {
  dataLoader: ServerDataLoader<TRow>;
  defaultSort?: IColumnSort<TRow>;
  filters?: IColumnFilters<TRow>;
}


const DataTableContainer = <TRow extends {}>({ pageSize, dataLoader, columns, defaultSort, filters, getRowKey, containerStyle }: IProps<TRow>) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [sorting, setSorting] = useState(defaultSort);

  const [promiseResult, setPromiseResult] = useState<{result?: TRow[], error?: Error | unknown}>({});

  const loadData = useCallback(async (pageNum: number, isPageNav = true) => {
    setPageNum(current => {
      if (pageNum === 1 && current !== pageNum && !isPageNav) {
        showToast("Page reset to page 1");
      }
      return pageNum;
    });

    setPromiseResult({});
    try {
      const result = await dataLoader(pageNum, pageSize, sorting, filters);
      isFirstPageOfApiResponse(result) && setTotalRecords(result.totalRecords!);
      setPromiseResult({result: result.data});
    }
    catch(error) {
      setPromiseResult({error});
      setTotalRecords(0); // Used to hide footer
    }
  }, [dataLoader, pageSize, sorting, filters]);


  useEffect(() => {
    loadData(1, false);
  }, [loadData]);


  return (
    <DataTable<TRow>
      pageSize={pageSize}
      pageNum={pageNum}
      setPageNum={loadData}
      totalRecords={totalRecords}
      sorting={sorting}
      setSorting={setSorting}
      data={promiseResult.result}
      error={promiseResult.error}
      columns={columns}
      getRowKey={getRowKey}
      containerStyle={containerStyle}
    />
  );

}

const memoized = memo(DataTableContainer) as typeof DataTableContainer;
export default memoized;


