import React, { useMemo, useState } from "react"
import { Classes } from "@blueprintjs/core";

interface IProps {
  timestamp?: Date;
  label?: string;
  displayStyle?: React.CSSProperties;
}

export const LastUpdatedTimestamp: React.FC<IProps> = ({timestamp, displayStyle: style, label = "Last updated"}) =>
  timestamp
  ? <div
      className={`${Classes.TEXT_MUTED} ${Classes.TEXT_SMALL}`}
      style={{textAlign: 'right', ...style}}>{label}: {timestamp.toLocaleTimeString()}
    </div>
  : null;

// Utility to track and display a "Last updated" status
const useLastUpdatedTimestamp = (style?: React.CSSProperties, label: string = "Last Updated"): [(date?: Date) => void, React.ReactNode, Date|undefined] => {
  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState<Date | undefined>()

  const lastUpdatedDisplay = useMemo(() => <LastUpdatedTimestamp timestamp={lastUpdatedTimestamp} label={label} displayStyle={style} />, [lastUpdatedTimestamp, label, style]);
  return [setLastUpdatedTimestamp, lastUpdatedDisplay, lastUpdatedTimestamp]
}


export default useLastUpdatedTimestamp;
