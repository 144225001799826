import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { AuthenticationResult, SilentRequest } from "@azure/msal-browser";
import { CallingProcessTypes } from "csd.phoenix.models/CallingProcessTypes";
import { getLoggedInUser } from "../authService";
import { getMsalClient, getAuthScopes } from "../authService/msalClient";
import { AppModes, isAppMode, isAppModeSet } from "../appModeService";
import { getOfficeAuthToken  } from "../authService/officeTokenStorage";


const callingProcessTypeHeaderName = "X-CallingProcessType";
export enum ContentTypes  {
  MultipartFormData = 'multipart/form-data'
}

interface IServerApiInnerError {
  type: string;
  message: string;
  stacktrace: string;
  innerError?: IServerApiInnerError;
}

export interface IServerApiError {
  error: {
    code: string;
    message: string;
    innererror?: IServerApiInnerError;
  }
}

function isServerApiError(error: any): error is IServerApiError {
  return error.error && error.error.code && error.error.message;
}


// This is returned, for example, when the WebApi fails to parse the JSON (ex. invalid Guid)
export interface IServerException {
  Message: string;
  ExceptionType: string;
  ExceptionMessage: string;
}


function isServerException(error: any): error is IServerException {
  return error.Message && error.ExceptionType && error.ExceptionMessage;
}


// function isAxiosError<T = any>(error: any): error is AxiosError<T> {
//   return error && error.isAxiosError && error.config;
// }


export class HttpError extends Error {
  readonly statusCode: number | undefined;

  constructor(message: string, statusCode?: number) {
    super(message);
    this.name = "XHttpError" + (statusCode ? `.${statusCode}` : '');
    this.statusCode = statusCode;
  }
}


// Create custom instance for API reqests
const http = axios.create();

function setRequestBearerToken(config: AxiosRequestConfig, accessToken: string) {
  console.assert(!!accessToken);
  config.headers.Authorization = `Bearer ${accessToken}`;
}


async function requestInterceptor(config: AxiosRequestConfig) {
  if (!config.method) {
    throw new Error("HTTP Rquest invoked witout a method.")
  }

  // Calling process
  if (!config.headers[callingProcessTypeHeaderName]) {
    config.headers[callingProcessTypeHeaderName] = CallingProcessTypes.EsEnrollments;
  }

  if (isAppModeSet() && !config.headers.Authorization)
  {
    if (isAppMode(AppModes.Office))
    {
      const officeToken = getOfficeAuthToken();
      if (officeToken) { // We might be on the office Welcome page
        config.headers.Authorization = `${officeToken.token_type} ${officeToken.token}`;
      }
    }
    else
    {
      let authResponse: AuthenticationResult | undefined = undefined;
      try {
        // App is in Employee or Partner mode.  Use Aure AD Bearer token
        const msalClient = getMsalClient();
        const account = getLoggedInUser()!; // Throws exception if no user is currently logged in
        const requestParams: SilentRequest = {
          account,
          scopes: getAuthScopes()
        }

        authResponse = await msalClient.acquireTokenSilent(requestParams);
        // Note: If this is a token renewal (ie. authResponse.fromCache === false), then
        // the above call only returns an ID token, and we must call it again to get an access token.
        // if (!authResponse.accessToken) {
        //   console.warn("msalClient.acquireTokenSilent didn't return a access token.  Trying again ...")
        //   authResponse = await msalClient.acquireTokenSilent(requestParams);
        // }

        //console.log(authResponse.fromCache, authResponse.accessToken)
        console.assert(!!authResponse.accessToken)
      }
      catch(error) {
        throw new HttpError("Auth token aquisition failure. " + error.message, 401);
      }

      //console.log("Auth token expires on: ", authResponse.expiresOn)
      setRequestBearerToken(config, authResponse!.accessToken);
    }
  }

  return config;
};




function throwHttpError(error: AxiosError<any>, isRetry: boolean): never {
  const statusCode = error.response && error.response.status;
  console.assert(statusCode !== 401);
  const prefix = isRetry ? "[Retry-Phase] " : "";

  // Handle "400 - Bad Data" error.
  if (statusCode === 400) {
    const errorResponse = error.response!.data;

    if (isServerException(errorResponse)) {
      throw new HttpError(prefix + errorResponse.ExceptionMessage, statusCode);
    }

    if (isServerApiError(errorResponse)) {
      throw new HttpError(prefix + errorResponse.error.message, statusCode);
    }

    if (errorResponse.Message) {
      throw new HttpError(prefix + errorResponse.Message, statusCode);
    }

    throw new HttpError(prefix + errorResponse, statusCode);
  }

  throw new HttpError(prefix + error.message, statusCode);
}



// async function handleAuthErrorResponse(error: AxiosError<any>) {

  // const statusCode = error.response && error.response.status;
  // console.assert(statusCode === 401);

  // // Important: calling an API before the user has logged-in causes the app to go into an infinite reload loop.
  // if (!getTokensFromStorage()) {
  //   throw new HttpError("API called before login.")
  // }

  // const tokens = await tryRestoreSession();
  // if (!tokens) {
  //   // No refresh token. Can't continue :(
  //   logout(true);
  //   throw new HttpError("Session renewal failed. Forcing logout.", statusCode);
  // }

  // // Session was restored successfully. Resend the request.
  // const { config: originalRequestConfig } = error;
  // setRequestAccessToken(originalRequestConfig, tokens.accessToken.token);
  // try {
  //   // We don't want the request/response interceptors to run again on this  request.
  //   // So, we'll use the standard/unconfigured Axios instance.
  //   const response = await axios.request(originalRequestConfig);
  //   return response;
  // }
  // catch(ex)  {
  //   console.assert(isAxiosError(ex));
  //   throwHttpError(ex, true);
  // }
// }



function errorResponseInterceptor(error: AxiosError<any>) {
  // NOTE: When a CORS request fails, no response is passed along to Axios, and Axios will return
  // `error.code === undefined`, `error.response === undefined` and `error.message` === "Network Error".
  // Ref: https://github.com/axios/axios/issues/383
  const statusCode = error.response && error.response.status;
  if (!statusCode) {
    throw new HttpError(typeof error.code === 'undefined' ?  "Possible CORS failure." : error.message, statusCode);
  }

  // Is this an authentication error?
  if (statusCode === 401) {
    throw new HttpError("Authentication failure: " + error.message, statusCode);
  }

  throwHttpError(error, false);
};




http.interceptors.request.use(requestInterceptor);
http.interceptors.response.use(undefined, errorResponseInterceptor);
export default http;

