import { loadConfig } from "../services/appConfigService";
import {
  IEsvcsEnrollment,
  EsvcsEnrollment_Factory,
} from "csd.phoenix.models/EsvcsEnrollment";
import { EsvcsEnrollmentStatuses } from "csd.phoenix.models/EsvcsEnrollmentStatuses";
import { IEsvcsEnrollmentPartner } from "csd.phoenix.models/EsvcsEnrollmentPartner";
import { IEsvcsEnrollmentDpmsVersions } from "csd.phoenix.models/EsvcsEnrollmentDpmsVersions"
import { EsvcsProductOfferings } from "csd.phoenix.models/EsvcsProductOfferings";
import routes from "../routePaths";
import { getLocale } from "../i18n";
import { getLoggedInUser } from "../services/authService";
import AsyncLruCache from "../utils/AsyncLruCache";
import { isEmptyGuid } from "../utils/objectUtils";
import { IPagedApiResponse } from "../models/PagedApiResponse";
import ODataApi from "../services/api/odata";
import { ODataQueryParams, IApiQueryOptions } from "../services/api/odata/models";
import { IColumnSort, getODataSortExpression } from "../components/shared/DataTable/models";
import IOfficeAuthToken from "../models/OfficeAuthToken";
import { AppModes, isAppMode } from "../services/appModeService";
import http from "../services/api/http";
import { EsvcsGatewayVersions } from "csd.phoenix.models/EsvcsGatewayVersions";
import { DpmsTypes } from "csd.phoenix.models/DpmsTypes";

const defaultControllerName = "EsvcsEnrollments";

//const getControllerName = () => isAppMode(AppModes.Partner) ? "EsvcsPartners" : defaultControllerName;

//#region Get Partners

async function loadPartners() {
  const { oDataUrl } = await loadConfig();
  var oDataApi = new ODataApi(oDataUrl, defaultControllerName);
  var result = await oDataApi.runCollectionFunction<IEsvcsEnrollmentPartner[]>(
    "GET",
    isAppMode(AppModes.Partner) ? "GetEnrollmentPartners" : "GetPartners"
  );
  return result.value.map((p) => {
    if (p.Name.startsWith("apim:")) {
      p.Name = p.Name.substr(5);
    }
    return p;
  });
}

const thirtyMinutes = 1000 * 60 * 30;
const partnerCache = new AsyncLruCache<"_", IEsvcsEnrollmentPartner[]>({
  maxItems: 1,
  expireAfter: thirtyMinutes,
  promiseFactory: loadPartners,
});

export function getPartners() {
  return partnerCache.get("_");
}

export async function getPartner(partnerId: number) {
  const partners = await getPartners();
  return partners.find((p) => p.Id === partnerId);
}

//#endregion

//#region Get Dpms Versions
async function loadDpmsVersions() {
  const { oDataUrl } = await loadConfig();
  var oDataApi = new ODataApi(oDataUrl, defaultControllerName);
  var result = await oDataApi.runCollectionFunction<[IEsvcsEnrollmentDpmsVersions]>(
    "GET",
    "GetDpmsVersions"
  );

  return result.value;
}

const dpmsVersionsCache = new AsyncLruCache<"_", IEsvcsEnrollmentDpmsVersions[]>({
  maxItems: 1,
  expireAfter: thirtyMinutes,
  promiseFactory: loadDpmsVersions,
});


export function getDpmsVersions() {
  return dpmsVersionsCache.get("_");
}
//#endregion
export interface IAuthClaim {
  Type: string;
  Value: string;
}

export async function getUserAuthClaims() {
  const { oDataUrl } = await loadConfig();
  var oDataApi = new ODataApi(oDataUrl, defaultControllerName);
  var result = await oDataApi.runCollectionFunction<IAuthClaim[]>(
    "GET",
    "GetAuthClaims"
  );
  return result.value;
}

export async function getInstances(
  statusCodes: EsvcsEnrollmentStatuses[] = [],
  pageNum: number,
  pageSize: number,
  sorting?: IColumnSort<IEsvcsEnrollment>,
  customerId?: string,
  sapNameEntry?: string,
  submittedByEntry?: string,
  productEntryId?: string,
  partnerEntryId?: string,
  dmpsEntryIds?: string[]
) {
  const { oDataUrl } = await loadConfig();
  const api = new ODataApi(oDataUrl, defaultControllerName);

  let filterStr = "(RecordStatus eq 0)";
  //let filterStr = "";
  if (statusCodes.length > 0) {
    const statusFilter =
      "(" +
      statusCodes.map((sc) => `EnrollmentStatus eq ${sc}`).join(" or ") +
      ")";
    filterStr += ` and ${statusFilter}`;
  }
  if (customerId) {
    filterStr += ` and CustomerId eq ${customerId}`;
  }

  if (partnerEntryId) {
    filterStr += ` and PartnerId eq ${partnerEntryId}`;
  }

  if (productEntryId) {
    filterStr += ` and ProductOffering eq ${productEntryId}`;
  }
  if (sapNameEntry) {
    filterStr += ` and ((indexof(CustomerId, '${sapNameEntry}') gt -1) ` +
      `or (indexof(CustomerName, '${sapNameEntry}') gt - 1))`;
  }

  if (submittedByEntry) {
    filterStr += ` and (indexof(SubmittedBy, '${submittedByEntry}') gt -1)`;
  }

  if (dmpsEntryIds!.length > 0) {
    const dpmsFilter =
      "(" +
      dmpsEntryIds!.map((d) => `DpmsType eq ${d}`).join(" or ") +
      ")";
    filterStr += ` and ${dpmsFilter}`;
  }

  const query: ODataQueryParams = {
    filter: filterStr,
    orderBy: getODataSortExpression(sorting),
  };

  const disablePaging = pageSize < 1;
  const paging: IApiQueryOptions | undefined = disablePaging
    ? undefined
    : {
      includeCount: pageNum === 1,
      pageNum: pageNum - 1,
      pageSize,
    };

  const result = await api.runSetQuery<IEsvcsEnrollment>(
    { method: "GET" },
    query,
    paging,
    isAppMode(AppModes.Partner) ? "GetEnrollementsForPartner" : undefined
  );

  const instances = result.value.map(
    (i) => EsvcsEnrollment_Factory.CreateIncoming(i)!
  );
  const response: IPagedApiResponse<IEsvcsEnrollment> = {
    totalRecords: result.count,
    currentPage: pageNum,
    pageSize: pageSize,
    data: instances,
  };

  return response;
}

function getCurrentUserInfo() {
  const account = getLoggedInUser();
  return {
    email: account?.username,
    name: account?.name,
  };
}

export async function createNewEnrollment(enrollment: IEsvcsEnrollment) {
  console.assert(!!enrollment);
  enrollment.RecordId = undefined;
  enrollment.EnrollmentStatus = EsvcsEnrollmentStatuses.New;
  enrollment.GatewayVersion = (enrollment.DpmsType === DpmsTypes.CMP)
    ? EsvcsGatewayVersions.None : EsvcsGatewayVersions.V2;
  const accountInfo = getCurrentUserInfo();
  enrollment.SubmittedBy = `${accountInfo.name} (${accountInfo.email})`;
  enrollment = EsvcsEnrollment_Factory.CreateOutgoing(enrollment)!;

  const { oDataUrl } = await loadConfig();
  const oDataApi = new ODataApi(oDataUrl, defaultControllerName);

  const data = {
    activationPath:
      routes.officeMode.welcome.path.replace(":id", "{id}"),
    enrollment,
  };

  var result = await oDataApi.runCollectionAction<IEsvcsEnrollment>(
    "Create",
    data
  );
  //console.log("response", result)
  return EsvcsEnrollment_Factory.CreateIncoming(result);
}

export async function updateEnrollment(enrollment: IEsvcsEnrollment) {
  console.assert(!!enrollment);
  console.assert(!!enrollment.RecordId && !isEmptyGuid(enrollment.RecordId));
  enrollment = EsvcsEnrollment_Factory.CreateOutgoing(enrollment)!;

  const { oDataUrl } = await loadConfig();
  const oDataApi = new ODataApi(oDataUrl, defaultControllerName);

  var result = await oDataApi.runEntityQuery<IEsvcsEnrollment>(
    enrollment.RecordId!,
    { method: "PATCH", data: enrollment }
  );
  return EsvcsEnrollment_Factory.CreateIncoming(result);
}

export interface IMarkAsViewedActionResponse {
  RecordId: string;
  CustomerName: string;
  ProductOffering: EsvcsProductOfferings;
  PartnerId?: number;
  PartnerName?: string;
  PartnerDisplayName?: string;
}

// Note this function doesn't require authentication
export async function markEnrollmentAsViewed(recordId: string) {
  console.assert(!!recordId && !isEmptyGuid(recordId));
  const { apiUrl } = await loadConfig();
  const url = apiUrl
    .segment(defaultControllerName+'/MarkAsViewed/'+recordId)
    .toString();

  let { data } = await http.post<IMarkAsViewedActionResponse>(url);

  return data;
  // const result = await oDataApi.runEntityAction<IMarkAsViewedActionResponse>(
  //   recordId,
  //   "MarkAsViewed"
  // );
  // //console.log("Mark as viewed: ", result)
  // return result;
}

export async function getOfficeAuthToken(
  enrollmentId: string,
  sapId: string,
  postCode: string,
  locale = getLocale()
) {
  console.assert(enrollmentId && sapId && postCode && locale);
  const { apiUrl } = await loadConfig();
  //const oDataApi = new ODataApi(oDataUrl, getControllerName());
  const url = apiUrl
    .segment(defaultControllerName+'/getofficeauthtoken')
    .toString();

  const postData = {
    enrollmentId: enrollmentId,
    sapId: sapId,
    postCode: postCode,
    locale: locale
  };

  let { data } = await http.post<IOfficeAuthToken>(url, postData);
  return data;
}

//Get enrollment for the logged in user
export interface IEnrollmentConfirmation {
  firstName: string;
  lastName: string;
  email: string;
  terms: string | undefined;
  documentId: string;
  acceptedTerms: boolean;
  enrollmentStatus: EsvcsEnrollmentStatuses;
  productOfferingDescription: string,
  productOffering?: Number,
  onlineHelpUrl?: string;
}

export async function getOfficeEnrollmentData() {
  const { oDataUrl } = await loadConfig();
  var oDataApi = new ODataApi(oDataUrl, defaultControllerName);
  var result = await oDataApi.runCollectionFunction<
    IEnrollmentConfirmation,
    IEnrollmentConfirmation
  >("GET", "GetOfficeEnrollmentData");

  return result;
}

//No need to pass in the office Id as there is a token required that contains that information - pass in the document Id
//as this signifies which document text they would have read
export async function markEnrollmentAcceptedTerms(
  documentId: string,
  firstName: string,
  lastName: string,
  email: string
) {
  const { oDataUrl } = await loadConfig();
  const oDataApi = new ODataApi(oDataUrl, defaultControllerName);

  const result = await oDataApi.runCollectionAction<
    IMarkAsViewedActionResponse
  >("MarkAcceptedTermsAndConditions", {
    documentId,
    email,
    firstName,
    lastName,
  });
  return result;
}

export async function resendEmail(
  enrollmentId: string,
  adminUserEmail: string
) {
  const { oDataUrl } = await loadConfig();
  // const oDataApi = new ODataApi(oDataUrl, getControllerName());
  const oDataApi = new ODataApi(oDataUrl, defaultControllerName);
  const activationPath = routes.officeMode.welcome.path.replace(":id", "{id}");


  const result = await oDataApi.runCollectionAction<
    IEsvcsEnrollment
  >("ResendEmail", {
    enrollmentId,
    adminUserEmail,
    activationPath
  });
  return result;
}

export async function retryProvisioning(
  enrollmentId: string
) {
  const { oDataUrl } = await loadConfig();
  const oDataApi = new ODataApi(oDataUrl, defaultControllerName);

  const result = await oDataApi.runCollectionAction<
    IEsvcsEnrollment
  >("RetryProvisioning", {
    enrollmentId
  });
  return result;
}
