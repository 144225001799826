import React, { memo, useCallback } from "react";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classnames from "classnames";
import { IColumn, SortDirection, getContentTextAlignment } from "../models";
import css from "../dataTable.module.scss";


interface IProps<TRow, TCol> {
  column: IColumn<TRow, TCol>;
  isDisabled: boolean;
  sortDirection: SortDirection;
  onSortChanged: (column: IColumn<TRow, TCol>, sortDirection: SortDirection) => void;
}


const SortIcon: React.FC<{sortDirection: SortDirection}> = ({sortDirection}) => {
  const icon = sortDirection === SortDirection.None
    ? undefined
    : sortDirection === SortDirection.Asc
      ? IconNames.SORT_ASC
      : IconNames.SORT_DESC;

  return <Icon icon={icon || IconNames.DOT} style={{color: icon ? undefined : 'transparent'}} />;
}

const ColumnHeader = <TRow extends {}, TCol extends unknown>({column, isDisabled, sortDirection = SortDirection.None, onSortChanged}: IProps<TRow, TCol>) => {
  const { defaultSortDirection = SortDirection.Asc } = column;

  const canSort = !isDisabled && column.disableSort !== true;
  const headerClicked = useCallback(() => {
    if (canSort) {
      const newSortDrection =
        sortDirection === SortDirection.None
          ? defaultSortDirection
          : sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
      onSortChanged(column, newSortDrection);
    }
  }, [canSort, column, defaultSortDirection, onSortChanged, sortDirection]);

  const colStyles = column.styles || {};
  const sortCssClass = `sort${SortDirection[sortDirection]}`;

  return (
    <th className={canSort ? classnames(css.sorted, css[sortCssClass]) : undefined} onClick={headerClicked}>
        <div className={css.header} >
          <div style={{flex: 1, textAlign: getContentTextAlignment(colStyles.alignment)}}>
            {column.header}
          </div>
          <div >
            <SortIcon sortDirection={canSort ? sortDirection : SortDirection.None} />
          </div>
      </div>
    </th>
  );
};


const memoized = memo(ColumnHeader) as typeof ColumnHeader;
export default memoized;
