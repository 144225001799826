import React, { memo, useMemo } from "react";
import DirtyFormWarning from "../DirtyFormWarning";


export interface IHeaderProps {
  warnIfDirty: boolean;
  isDirty: boolean
  containerStyle?: React.CSSProperties;
}


const Header: React.FC<IHeaderProps> = memo(({children, warnIfDirty, isDirty, containerStyle }) => {
  const showDirtyWarning = warnIfDirty && isDirty;

  const _containerStyle: React.CSSProperties = useMemo(() => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    margin: '0 1em',
    ...containerStyle
  }),[containerStyle])

  return (
    <div style={_containerStyle}>
      <div style={{flex: 1}}>{children}</div>
      <DirtyFormWarning isDirty={showDirtyWarning} />
    </div>);
});


export default Header;
