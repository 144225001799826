import React, { memo } from "react";
import { IColumn, IColumnSort, FieldNames } from "./models";
import css from "./dataTable.module.scss";
import TableHeader from "./TableHeader";
import TableFooter from "./TableFooter";
import Loading from "./Loading";
import Error from "./Error";
import Rows from "./Rows";
import classnames from "classnames";


export interface IDataTableProps<TRow> {
  totalRecords: number;
  pageSize: number;
  pageNum: number;
  setPageNum: (newPageNum: number) => void;
  columns: IColumn<TRow, any>[];
  sorting?: IColumnSort<TRow>;
  setSorting: (newSorting: IColumnSort<TRow>) => void;
  getRowKey: FieldNames<TRow> | ((row: TRow, index: number) => string);
  data: TRow[] | undefined;
  error?: Error | unknown;
  containerStyle?: React.CSSProperties;
}


const Table = <TRow extends {}>(props: IDataTableProps<TRow>) => {
  const isLoading = !props.error && !props.data;
  const tableState = isLoading ? 'loading' : props.data ? 'loaded' : 'error' ;
  const colCount = props.columns.length;
  const { containerStyle } = props;

  return (
    <div>
      <div style={containerStyle}
      className={classnames(containerStyle?.maxHeight && 'scrollable')}
      >
        <table className={classnames(css.dataTable)} data-state={tableState}>
          <TableHeader<TRow>
            columns={props.columns}
            isDisabled={isLoading || props.totalRecords < 2}
            sorting={props.sorting}
            setSorting={props.setSorting}
          />
          <tbody>
            <Loading isLoading={isLoading} colSpan={colCount} />
            <Error error={props.error} colSpan={colCount} />
            {props.data && <Rows columns={props.columns} data={props.data} getRowKey={props.getRowKey} />}
          </tbody>
        </table>
      </div>
      <TableFooter
          colSpan={colCount}
          pageNum={props.pageNum}
          pageSize={props.pageSize}
          totalRecords={props.totalRecords}
          setPageNum={props.setPageNum}
        />
    </div>
  );

}

const memoized = memo(Table) as typeof Table;
export default memoized;


