import { useState, useEffect } from "react";

type PromiseState<T> = {
  isBusy: boolean;
  result?: T;
  error?: Error;
}

// Keeps track of promsie state
export default function usePromiseState<T>(promise: Promise<T> | undefined): [boolean, T | undefined, Error | undefined] {
  const [state, setState] = useState<PromiseState<T>>({isBusy: !!promise});

  useEffect(() => {
    if (promise) {
      let isActivePromise = true;
      setState({isBusy: true});

      promise.then(
        result => isActivePromise ? setState({isBusy: false, result}) : console.warn(`Ignoring stale promise completion.`, result),
        error => isActivePromise ? setState({isBusy: false, error}) : console.warn(`Ignoring stale promise error.`, error)
      );

      return () => { isActivePromise = false };
    }
  }, [promise]);

  return [state.isBusy, state.result, state.error];
}

