import React, { memo, useMemo } from "react";
import Pager from "./Pager";
import css from "../dataTable.module.scss";


interface IProps {
  pageSize: number;
  pageNum: number;
  totalRecords: number;
  colSpan: number;
  setPageNum: (pageNum: number) => void;
}


function FooterContent(pageSize: number, pageNum: number, totalRecords: number, setPageNum: (pageNum: number) => void) {
  const totalPages = useMemo(() => Math.ceil(totalRecords / pageSize), [pageSize, totalRecords]);
  const start = 1 + ((pageNum - 1) * pageSize);
  const end = Math.min(totalRecords, start + pageSize - 1);
  return (
    <div className={css.footer}>
      <div style={{ flex: 1 }}>
        {totalPages === 1 && <div>Total records: {totalRecords}</div>}
        {(totalPages > 1) && <div>{start} to {end} of {totalRecords}</div>}
      </div>
      <Pager totalRecords={totalRecords} pageSize={pageSize} pageNum={pageNum} setPageNum={setPageNum} />
    </div>

  );
};

const Footer: React.FC<IProps> = memo(({ pageSize, pageNum, setPageNum, totalRecords }) => {
  if (!totalRecords)
    return null;
  else
    return FooterContent(pageSize, pageNum, totalRecords, setPageNum);
});


export default Footer;
