import React, { useState, useCallback, useMemo, useContext } from "react";
export enum AppModes {
  NotSet,
  Employee,
  Office,
  Partner
}


const appModeKey = "_appMode_";

const getAppMode = () => {
  const value = sessionStorage.getItem(appModeKey) || '';

  let appMode= Object.values(AppModes).filter(k => typeof k === "string").indexOf(value);
  if (appMode === -1)
   appMode = AppModes.NotSet;
  return appMode;
}


export class AppModeTransitionError extends Error {
  constructor(currentMode: AppModes, newMode: AppModes) {
    super(`Can't change app mode to '${AppModes[newMode]}'. (App mode already set to '${AppModes[currentMode]}')`)
  }
}

// Note: State modification must be reflected in context so that components can update accordingly.
// As such, this method is not exported.  External callers shoudl use IAppModeContext.setAppMode() instead.
const setSessionAppMode = (appMode: AppModes)  => {
  // Once set, app mode can't be changed .
  const currentMode = getAppMode();
  if (appMode === currentMode) {
    return;
  }

  if (currentMode !== AppModes.NotSet) {
    throw new AppModeTransitionError(currentMode, appMode);
  }

  console.info("Settings app mode to: " + AppModes[appMode])
  sessionStorage.setItem(appModeKey, AppModes[appMode]);
}


export const isAppModeSet = () => getAppMode() !== AppModes.NotSet;
export const isAppMode = (appMode: AppModes) => getAppMode() === appMode;


export interface IAppModeContext {
  readonly appMode: AppModes;
  setAppMode: (appMode: AppModes) => void;

  // Expose some module level functions in context for convenience
  isAppMode: (appMode: AppModes) => boolean;
  isAppModeSet: () => boolean;

}

const context = React.createContext<IAppModeContext|undefined>(undefined);


export const AppModeProvider: React.FC = ({children}) => {
  const [appMode, _setAppModeState] = useState(getAppMode());

  const setAppMode = useCallback((appMode: AppModes) => {
    setSessionAppMode(appMode);
    _setAppModeState(appMode);
  }, []);


  const value = useMemo(() => ({
    appMode,
    setAppMode,
    isAppMode,
    isAppModeSet
  }), [appMode, setAppMode]);

  return (
    <context.Provider value={value}>
      {children}
    </context.Provider>)
}


export function useAppMode() {
  const ctx = useContext(context);
  if (!ctx) {
    throw new Error("App Mode Context not found!!");
  }
  return ctx;
}


