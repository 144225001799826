import React from "react";
import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { IClientLogEntry } from "../../../../models/LogEntry";
import { getClientLogEntries } from "../../../../stores/logEntriesStore";
import CustomerLogViewerPanel, { TClientLogColDef, TLogColDef } from "./CustomerLogViewerPanel";
import { FnOpenJsonDialog } from "./JsonDisplayDialog";
import { ILogPanelProps } from "./LogPanelProps";



const getClientLogColumns = (openJsonDlg: FnOpenJsonDialog): TLogColDef[] => {
  const columns: TClientLogColDef[] = [
    {
      header: "Created On",
      name: "createdOn"
    },

    {
      header: "Client Log ID",
      name: "id",
      disableSort: true     // ID counter is reset at midnight each day.  Not useful for sorting.
    },

    {
      header: "Message",
      name: "message"      // Allowing this to sort; maybe you want to know how many of a certain kind of msg?
    },

    {
      header: "Log Level",
      name: "logLevel"
    },

    {
      header: "Agent Version",
      name: "agentVersion"
    },

    {
      header: "",
      name: "_actions_",
      disableSort: true,
      accessor: (data: IClientLogEntry) => data,
      renderer: (data: IClientLogEntry) => {
        return (
          <Button onClick={() => openJsonDlg(data)}
            style={{ fontSize: "smaller" }}
            rightIcon={IconNames.EYE_OPEN}
            title="View Log Details"
            small={true}
            minimal={true}>
          </Button>
        );
      }
    }
  ];

  return columns as TLogColDef[];
};


const ClientLogsPanel: React.FC<ILogPanelProps> = (props) => {
  return (
      <CustomerLogViewerPanel
        {...props}
        colGetter={getClientLogColumns}
        apiFunc={getClientLogEntries}
      />
  );
}

export default ClientLogsPanel;
