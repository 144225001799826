import React from "react";
import { Icon } from "@blueprintjs/core";
import { IconNames, IconSize } from "@blueprintjs/icons";
import css from "../form.module.scss";


interface IProps {
  isChecked: boolean;
  label: string;
  className?: string;
}

const CheckIcon: React.FC<IProps> = ({isChecked, label, className}) => {
  return (
    <div className={className} >
      <div style={{display: 'inline-block', borderRadius: 1, width: 14, height: 14, border: `solid 1px ${isChecked ? '#ccc' : '#aaa'}` }}></div>
      {isChecked &&
        // <div style={{position: 'absolute', width: '100%'}}>
        //   <Icon icon={IconNames.TICK} className={css.fieldValue} iconSize={Icon.SIZE_LARGE} style={{position: 'relative', top: -Icon.SIZE_LARGE-1}} />
        // </div>
        <div style={{display: 'inline-block', position: 'relative', left: -14, top: -18}}>
        <Icon icon={IconNames.TICK} className={css.fieldValue} size={IconSize.LARGE} style={{position: 'absolute'}} />
      </div>
      }
      <span style={{paddingLeft: '1em'}}>{label}</span>
    </div>
  );
}

export default CheckIcon;
