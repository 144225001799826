import React, { useCallback, useState, memo } from "react";
import "@blueprintjs/core/lib/css/blueprint.css"
import "normalize.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import { Select, ItemRenderer, ItemPredicate } from "@blueprintjs/select";
import { Button, MenuItem } from "@blueprintjs/core";
import { IFieldOption } from "./form/models";
import { IconNames } from "@blueprintjs/icons";


// const items: IFieldOption[] = [
//   {
//     "value": "1111",
//     "text": "location 1",
//   },
//   {
//     "value": "2222",
//     "text": "location 2",
//   },
//   {
//     "value": "3333",
//     "text": "location 3",
//   }
// ];


const SelectItem = Select.ofType<IFieldOption>();

const selectedItemStyle: React.CSSProperties = { width: '100%', justifyContent: 'space-between' };

interface IProps {
  items: IFieldOption[];
  onChange: (value: string) => void;
  isDisabled?: boolean;
  selectedItemValue?: string;
}

const Selection: React.FC<IProps> = memo(({ items, isDisabled = false, onChange, selectedItemValue }) => {
  const [activeItem, setActiveItem]
    = useState<IFieldOption | null>(
      items[!!selectedItemValue
        ? items.findIndex(i => i.value === Number(selectedItemValue))
        : 0]
    );


  const itemPredicate: ItemPredicate<IFieldOption> = useCallback((query, item, _, exact) => {
    const _query = query.toLocaleLowerCase();
    const itemVal = item.text!.toLowerCase();

    return exact === true
      ? _query === itemVal
      : itemVal.indexOf(_query) >= 0;
  }, []);

  const itemRenderer: ItemRenderer<IFieldOption> = useCallback((item, { handleClick, modifiers: { disabled, matchesPredicate, active } }) => {
    return matchesPredicate
      ? <MenuItem
        key={(item.value || "").toString()}
        text={item.text}
        disabled={disabled}
        active={active}
        onClick={handleClick}
      />
      : null;
  }, []);


  const onItemSelect = useCallback((item: IFieldOption) => {
    //console.log("onItemSelect")
    onChange((item.value || "").toString());
  }, [onChange]);

  return (
    <SelectItem
      disabled={isDisabled}
      items={items}
      activeItem={activeItem}
      onActiveItemChange={setActiveItem}
      itemPredicate={itemPredicate}
      onItemSelect={onItemSelect}
      itemRenderer={itemRenderer}
      popoverProps={{ minimal: true }}
      filterable={false}
    >
      <Button
        text={activeItem?.text}
        rightIcon={IconNames.CHEVRON_DOWN}
        disabled={isDisabled}
        style={selectedItemStyle}
      />
    </SelectItem>
  );
})


export default Selection;
