
import React, { useCallback, useState } from "react";
import { IconNames } from "@blueprintjs/icons";
import { Button, Intent } from "@blueprintjs/core";
import { ISyncAgentCounts } from "../../../models/SyncAgentCounts";
import {  getSyncAgentCounts } from "../../../stores/syncAgentsStore"
import PromiseWait from "../../shared/PromiseWait";
import usePromiseState from "../../shared/usePromiseState";
import useLastUpdatedTimestamp from "../../shared/useLastUpdatedTimestamp";


const Content: React.FC<ISyncAgentCounts> = ({ active, inactive, hosted, onPrem, versions }) => {
  return (
    <div style={{ display: "flex" }}>

      <div style={{marginRight: 20}}>
          <div>Categories:</div>
          <table className="staticTable">
            <thead>
              <tr>
                <th>Active</th>
                <th>Inactive</th>
                <th>On-Prem</th>
                <th>Hosted</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{active}</td>
                <td>{inactive}</td>
                <td>{onPrem}</td>
                <td>{hosted}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <div>Versions:</div>
          <table className="staticTable" style={{overflowX: "auto"}}>
            <thead>
              <tr>
                {versions.map(([version]) => <th key={version}>{version}</th>)}
              </tr>
            </thead>
            <tbody>
              <tr>
                {versions.map(([version, count]) => <td key={version}>{count}</td>)}
                </tr>
            </tbody>
          </table>
        </div>
    </div>
  )
};


const loadData = (setLastUpdatedTimestamp: (date?: Date) => void) =>
  getSyncAgentCounts().then(results => { setLastUpdatedTimestamp(new Date()); return results;})

const AgentInfoPanel:React.FC = () => {
  const [setLastUpdatedTimestamp, lastUpdatedDisplay] = useLastUpdatedTimestamp({textAlign: "left", margin: "10px 0 30px 0"});
  const [dataLoader, setDataLoader] = useState<Promise<ISyncAgentCounts> | undefined>();
  const [isBusy] = usePromiseState(dataLoader);
  const handleQueryButtonClick = useCallback(() => setDataLoader(loadData(setLastUpdatedTimestamp)), [setLastUpdatedTimestamp]);

  return (
    <div>
      <Button
        icon={IconNames.SEARCH}
        intent={Intent.PRIMARY}
        style={{margin: "20px 0"}}
        disabled={isBusy}
        onClick={handleQueryButtonClick}
      >Query Agent Info
      </Button>

      <PromiseWait
        promise={dataLoader}
        component={Content}
        mapComponentProps={PromiseWait.mapAsIs}
        useModalWait={false}
        waitMessage="Loading"
        waitProps={{showBorder:false, style: { maxWidth: 120 } }}
       />

      {lastUpdatedDisplay}
    </div>
  )
};


export default AgentInfoPanel;
