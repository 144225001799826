import React from "react";
import ErrorCallout from "../shared/ErrorCallout";


export interface IProps {
  title?: string;
  description?: string;
}

const NotFound = ({title = "Not Found", description = "The page you tried to access doesn't exist!"} : IProps) =>
  <ErrorCallout title={title} description={description} />

export default NotFound;
