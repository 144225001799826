import React from "react";
import { Route, Switch } from "react-router";
import routes from "../../../routePaths";
import CollapsiblePanel, { useCollapsiblePanelStates } from "../../shared/CollapsiblePanel";
import Page from "../../shared/Page";
import CustomerPage from "./CustomerPage";
import CustomerPanel from "./CustomerPanel";
import SyncAgentsInfoPanel from "./SyncAgentInfoPanel";
import SyncAgentResetPanel from "./SyncAgentResetPanel";
import SyncAgentUpdatesPanel from "./SyncAgentUpdatesPanel";


export const SupportPageRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={routes.support.customers.path} exact={true} component={CustomerPage} />
      <Route component={SupportPage} />
    </Switch>
  );
}

interface IProps {
}

const SupportPage: React.FC<IProps> = () => {
  const [isPanelOpen, togglePanelOpenState] = useCollapsiblePanelStates(["customers", "agentInfo", "agentReset", "agentUpdate"], "uiState.customerList.openPanels");

  return (
    <Page header="Gateway Support" >
      <CollapsiblePanel
        title="Customers"
        contentPlaceHolder="Search/View customer information"
        isOpen={isPanelOpen("customers")}
        toggleIsOpen={() => togglePanelOpenState("customers")}
      >
        <CustomerPanel />
      </CollapsiblePanel>

      <CollapsiblePanel
        title="Sync Agent Info"
        contentPlaceHolder="View information about currently deployed Sync Agents"
        isOpen={isPanelOpen("agentInfo")}
        toggleIsOpen={() => togglePanelOpenState("agentInfo")}
      >
        <SyncAgentsInfoPanel />
      </CollapsiblePanel>

      <CollapsiblePanel
        title="Reset Sync Agent Installation"
        contentPlaceHolder="Reset the max usage limit to allow a Sync Agent to be (re)installed"
        isOpen={isPanelOpen("agentReset")}
        toggleIsOpen={() => togglePanelOpenState("agentReset")}
      >
        <SyncAgentResetPanel />
      </CollapsiblePanel >

      <CollapsiblePanel
        title="Sync Agent Updates"
        contentPlaceHolder="Send out Sync Agent updates"
        isOpen={isPanelOpen("agentUpdate")}
        toggleIsOpen={() => togglePanelOpenState("agentUpdate")}
      >
        <SyncAgentUpdatesPanel />
      </CollapsiblePanel>
    </Page>
  );
}

export default SupportPage;
