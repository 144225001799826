import React from "react";
import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Tooltip2 } from "@blueprintjs/popover2";
import { IServiceLogEntry } from "../../../../models/LogEntry";
import { getServiceLogEntries } from "../../../../stores/logEntriesStore";
import { IColumn } from "../../../shared/DataTable/models";
import CustomerLogViewerPanel, { TLogColDef, TServiceLogColDef } from "./CustomerLogViewerPanel";
import { FnOpenJsonDialog } from "./JsonDisplayDialog";
import { ILogPanelProps } from "./LogPanelProps";


const elide = (value: string, maxWidth: number = 255) => {
  if (!value)
    return ("");
  else if (value.length < maxWidth)
    return (value);
  else
    return (value.substring(0, maxWidth - 3) + "...");
};


const getServiceLogColumns = (openJsonDlg: FnOpenJsonDialog): TLogColDef[] => {
  const columns: TServiceLogColDef[] = [
    {
      header: "Created On",
      name: "createdOn"
    },

    {
      header: "Service Log ID",
      name: "id",
      disableSort: true     // ID counter is reset at midnight each day.  Not useful for sorting.
    },

    {
      header: "Message",
      name: "message",      // Allowing this to sort; maybe you want to know how many of a certain kind of msg?

      //========================================================================
      // Workaround for design limitation of DataTableContainer.
      //========================================================================
      // The message property of the service logs can contain a HUMONGOUS
      // amount of text, which overwhelms the table implementation so that it
      // no longer obeys the 100% width style rule.  A very long string really
      // isn't useful in the table (you can see it in the full JSON) thus we
      // elide really long ones so the user can still get the gist of the entry.
      // We haven't used the blueprint.js "Text" component because we haven't
      // had the time available to figure out how to get the same nice reflowing
      // behavior that we get with the code below. TODO: figure it out!
      renderer: (value: string, row: IServiceLogEntry, col: IColumn<IServiceLogEntry, string>) => {
        const tooltip = !!row.exception
          ? <div>{elide(row.exception!.description[0], 180)}</div>
          : <div>No exception description available</div>;
        return (
          <Tooltip2 content={tooltip}>
            <div>{elide(value)}</div>
          </Tooltip2>
        );
      }
    },

    {
      header: "Log Level",
      name: "logLevel"
    },

    {
      header: "Component",
      name: "component"
    },

    {
      header: "",
      name: "_actions_",
      disableSort: true,
      accessor: (data: IServiceLogEntry) => data,
      renderer: (data: IServiceLogEntry) => {
        return (
          <Button onClick={() => openJsonDlg(data)}
            style={{ fontSize: "smaller" }}
            rightIcon={IconNames.EYE_OPEN}
            title="View Log Details"
            small={true}
            minimal={true}>
          </Button>
        );
      }
    }
  ];

  return columns as TLogColDef[];
};


const ServiceLogsPanel: React.FC<ILogPanelProps> = (props) => {
  return (
      <CustomerLogViewerPanel
        {...props}
        colGetter={getServiceLogColumns}
        apiFunc={getServiceLogEntries}
      />
  );
}

export default ServiceLogsPanel;
