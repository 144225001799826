"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CallingProcessTypes_DisplayOrders = exports.CallingProcessTypes_Descriptions_English = exports.CallingProcessTypes_Descriptions = exports.CallingProcessTypes_translateDescriptionBack = exports.CallingProcessTypes_translateDescriptions = exports.CallingProcessTypes = void 0;
var CallingProcessTypes;
(function (CallingProcessTypes) {
    CallingProcessTypes[CallingProcessTypes["CMP"] = 0] = "CMP";
    CallingProcessTypes[CallingProcessTypes["EForms"] = 1] = "EForms";
    CallingProcessTypes[CallingProcessTypes["Migrations"] = 2] = "Migrations";
    CallingProcessTypes[CallingProcessTypes["CMPMobile"] = 3] = "CMPMobile";
    CallingProcessTypes[CallingProcessTypes["CMPWebView"] = 4] = "CMPWebView";
    CallingProcessTypes[CallingProcessTypes["EsEnrollments"] = 5] = "EsEnrollments";
    CallingProcessTypes[CallingProcessTypes["Partners"] = 6] = "Partners";
    CallingProcessTypes[CallingProcessTypes["SenseiGateway"] = 7] = "SenseiGateway";
    CallingProcessTypes[CallingProcessTypes["ImagingBridge"] = 8] = "ImagingBridge";
    CallingProcessTypes[CallingProcessTypes["EnterpriseBackupManager"] = 9] = "EnterpriseBackupManager";
    CallingProcessTypes[CallingProcessTypes["Boost"] = 20] = "Boost";
})(CallingProcessTypes = exports.CallingProcessTypes || (exports.CallingProcessTypes = {}));
function CallingProcessTypes_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.CallingProcessTypes_Descriptions);
    allKeys.forEach(function (key) {
        exports.CallingProcessTypes_Descriptions[key] = translationFunction(exports.CallingProcessTypes_Descriptions[key]);
    });
}
exports.CallingProcessTypes_translateDescriptions = CallingProcessTypes_translateDescriptions;
function CallingProcessTypes_translateDescriptionBack() {
    var allKeys = Object.keys(exports.CallingProcessTypes_Descriptions);
    allKeys.forEach(function (key) {
        exports.CallingProcessTypes_Descriptions[key] = exports.CallingProcessTypes_Descriptions_English[key];
    });
}
exports.CallingProcessTypes_translateDescriptionBack = CallingProcessTypes_translateDescriptionBack;
var _CallingProcessTypes_Descriptions = {};
_CallingProcessTypes_Descriptions.CMP = _CallingProcessTypes_Descriptions[0] = "CMP";
_CallingProcessTypes_Descriptions.EForms = _CallingProcessTypes_Descriptions[1] = "EForms";
_CallingProcessTypes_Descriptions.Migrations = _CallingProcessTypes_Descriptions[2] = "Migrations";
_CallingProcessTypes_Descriptions.CMPMobile = _CallingProcessTypes_Descriptions[3] = "CMPMobile";
_CallingProcessTypes_Descriptions.CMPWebView = _CallingProcessTypes_Descriptions[4] = "CMPWebView";
_CallingProcessTypes_Descriptions.EsEnrollments = _CallingProcessTypes_Descriptions[5] = "EsEnrollments";
_CallingProcessTypes_Descriptions.Partners = _CallingProcessTypes_Descriptions[6] = "Partners";
_CallingProcessTypes_Descriptions.SenseiGateway = _CallingProcessTypes_Descriptions[7] = "SenseiGateway";
_CallingProcessTypes_Descriptions.ImagingBridge = _CallingProcessTypes_Descriptions[8] = "ImagingBridge";
_CallingProcessTypes_Descriptions.EnterpriseBackupManager = _CallingProcessTypes_Descriptions[9] = "EnterpriseBackupManager";
_CallingProcessTypes_Descriptions.Boost = _CallingProcessTypes_Descriptions[20] = "Boost";
var _CallingProcessTypes_Descriptions_English = {};
_CallingProcessTypes_Descriptions_English.CMP = _CallingProcessTypes_Descriptions_English[0] = "CMP";
_CallingProcessTypes_Descriptions_English.EForms = _CallingProcessTypes_Descriptions_English[1] = "EForms";
_CallingProcessTypes_Descriptions_English.Migrations = _CallingProcessTypes_Descriptions_English[2] = "Migrations";
_CallingProcessTypes_Descriptions_English.CMPMobile = _CallingProcessTypes_Descriptions_English[3] = "CMPMobile";
_CallingProcessTypes_Descriptions_English.CMPWebView = _CallingProcessTypes_Descriptions_English[4] = "CMPWebView";
_CallingProcessTypes_Descriptions_English.EsEnrollments = _CallingProcessTypes_Descriptions_English[5] = "EsEnrollments";
_CallingProcessTypes_Descriptions_English.Partners = _CallingProcessTypes_Descriptions_English[6] = "Partners";
_CallingProcessTypes_Descriptions_English.SenseiGateway = _CallingProcessTypes_Descriptions_English[7] = "SenseiGateway";
_CallingProcessTypes_Descriptions_English.ImagingBridge = _CallingProcessTypes_Descriptions_English[8] = "ImagingBridge";
_CallingProcessTypes_Descriptions_English.EnterpriseBackupManager = _CallingProcessTypes_Descriptions_English[9] = "EnterpriseBackupManager";
_CallingProcessTypes_Descriptions_English.Boost = _CallingProcessTypes_Descriptions_English[20] = "Boost";
exports.CallingProcessTypes_Descriptions = _CallingProcessTypes_Descriptions;
exports.CallingProcessTypes_Descriptions_English = _CallingProcessTypes_Descriptions_English;
var _CallingProcessTypes_DisplayOrders = {};
_CallingProcessTypes_DisplayOrders.CMP = _CallingProcessTypes_DisplayOrders[0] = 0;
_CallingProcessTypes_DisplayOrders.EForms = _CallingProcessTypes_DisplayOrders[1] = 1;
_CallingProcessTypes_DisplayOrders.Migrations = _CallingProcessTypes_DisplayOrders[2] = 2;
_CallingProcessTypes_DisplayOrders.CMPMobile = _CallingProcessTypes_DisplayOrders[3] = 3;
_CallingProcessTypes_DisplayOrders.CMPWebView = _CallingProcessTypes_DisplayOrders[4] = 4;
_CallingProcessTypes_DisplayOrders.EsEnrollments = _CallingProcessTypes_DisplayOrders[5] = 5;
_CallingProcessTypes_DisplayOrders.Partners = _CallingProcessTypes_DisplayOrders[6] = 6;
_CallingProcessTypes_DisplayOrders.SenseiGateway = _CallingProcessTypes_DisplayOrders[7] = 7;
_CallingProcessTypes_DisplayOrders.ImagingBridge = _CallingProcessTypes_DisplayOrders[8] = 8;
_CallingProcessTypes_DisplayOrders.EnterpriseBackupManager = _CallingProcessTypes_DisplayOrders[9] = 9;
_CallingProcessTypes_DisplayOrders.Boost = _CallingProcessTypes_DisplayOrders[20] = 20;
exports.CallingProcessTypes_DisplayOrders = _CallingProcessTypes_DisplayOrders;
