import React from "react";
import { Icon, Classes } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";
import css from "./styles.module.scss";

interface IProps {
  icon: IconName;
  title: string;
  description: React.ReactNode;
}

const SectionLite: React.FC<IProps> = ({
  title,
  icon,
  description,
  children,
}) => {
  return (
    <div className={css.section}>
      <div className={css.header}>
        <div>
          <Icon icon={icon} />
          <h3>{title}</h3>
        </div>
        <div className={`${css.subtitle} ${Classes.TEXT_SMALL}`}>
          {description}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default SectionLite;
