import React, { createContext, useEffect, useState, useContext } from "react";
import { Callout, Intent } from "@blueprintjs/core";
import Wait from "./Wait";
import { loadConfig, IAppConfig } from "../../services/appConfigService";

const configContext = createContext<IAppConfig | undefined>(undefined);

export const AppConfigProvider: React.FC = ({children}) => {
  const [config, setConfig] = useState<IAppConfig | undefined | null>();
  useEffect(() => {
    loadConfig()
    .then(setConfig)
    .catch(() => setConfig(null));
  }, []);

  // Config is loading ....
  if (typeof config === 'undefined') {
    return <div className="centered"><Wait style={{padding: '8px 20px'}} /></div>
  }

  // Failed to load config
  if (config === null) {
    return (
      <div className="centered">
        <Callout
          intent={Intent.DANGER}
          title="Error :("
          className="elevated"
          style={{width: 340}}
        >
          Failed to load application configuration.
        </Callout>
      </div>
    )
  }

  // Config loaded ...
  // console.log("CONFIG LOADED:", config);
  return (
    <configContext.Provider value={config} >
      {children}
    </configContext.Provider>
  );

}

const useAppConfig = () => {
  const config = useContext(configContext);
  if (!config) {
    throw new Error("AppConfig not available on context!")
  }
  return config;
}

export default useAppConfig;


