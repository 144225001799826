import { DpmsTypes } from "csd.phoenix.models/DpmsTypes";
import { IAgentUpdateResponse } from "../models/AgentUpdateResponse";
import { ISyncAgentCounts } from "../models/SyncAgentCounts";
import { ISyncAgentResetResults } from "../models/SyncAgentResetResults";
import http from "../services/api/http";
import { loadConfig } from "../services/appConfigService";


const defaultControllerName = "SyncAgents";


export async function getSyncAgentCounts() {
    const { gatewayAdminUrl } = await loadConfig();
    const url = gatewayAdminUrl
      .segment(defaultControllerName +'/AgentCounts')
      .toString();

    // Note: API response will deserialize the version information into a JS object type
    type ApiResponse = Omit<ISyncAgentCounts, "versions"> & { versions: Record<string, number> };
    const { data: { versions, ...rest} } = await http.get<ApiResponse>(url);

    // Convert version object to an array
    const versionsArray: [string, number][] = [];
    for (const prop in versions)
      versionsArray.push([prop, versions[prop]]);
    const result: ISyncAgentCounts = {  ...rest, versions: versionsArray }
    return result;
  }


export async function sendAgentUpdates(installerUri: string, dpmsType?: DpmsTypes, updateVersionThreshold?: string, agentId?: string) {
    const { gatewayAdminUrl } = await loadConfig();
    const url = gatewayAdminUrl
      .segment(defaultControllerName +'/commands/bulk/RunInstaller')
      .query({
        agentId,
        dpmsType,
        updateVersionThreshold,
        installerUri
      })
      .toString();

    //const requestConfig: AxiosRequestConfig = { headers: { Accept: "text/csv" } }
    const { data } = await http.post<IAgentUpdateResponse[]>(url);
    return data;
  }


export async function resetAgentInstallation(syncAgentId: string) {
  const { gatewayAdminUrl } = await loadConfig();
  const url = gatewayAdminUrl
    .segment(defaultControllerName)
    .segment('/:syncAgentId')
    .param({ syncAgentId })
    .segment('/ResetInstallation')
    .toString();

  // The REST call returns a JSON object with a property called "key",
  // but that name is already reserved as a special built-in property
  // of React components.  So we need to change its name to "installKey"
  // below before we return the result.  (It's the Agent Installation key.)

  const { data } = await http.post<ISyncAgentResetResults & {key:string}>(url);
  const { key, ...result } = data;
  result.installKey = key;

  return result;
}