import React from "react";
import { Dialog, Classes, DialogProps } from "@blueprintjs/core";
import classnames from "classnames";


interface IBaseDialogProps extends DialogProps {
  header?: React.ReactNode;
  actions: React.ReactNode;
}


const BaseDialog: React.FC<IBaseDialogProps> = ({header, actions, children, ...dialogProps}) => {
  if (dialogProps.isOpen !== true) {
    return null;
  }
  return (
    <Dialog {...dialogProps}>
      {/* Dialog header */}
      {header &&  <div className={Classes.DIALOG_HEADER}>{header}</div>}

      {/* Dialog body */}
      <div className={Classes.DIALOG_BODY} style={{overflow: 'auto', maxHeight: '70vh'}}>
        {children}
      </div>

      {/* Footer and Actions */}
      {actions && <div className={Classes.DIALOG_FOOTER}>
        <div className={classnames(Classes.DIALOG_FOOTER_ACTIONS, "centered")}>
          {actions}
        </div>
      </div>}
    </Dialog>
  );

}

export default BaseDialog;

