import { useRef, useEffect } from "react";

// Ref: https://reactjs.org/docs/hooks-faq.html
export default function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
