import React from "react";
import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Tooltip2 } from "@blueprintjs/popover2";
import { IEgressLogEntry } from "../../../../models/LogEntry";
import { getEgressLogEntries } from "../../../../stores/logEntriesStore";
import { ContentAlignment, IColumn } from "../../../shared/DataTable/models";
import CustomerLogViewerPanel, { TEgressLogColDef, TLogColDef } from "./CustomerLogViewerPanel";
import { FnOpenJsonDialog } from "./JsonDisplayDialog";
import { ILogPanelProps } from "./LogPanelProps";


const elide = (value: string, maxWidth: number = 255) => {
  if (!value)
    return ("");
  else if (value.length < maxWidth)
    return (value);
  else
    return (value.substring(0, maxWidth - 3) + "...");
};

const getEgressLogColumns = (openJsonDlg: FnOpenJsonDialog): TLogColDef[] => {
  const columns: TEgressLogColDef[] = [
    {
      header: "Created On",
      name: "createdOn"
    },

    {
      header: "Egress Log ID",
      name: "id",
      disableSort: true     // ID counter is reset at midnight each day.  Not useful for sorting.
    },

    {
      header: "Message",
      name: "message",      // Allowing this to sort; maybe you want to know how many of a certain kind of msg?

      renderer: (value: string, row: IEgressLogEntry, col: IColumn<IEgressLogEntry, string>) => {
        const tooltip = !!row.exception
          ? <div>{elide(row.exception!.description[0], 180)}</div>
          : <div>No exception description available</div>;
        return (
          < Tooltip2 content={tooltip}>
            <div>{elide(value)}</div>
          </Tooltip2>
        );
      }
    },

    {
      header: "Log Level",
      name: "logLevel"
    },

    {
      header: "Session ID",
      name: "sessionId"
    },

    {
      header: "Error Count",
      name: "_errors_",
      disableSort: true,
      styles: { alignment: ContentAlignment.Center },
      renderer: (ignored: any, row: IEgressLogEntry, col: IColumn<IEgressLogEntry, any>) => {
        const { failedEntities = [] } = row;
        if (failedEntities.length === 0) {
          return (<div></div>);
        }
        else {
          const totals: Map<string, number> = failedEntities.reduce((acc, e) => {
            acc.set(e.EntityType, ((acc.get(e.EntityType) || 0) + 1));
            return acc;
          }, new Map());

          const tooltip = (
            <div>
              {Array.from(totals.entries()).map(([key, value]) => <div key={key}>{key}: {value}</div>)}
            </div>
          );
          return (
            < Tooltip2 content={tooltip}>
              <div style={{ padding: "0 4px" }}>{failedEntities.length}</div>
            </Tooltip2>
          );
        }
      }
    },

    {
      header: "",
      name: "_actions_",
      disableSort: true,
      accessor: (data: IEgressLogEntry) => data,
      renderer: (data: IEgressLogEntry) => {
        return (
          <Button onClick={() => openJsonDlg(data)}
            style={{ fontSize: "smaller" }}
            rightIcon={IconNames.EYE_OPEN}
            title="View Log Details"
            small={true}
            minimal={true}>
          </Button>
        );
      }
    }
  ];

  return columns as TLogColDef[];
};


const EgressLogsPanel: React.FC<ILogPanelProps> = (props) => {
  return (
    <CustomerLogViewerPanel
      {...props}
      colGetter={getEgressLogColumns}
      apiFunc={getEgressLogEntries}
    />
  );
}

export default EgressLogsPanel;
