import React from "react";
import Layout from "./Layout";
import ErrorCallout from "../shared/ErrorCallout";

interface IProps {
  title: string;
  description: React.ReactNode;

}

const ErrorPage: React.FC<IProps> = ({ title, description }) => {
  return (
    <Layout>
      <ErrorCallout title={title} description={description} />
    </Layout>
  );
}



export const PageNotFound = () =>
  <ErrorPage title="Sorry, this page is not available!" description="Please check that you've followed a valid link." />

export default ErrorPage;
