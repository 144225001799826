import React, { memo, useMemo, ReactNode } from "react";
import { Button, Intent } from "@blueprintjs/core";
import reactModal from "@prezly/react-promise-modal";
import BaseDialog from "./BaseDialog";
import useDialogState from "../useDialogState";



interface IMessageActions {
  okButtonLabel?: string;
  okButtonIntent?: Intent;
  onOk: () => void;
}


const MessageActions: React.FC<IMessageActions> = memo(({okButtonLabel, okButtonIntent, onOk}) => {
  return (
    <div className="centered">
      <Button
        text={okButtonLabel}
        intent={okButtonIntent || Intent.SUCCESS}
        onClick={onOk}
        style={{minWidth: 120, margin: '0 1em'}}
      />
    </div>)
})



interface IProps  extends IMessageActions {
  title: string;
  showCloseButton?: boolean;
  canEscapeClose?: boolean;
  Trigger: React.ElementType<{handleOpen: () => void}>;
}


const MessageDialog: React.FC<IProps> = memo(({
  Trigger,
  okButtonLabel = "OK",
  title,
  showCloseButton,
  canEscapeClose,
  onOk,
  okButtonIntent,
  children}) => {
  const [isOpen, open, close] = useDialogState();

  const actions = useMemo(() =>
    <MessageActions
      okButtonLabel={okButtonLabel}
      okButtonIntent={okButtonIntent}
      onOk={() => {close(); onOk();}}
  />, [okButtonLabel, okButtonIntent, close, onOk]);

  return (
    <>
      <Trigger handleOpen={open} />
      <BaseDialog
        title={title}
        isOpen={isOpen}
        onClose={close}
        canEscapeKeyClose={canEscapeClose}
        isCloseButtonShown={showCloseButton}
        actions={actions}
      >
        {children}
      </BaseDialog>
    </>
  );
});

export default MessageDialog;



function messageDialogPromise(title: string, message: ReactNode) {

  return reactModal(({show: isOpen, onSubmit: onClose}) => {
    const closeButton =
      <div className="centered">
        <Button
          text="Close"
          intent={Intent.PRIMARY}
          onClick={onClose}
        />
      </div>;

    return (
      <BaseDialog
        title={title}
        isOpen={isOpen}
        onClose={onClose}
        canEscapeKeyClose={true}
        isCloseButtonShown={true}
        actions={closeButton}
      >
      {message}
    </BaseDialog>)});
}

export { messageDialogPromise }
