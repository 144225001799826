import React, { memo, useState, useCallback, useMemo } from "react";
import { Intent, Popover, Position, Button, NumericInput } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import useDialogState from "../../../useDialogState";
import css from "../../dataTable.module.scss";


interface IProps {
  pageSize: number;
  pageNum: number;
  totalRecords: number;
  setPageNum: (pageNum: number) => void;
}



const PageSelector: React.FC<IProps> = memo(({pageSize, pageNum, setPageNum, totalRecords}) => {
  const totalPages = useMemo(() => Math.ceil(totalRecords / pageSize), [pageSize, totalRecords]);
  const [tempPageNum, setTempPageNum] = useState(pageNum);
  const [isOpen, open, close] = useDialogState();

  const openPopup = useCallback(() => {
    setTempPageNum(pageNum);
    open();
  }, [open, pageNum]);

  const handlePageChanged = useCallback(() => {
    close();
    setPageNum(tempPageNum);
  }, [close, setPageNum, tempPageNum]);

  const onValueChanged = useCallback((value, _) => setTempPageNum(value), []);

  if (totalPages < 2) {
    return null;
  }

  return (
      <Popover position={Position.TOP_LEFT} isOpen={isOpen} onClose={close} >
        <Button className={css.pagerButton} small={true} onClick={openPopup}>{pageNum}</Button>
        <div style={{padding: '1em'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div>Goto Page:&nbsp;</div>
            <NumericInput
              style={{padding: '1em 0', textAlign: 'right', maxWidth: `${totalPages.toString().length + 1}em`}}
              min={1} max={totalPages}
              clampValueOnBlur={true}
              value={tempPageNum}
              onValueChange={onValueChanged}
            />
          </div>
          <div style={{paddingTop: '1em', display: 'flex', justifyContent: 'center'}}>
            <Button icon={IconNames.TICK} intent={Intent.SUCCESS} style={{minWidth: 100,}} onClick={handlePageChanged}>OK</Button>
          </div>
        </div>
      </Popover>
  );
});


export default PageSelector;
