import React, { useCallback, useRef } from "react";
import classnames from "classnames";
import { FormGroup, InputGroup, Intent, TextArea } from "@blueprintjs/core";
import css from "../form.module.scss";
import { ITextFieldProps, TextInputType } from "../models";
import { useAutoComplete } from "../models/utils";
import { FastField, FieldProps, getIn } from "formik";
import { validationMethodFactory, hasRequiredValidation, findValidation, ValidationType } from "../models/validation";
import RequiredIndicator from "../../../shared/RequiredIndicator";
import useFormContext from "../FormContext";
import PrintedTextField from "./PrintedTextField";


type  Props<T=any> = FieldProps<T> & { fieldProps: ITextFieldProps };

const BoundTextField = ({ field, form, fieldProps }: Props) => {
  const { isEditing, isPrinting, fieldChangedSignal, fieldPropOverrides } = useFormContext();
  const autoComplete = useAutoComplete(fieldProps);
  const error = (form.submitCount || getIn(form.touched, field.name)) && getIn(form.errors, field.name);
  const isRequired = hasRequiredValidation(fieldProps.validations);
  const intent = error ? Intent.DANGER : Intent.NONE;
  const value = field.value || ''; // Prevent error when initial value is missing
  const initialValue = useRef(value);
  const isMultiLine = fieldProps.inputType === TextInputType.Multiline;
  const isEmail = findValidation(ValidationType.Email, fieldProps.validations);

  const onFocus = useCallback((e: React.FocusEvent) => {
    initialValue.current = field.value;
  }, [field.value]);

  const onBlur = useCallback((e: React.FocusEvent) => {
    field.onBlur(e);
    field.value !== initialValue.current && fieldChangedSignal.dispatch(field.name, field.value, initialValue.current);
  }, [field, fieldChangedSignal]);

  const control = isPrinting
    ? <PrintedTextField value={value} />
    : isMultiLine
      ? <TextArea
          fill={true}
          autoComplete={autoComplete}
          inputMode={isEmail ? "email" : undefined}
          intent={intent}
          placeholder={(isEditing && fieldProps.prompt) || undefined}
          disabled={!isEditing}
          {...field}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          {...fieldPropOverrides[field.name]}
        />
      : <InputGroup
          fill={true}
          autoComplete={autoComplete}
          inputMode={isEmail ? "email" : undefined}
          intent={intent}
          placeholder={(isEditing && fieldProps.prompt) || undefined}
          disabled={!isEditing}
          type={fieldProps.inputType === TextInputType.Password ? "password" : "text"}
          {...field}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          {...fieldPropOverrides[field.name]}
        />

  const className = classnames(css.formPart);
  return (
    <FormGroup
      label={fieldProps.label}
      labelInfo={<RequiredIndicator show={isRequired} isPrinting={isPrinting} />}
      style={fieldProps.style}
      intent={intent}
      helperText={isEditing ? (error || <>&nbsp;</>) : undefined}
      className={className}
    >
      {control}
    </FormGroup>)
}


const TextField = (props : ITextFieldProps) => {
  console.assert(props && props.binding);
  const { isEditing } = useFormContext();
  const validate = isEditing ? validationMethodFactory(props) : undefined;
  return <FastField name={props.binding} fieldProps={props} component={BoundTextField} validate={validate} />;
}


export default TextField;






