import { isLocaleUS } from "./index";


interface ILocaleSettings {
  phone: {
    allowedChars: RegExp;
    allowedDigitCounts: number[];
    desc: string;
  },

  postCode: {
    regex: RegExp;
    maxLen: number;
    desc: string;
  }
}


const localeSettings: { [locale: string]: ILocaleSettings } = {
  us: {
    phone: {
      allowedChars: /^[0-9() -]*$/,
      allowedDigitCounts: [7, 10],
      desc: "Must be 7 or 10 digits"
    },
    postCode: {
      regex: /^[0-9]{5}([ -]?[0-9]{4})?$/,
      maxLen: 10,
      desc: "Must be 5 or 9 digits"
    }
  },

  other: {
    phone: {
      allowedChars: /^[0-9 -]*$/,
      allowedDigitCounts: [11],
      desc: "Must be 11 digits"
    },
    postCode: {
      // UK format: https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#Validation
      regex: /^[A-Za-z]{1,2}[0-9rR][0-9A-Za-z]? ?[0-9][A-Za-z]{2}$/,
      maxLen: 8,
      desc: "Invalid format"
    }
  }
}

export function getLocaleSettings(locale?: number) {
  return isLocaleUS(locale) ? localeSettings.us : localeSettings.other;
}
