import React, { useEffect, useState } from "react";
import { Button, Callout, Classes, Dialog, Icon, Intent } from "@blueprintjs/core";
import { IAgentUpdateResponse } from "../../../../models/AgentUpdateResponse";
import { toCsvData } from "../../../../utils/csvUtils";
import useDialogState from "../../../shared/useDialogState";
import { IconNames } from "@blueprintjs/icons";


interface IProps {
  data?: IAgentUpdateResponse[];
}

const Results: React.FC<IProps> = ({ data }) => {
  const haveData = !!data && data.length > 0;
  const [isOpen, open, close] = useDialogState();
  const [csvBlobUrl, setCsvBlobUrl] = useState<string|undefined>();

  useEffect(() => {
    if (!data || data.length === 0) return;
    const csvData = toCsvData(data);
    var blob = new Blob([csvData], {type: "text/csv"});
    setCsvBlobUrl(URL.createObjectURL(blob));
  }, [data]);

  useEffect(() => {
    if (haveData)
      open();

    if (!!csvBlobUrl) {
      return (() => URL.revokeObjectURL(csvBlobUrl));
    }
  }, [haveData, csvBlobUrl, open])

  if (!data) return null;

  if (data.length  === 0)
    return <Callout style={{maxWidth: 600}} intent={Intent.WARNING} >No agent updates were sent, because none matched the selected criteria</Callout>;

  return (
    <Dialog
    title="Agent Updates Sent"
    isOpen={isOpen}
    canOutsideClickClose={false}
    isCloseButtonShown={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>Updates queued: {data.length}</p>

        <div style={{display: !!csvBlobUrl ? 'block' : 'none'}}>
          <a href={csvBlobUrl} download="Sync-Agent-Updates.csv"><Icon icon={IconNames.DOWNLOAD} /> Download results as CSV</a>
        </div>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            icon={IconNames.TICK}
            onClick={close}
            intent={Intent.PRIMARY}
            className={Classes.DIALOG_CLOSE_BUTTON}
          >Ok</Button>
        </div>
      </div>

    </Dialog>
  );

}



export default Results;
