import React, { memo, useCallback } from "react";
import ColumnHeader from "./ColumnHeader";
import { IColumn, IColumnSort, SortDirection } from "../models";


interface IProps<TRow> {
  columns: IColumn<TRow, any>[];
  isDisabled: boolean;
  sorting?: IColumnSort<TRow>;
  setSorting: (sorting: IColumnSort<TRow>) => void;
}


const TableHeader = <TRow extends {}>({ columns, isDisabled, sorting, setSorting }: IProps<TRow>) => {
  const handleSortChanged = useCallback((column: IColumn<TRow, any>, sortDirection: SortDirection) =>
    setSorting({fieldName: column.name, sortDirection}), [setSorting])

  return (
    <thead>
      <tr>
      {columns.map((c, i) =>
        <ColumnHeader<TRow, any>
          key={`${i}-${c.name}`}
          column={c}
          isDisabled={isDisabled}
          sortDirection={sorting && sorting.fieldName === c.name ? sorting.sortDirection : SortDirection.None}
          onSortChanged={handleSortChanged}
          />)}
      </tr>
    </thead>
  );
}

const memoized = memo(TableHeader) as typeof TableHeader;
export default memoized;
