import React          from "react";
import { ICustomer }  from "../../../../models/Customer";


const formatDate = (date?: Date) => !!date ? date.toLocaleString() : '';


const CustomerInfo: React.FC<ICustomer> = (props) => {
  return (
    <>
      {/* Table = Minimal output formatting.  Use blueprint for production-quality format. */}
      <div>
        <br></br>
        <p><b>Customer Summary:</b></p>
        <table>
          <tbody>
            <tr>
              <td>SAP ID:</td>
              <td>{props.id}</td>
            </tr>
            <tr>
              <td>Customer Name:</td>
              <td>{props.name}</td>
            </tr>
            <tr>
              <td>DPMS Type:</td>
              <td>{props.dpmsType}</td>
            </tr>
            <tr>
              <td>Hosted:</td>
              <td>{props.id === props.agentId ? "no" : "yes"}</td>
            </tr>
            {props.id !== props.agentId &&
              <tr>
                <td>Agent ID:</td>
                <td>{props.agentId}</td>
              </tr>
            }
            <tr>
              <td>Time Zone:</td>
              <td>{props.timeZoneName}</td>
            </tr>
            <tr>
              <td>Last Ingress:</td>
              <td>{formatDate(props.lastIngressSession?.createdOn)}</td>
            </tr>
            <tr>
              <td>Last Egress:</td>
              <td>{formatDate(props.lastEgressSession?.createdOn)}</td>
            </tr>
            <tr>
              <td>Last Egress Error:</td>
              <td>{formatDate(props.lastEgressEntityFailure?.date)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default CustomerInfo;


