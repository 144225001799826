import { Configuration,  BrowserCacheLocation, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { isAppMode, AppModes } from "../appModeService";
import routes from "../../routePaths";
import getAppConfig from "../appConfigService";


let _msalClient: PublicClientApplication;

export const consoleTextStyles = {
  Title: "background: SteelBlue; color: #000",
  Unset: "background: unset; color:unset"
}

const getAzureAdConfig = () => getAppConfig().azureAd;

export function getMsalClient() {
  if (!_msalClient) {
    _msalClient = new PublicClientApplication(getMsalClientConfig());
  }

  return _msalClient;
}

export function getAuthScopes() {
  const isPartnerMode = isAppMode(AppModes.Partner);
  const config = getAzureAdConfig();
  if (isPartnerMode) {
    return config.partner.scopes;
  }

  // Our app is registered as a single-tenant app in CSD Employee AD.
  // As such, we'll set the scope to trhe Client ID of our app registration
  return [`${config.employee.clientId}/.default`];;
}


export function getMsalClientConfig() {
  const config = getAzureAdConfig();
  const isPartnerMode = isAppMode(AppModes.Partner);
  const { tenantName, clientId } = isPartnerMode ? config.partner : config.employee;

  const authority = isPartnerMode
    ? `https://${config.partner.loginDomain!}/${tenantName}.onmicrosoft.com/${config.partner.policies.login}`
    : `https://login.microsoftonline.com/${tenantName}`;

  const msalConfig: Configuration = {
    auth: {
        authority,
        clientId,
        knownAuthorities: isPartnerMode ? [config.partner.loginDomain!] : [],
        redirectUri: window.location.origin + (isPartnerMode ?  "/partner" : ""),
        postLogoutRedirectUri: isPartnerMode ? routes.partnerMode.root.path : routes.home.path,
    },

    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: false
    },

    system: {
        loggerOptions: { loggerCallback }
      }
    }

  //console.log("%cMSAL%c Config: ", consoleTitleStyle, consoleUnsetStyle, msalConfig);
  return msalConfig;
}



const isProdEnv = process.env.NODE_ENV === "production";

function loggerCallback(level: LogLevel, message: string, containsPii: boolean) {
  const { azureAd: { logMsalEvents } } = getAppConfig();

  if (!logMsalEvents || containsPii) {
      return;
  }

  message = "%cMSAL%c " + message;

  switch (level) {
      case LogLevel.Error:
          console.error(message, consoleTextStyles.Title, consoleTextStyles.Unset);
          return;

      case LogLevel.Warning:
        console.warn(message, consoleTextStyles.Title, consoleTextStyles.Unset);
        return;

      case LogLevel.Info:
          isProdEnv || console.info(message, consoleTextStyles.Title, consoleTextStyles.Unset);
          return;

      case LogLevel.Verbose:
          isProdEnv || console.debug(message, consoleTextStyles.Title, consoleTextStyles.Unset);
          return;

  }
}
