import isDate from "lodash.isdate";
import { formatDate  } from "./dataFormatters";


const formatStringValue = (value: string) => '"' + value.replace('"', '""') + '"';

const defaultDateFormatter = (date: Date) => formatDate(date, undefined, undefined, false);

export const getHeaders = (obj: Record<string, any>) => {
  let headers = '';
  for (const prop in obj) {
    if (headers !== '') headers += ',';
    headers += prop;
  }
  return headers;
}

export function toCsvRecord(obj: Record<string, any>, stringifyComplexTypes = false, dateFormatter: (date: Date) => string = defaultDateFormatter) {
  let line = '';
  for (const prop in obj) {
    if (line !== '') line += ',';
    const value = obj[prop];
    if (!value)
      continue;

    if (isDate(value))
      line += formatStringValue(dateFormatter(value));
    else {
      const type = typeof(value);
      if (type === "string")
        line += formatStringValue(value);
      else if (type === "number" || type === "bigint" || type === "boolean")
        line += value.toString();
      else
        line += stringifyComplexTypes ? formatStringValue(JSON.stringify(value)) : `?? (${type})`
    }
  }

  return line;
}



export const toCsvData = (data: Record<string, any>[]) => {
  let csvLines = getHeaders(data[0]) + "\r\n";
  for (const item of data)
    csvLines += toCsvRecord(item) + "\r\n";

  return csvLines;
}
