import React, { memo } from "react";
import { Button, Intent } from "@blueprintjs/core";
import { CancelButtonState } from "../models/baseTypes";


export interface IFooterProps {
  isDirty: boolean;
  cancelButtonState?: CancelButtonState;
  submitForm?: () => void;
  resetForm: () => void;
  className?: string;
}



const Footer: React.FC<IFooterProps> = memo(({isDirty, cancelButtonState = CancelButtonState.EnabledIfDirty, submitForm, resetForm, className })  => {
  return (
  <div className={className}>
    <Button
      text="Save"
      intent={Intent.SUCCESS}
      type="submit"
      disabled={!isDirty || !submitForm}
      onClick={submitForm}
      style={{minWidth: 120, margin: '0 1em'}}
    />
    {(cancelButtonState !== CancelButtonState.AlwayHide) &&
    <Button
      text="Cancel"
      intent={Intent.WARNING}
      disabled={cancelButtonState !== CancelButtonState.AlwaysEnabled && !isDirty}
      onClick={resetForm}
      style={{minWidth: 120, margin: '0 1em'}}
    />}
  </div>);
});


export default Footer;
