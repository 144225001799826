import React from "react";
import { IColumnCounts, ColumnWidth, HeaderSize, ContentAlignment, FormPartType, Orientation } from "./baseTypes";
import { IValidation, IRequiredValidation } from "./validation";


type supportedCssProperties = "padding" | "margin" | "minWidth" | "maxWidth" | "minHeight" | "maxHeight" ;
interface ICssStyle extends Pick<React.CSSProperties, supportedCssProperties> {
}

export interface IBasePartProps {
  style?: ICssStyle;
  //options?: object;
}

type PropsToJson<TProps extends IBasePartProps, TPartType extends FormPartType> = Readonly<TProps & { type: TPartType }>;


interface IPartWithAlignment extends IBasePartProps {
  alignment?: ContentAlignment;
}


// --- Part: Spacer ---
export interface ISpacerPartProps extends IBasePartProps {
}

export interface ISpacerPartJson extends PropsToJson<ISpacerPartProps, FormPartType.Spacer> {

}


// --- Part: Page break part ---
export interface IPageBreakPartProps extends IBasePartProps {
  breakAfter?: boolean;
}

export interface IPageBreakPartJson extends PropsToJson<IPageBreakPartProps, FormPartType.PageBreak> {

}


// --- Part: Error Messaage ---
export interface IErrorMessagePartProps extends IPartWithAlignment {
  id: string;
  fieldDeps: string[];
}

export interface IErrorMessagePartJson extends PropsToJson<IErrorMessagePartProps, FormPartType.ErrorMsg> {

}


// --- Part: Logo ---
export interface ILogoPartProps extends IPartWithAlignment {
}

export interface ILogoPartJson extends PropsToJson<ILogoPartProps, FormPartType.Logo> {

}


// --- Part: Header ---
export interface IHeaderPartProps extends IPartWithAlignment {
  size: HeaderSize;
  content: string;
}

export interface IHeaderPartJson extends PropsToJson<IHeaderPartProps, FormPartType.Header> {

}


// --- Part: Content ---
export interface IContentPartProps extends IPartWithAlignment {
  content: string[];
}


export interface IContentPartJson extends PropsToJson<IContentPartProps, FormPartType.Content> {

}


// --- Part: Markdown ---
export interface IMarkdownPartProps extends IPartWithAlignment {
  content: string[];
}

export interface IMarkdownPartJson extends PropsToJson<IMarkdownPartProps, FormPartType.Markdown> {
}


// --- Part: Field Base ---
export interface IFieldPartBase extends IBasePartProps {
  label: string;
  binding: string;
  validations: IValidation[];
}


// --- Part: Text Field ---
export enum TextInputType { Text, Password, Multiline }

export interface ITextFieldProps extends IFieldPartBase {
  prompt?: string;
  inputType?: TextInputType;
}

export interface ITextFieldJson extends PropsToJson<ITextFieldProps, FormPartType.TextField> {
};


// --- Part: Number Field ---
export interface INumberFieldProps extends IFieldPartBase {
  prompt?: string;
}

export interface INumberFieldJson extends PropsToJson<INumberFieldProps, FormPartType.NumberField> {
};


// --- Part: Phone Number Field ---
export interface IPhoneNumFieldProps extends IFieldPartBase {
  prompt?: string;
}

export interface IPhoneNumFieldJson extends PropsToJson<IPhoneNumFieldProps, FormPartType.PhoneNumField> {
};


// --- Part: Post Code Field ---
export interface IPostCodeFieldProps extends IFieldPartBase {
  prompt?: string;
}

export interface IPostCodeFieldJson extends PropsToJson<IPostCodeFieldProps, FormPartType.PostCodeField> {
};


// --- Part: Date Field ---
export interface IDateFieldProps extends IFieldPartBase {
  prompt?: string;
  isTimeZoneAgnostic?: boolean;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  showShortcuts?: boolean;
}

export interface IDateFieldJson extends PropsToJson<IDateFieldProps, FormPartType.DateField> {
};


// --- Part: Signature Field ---
export interface ISignatureFieldProps extends IFieldPartBase {
}

export interface ISignatureFieldJson extends PropsToJson<ISignatureFieldProps, FormPartType.SignatureField> {
};


// --- Part: Radio Field ---
export type FieldOptionType = string | number | boolean;
export interface IFieldOption {
  value: FieldOptionType;
  text?: string;
}


export interface IRadioFieldProps extends IFieldPartBase {
    options: IFieldOption[];
    orientation?: Orientation;
}

export interface IRadioFieldJson extends PropsToJson<IRadioFieldProps, FormPartType.RadioGroupField> {
};


// --- Part: Checkbox Field ---
export interface ICheckboxFieldProps extends IFieldPartBase {
  useAltStyle?: boolean;
  checkedLabel?: string;
  uncheckedLabel?: string;
}

export interface ICheckboxFieldJson extends PropsToJson<ICheckboxFieldProps, FormPartType.CheckboxField> {
};


// --- Part: Checkbox group field ---

export interface ICheckboxGroupProps extends IFieldPartBase, IColumnCounts {
  options: IFieldOption[];
  useAltStyle?: boolean;
}

export interface ICheckboxGroupFieldJson extends PropsToJson<ICheckboxGroupProps, FormPartType.CheckboxGroupField> {
};


// --- Part: Select Field ---
export interface ISelectFieldProps extends IFieldPartBase {
  options: IFieldOption[];
  isLoading?: boolean;
}

export interface ISelectFieldJson extends PropsToJson<Omit<ISelectFieldProps, "isLoading">, FormPartType.SelectField> {
};


// --- Part: PatientConnectionType Field ---
export interface IPatientConnectionTypeFieldProps extends IFieldPartBase {
  validations: [IRequiredValidation];
}

export interface IPatientConnectionTypeFieldJson extends PropsToJson<IPatientConnectionTypeFieldProps, FormPartType.PatientConnectionTypeField> {
};


// --- Part: RelationshipToInsuredField Field ---
export interface IRelationshipToInsuredFieldProps extends IFieldPartBase {
  validations: [IRequiredValidation];
}

export interface IRelationshipToInsuredFieldJson extends PropsToJson<IRelationshipToInsuredFieldProps, FormPartType.RelationshipToInsuredField> {
};



// --- Part: StateProvinceSelect Field ---
export interface IStateProvinceFieldProps extends IFieldPartBase {
  validations: [IRequiredValidation];
}

export interface IStateProvinceFieldJson extends PropsToJson<IStateProvinceFieldProps, FormPartType.StateProvinceField> {
};

// --- Part: TimeZoneSelect Field ---
export interface ITimeZoneFieldProps extends IFieldPartBase {
}

export interface ITimeZoneFieldJson extends PropsToJson<ITimeZoneFieldProps, FormPartType.TimeZoneField> {
};

// --- Part: Column ---
interface IColumnWidths {
  mobileWidth?: ColumnWidth;
  tabletWidth?: ColumnWidth;
  desktopWidth?: ColumnWidth;
}

export interface IColPartProps extends IBasePartProps,  IColumnWidths {
}

type ColumnChildJson = IErrorMessagePartJson | ISpacerPartJson | ILogoPartJson | IHeaderPartJson | IContentPartJson | IMarkdownPartJson
  | ITextFieldJson | INumberFieldJson | IRadioFieldJson | ICheckboxFieldJson | ICheckboxGroupFieldJson
  | ISelectFieldJson  | IPhoneNumFieldJson | IPostCodeFieldJson
  | IStateProvinceFieldJson | IPatientConnectionTypeFieldJson | IRelationshipToInsuredFieldJson
  | IDateFieldJson | ISignatureFieldJson | ITimeZoneFieldJson;

export interface IColPartJson extends PropsToJson<IColPartProps, FormPartType.Col> {
  readonly part: ColumnChildJson;
};

// --- Part: Section ---
export interface ISectionPartProps extends IPartWithAlignment {
  title?: string;
  //orientation?: Orientation;
}

type SectionChildJson = ColumnChildJson;

export interface ISectionPartJson extends PropsToJson<ISectionPartProps, FormPartType.Section> {
  readonly parts: SectionChildJson[];
}


export interface IRowPartProps extends IBasePartProps, IColumnCounts {

}


// --- Part: Row ---
export interface IRowPartJson extends PropsToJson<IRowPartProps, FormPartType.Row> {
  readonly columns: IColPartJson[];
};


// --- Form ---
export type IFormPartJson =
  // General parts
  IErrorMessagePartJson | ISpacerPartJson | IPageBreakPartJson | ILogoPartJson | IHeaderPartJson | IContentPartJson | IMarkdownPartJson
  // Container parts
  | ISectionPartJson  | IRowPartJson | IColPartJson
  // Field Parts
  | ITextFieldJson | INumberFieldJson | IRadioFieldJson | ICheckboxFieldJson | ICheckboxGroupFieldJson
  | ISelectFieldJson | IPhoneNumFieldJson | IPostCodeFieldJson
  | IStateProvinceFieldJson | IPatientConnectionTypeFieldJson | IRelationshipToInsuredFieldJson
  | IDateFieldJson | ISignatureFieldJson | ITimeZoneFieldJson;


export interface IFormTemplateJson {
    name: string;
    version: "1",
    savePrompt?: string;
    parts: IFormPartJson[];
  }
