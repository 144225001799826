import React, { memo, useCallback, useState } from "react";
import "@blueprintjs/core/lib/css/blueprint.css"
import "normalize.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import { MultiSelect, ItemRenderer, ItemPredicate } from "@blueprintjs/select";
import { Button, Intent, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { IFieldOption } from "./form/models";


// const items: IFieldOption[] = [
//   {
//     "value": "1111",
//     "text": "location 1",
//   },
//   {
//     "value": "2222",
//     "text": "location 2",
//   },
//   {
//     "value": "3333",
//     "text": "location 3",
//   }
// ];

const noResults = <MenuItem text="No match" disabled={true} />;

const MultiSelector = MultiSelect.ofType<IFieldOption>();

const itemPredicate: ItemPredicate<IFieldOption> = (query, item, _, exact) => {
  const _query = query.toLocaleLowerCase();
  const _text = (item.text || (item.value || '').toString()).toLowerCase();
  return exact === true
    ? _query === _text
    : _text.indexOf(_query) >= 0;

}


interface IProps {
  items: IFieldOption[];
  onChange: (values: string[]) => void;
  defaultText?: string;
}


const MultiSelection: React.FC<IProps> = memo(({ items, onChange, defaultText }) => {
  const [selectedItems, setSelectedItems] = useState<IFieldOption[]>([]);
  const getSelectedIndex = useCallback((item: IFieldOption) => selectedItems.indexOf(item), [selectedItems]);
  const isSelected = useCallback((item: IFieldOption) => getSelectedIndex(item) !== -1, [getSelectedIndex]);

  const itemRenderer: ItemRenderer<IFieldOption> = useCallback((item, { handleClick, modifiers: { disabled, matchesPredicate, active } }) => {
    return matchesPredicate
      ? <MenuItem
        active={active}
        icon={isSelected(item) ? "tick" : "blank"}
        key={(item.value || "").toString()}
        text={item.text}
        disabled={disabled}
        onClick={handleClick}
      />
      : null;
  }, [isSelected]);

  const deselectItem = useCallback((index: number) => {
    const newItems = Array.from(selectedItems);
    newItems.splice(index, 1);
    setSelectedItems(newItems);
    return newItems;

  }, [selectedItems]);

  const handleTagRemove = useCallback((_tag: React.ReactNode, index: number) => {
    const newItems = deselectItem(index);

    onChange(newItems.map((p) => ((p.value || "").toString())));

  }, [deselectItem, onChange]);


  const renderTag = useCallback((item: IFieldOption) => item.text, []);

  const selectItem = useCallback((item: IFieldOption) => {

    const newItems = Array.from(selectedItems);
    newItems.push(item);
    setSelectedItems(newItems);
    return newItems;

  }, [selectedItems]);


  const handleSelect = useCallback((item: IFieldOption) => {
    const newItems = isSelected(item) ? deselectItem(getSelectedIndex(item)) : selectItem(item);

    onChange(newItems.map((p) => (p.value.toString())));

  }, [deselectItem, getSelectedIndex, isSelected, onChange, selectItem]);

  const onHandleClear = useCallback(() => {
    setSelectedItems([]);
    onChange([]);

  }, [onChange]);

  const buttons = items.length > 0 ?
    (
      <div>
        {(selectedItems.length > 0) &&
          <Button
            minimal={true}
            small={true}
            icon={IconNames.CROSS}
            intent={Intent.DANGER}
            onClick={onHandleClear}
            title="Cancel"
          />
        }
        <Button
          minimal={true}
          small={true}
          icon={IconNames.CHEVRON_DOWN}
        />
      </div>
    ) : null;


  return (
    <MultiSelector
      fill={true}
      itemRenderer={itemRenderer}
      items={items}
      placeholder={defaultText ?? ""}
      tagRenderer={renderTag}
      onItemSelect={handleSelect}
      selectedItems={selectedItems}
      openOnKeyDown={false}
      noResults={noResults}
      itemPredicate={itemPredicate}
      resetOnSelect={true}
      //filterable={true}
      tagInputProps={{
        onRemove: handleTagRemove,
        fill: true,
        //disabled: true,
        tagProps: { minimal: true, interactive: false }, rightElement: buttons!
      }}
      popoverProps={{ minimal: false, position: "bottom-left" }}
    />
  );
})

export default MultiSelection;
