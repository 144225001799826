import UrlAssembler from "url-assembler";
import { AxiosRequestConfig, AxiosInstance } from "axios";
import { ODataQueryParams, IApiQueryOptions, IApiQueryResponse, IODataSuccessResponse, KeyValuePairs, ODataActionResponse } from "./models";
import { assertNotEmpty } from "../../../utils/objectUtils";
import Resource from "./Resource";
import globalHttp from "../http";



type HttpMethod = AxiosRequestConfig["method"];
export const activeRecordsFilter: ODataQueryParams = {filter: "RecordStatus eq 0"}

type HttpRequestWithoutUrl = Omit<AxiosRequestConfig, "url">;
type PostOperationQueryParams = Pick<ODataQueryParams, "expand" | "select">;


export default class ODataApi {
  private baseUrl: UrlAssembler;
  private entitySetName: string;
  private http: AxiosInstance;

  constructor(baseUrl: UrlAssembler, entitySetName: string, http = globalHttp) {
    this.baseUrl = assertNotEmpty(baseUrl);
    this.entitySetName = assertNotEmpty(entitySetName);
    this.http = http;
  }


  private async baseRunQuery<T>(httpRequest: AxiosRequestConfig): Promise<IApiQueryResponse<T>> {
    try {
      const { data } = await this.http.request<IODataSuccessResponse<T>>(httpRequest);
      return {
        count: data["@odata.count"],
        value: data.value
      };
    }
    catch(_e) {

      // else if (error.response && error.response.status === 400) {
  //   //Handle BadRequest ModelState Error
  //   const response = (error.response.data as any)["error"];
  //   return Promise.reject(response && response["innererror"]||error);
  // }

      //console.log(_e);
      //const axiosError = _e as AxiosError<IODataErrorResponse>;
      // let errorMsg;
      // // Handle "400 - Bad Data" error.
      // if (axiosError.response && axiosError.response.status === 400) {
      //   const { data } = axiosError.response;
      //   errorMsg = data;
      // }
      // else
      //   errorMsg = _e.message;

      //const { error: { message: errorMsg } } = _e as IODataErrorResponse;
      const message = `OData ${httpRequest.method} call to "${this.entitySetName}" failed. ${_e.message}`;
      console.error(message, _e);
      throw _e;
    }
  }


  runSetQuery<T>(
    httpRequest: HttpRequestWithoutUrl,
    queryParams?: ODataQueryParams,
    pageOptions?: IApiQueryOptions,
    entitySet?: string
  ): Promise<IApiQueryResponse<T[]>> {

    const url = new Resource(this.baseUrl.toString())
      .entitySet(!!entitySet ? this.entitySetName + "/" + entitySet : this.entitySetName)
      .queryOptions(queryParams)
      .applyApiQueryOptions(pageOptions)
      .asString();

    const requestConfig = { ...httpRequest, url }
    return this.baseRunQuery<T[]>(requestConfig);
  }


  runCollectionFunction2<TReq, TResp = IApiQueryResponse<TReq>>(
    httpMethod: HttpMethod = "GET",
    functionName: string,
    functionParams?: KeyValuePairs,
    queryParams?: ODataQueryParams
  ): Promise<TResp> {

    return this.baseOdataFunction(httpMethod, functionName, undefined, functionParams, queryParams);
  }





  async runEntityQuery<T>(
    entityId: string,
    httpRequest: HttpRequestWithoutUrl,
    queryParams?: ODataQueryParams,
    pageOptions?: IApiQueryOptions,
  ): Promise<T> {

    const url = new Resource(this.baseUrl.toString())
        .entity(this.entitySetName, entityId)
        .queryOptions(queryParams)
        .applyApiQueryOptions(pageOptions)
        .asString();

    const requestConfig = {...httpRequest, url }
    const result = await this.baseRunQuery<T[]>(requestConfig);
    if (result.value.length === 0) {
      throw new Error(`Can't find ${this.entitySetName} with id: ${entityId}`);
    }

    console.assert(result.value.length === 1);
    return result.value[0];
  }

  // Assumption: Response is a single result, not a collection
  private async baseOdataAction<TRsp>(
    actionName: string,
    entityId?: string,
    data?: KeyValuePairs,
    queryParams?: ODataQueryParams
  ): Promise<TRsp> {
    let res = new Resource(this.baseUrl.toString());
    res = !!entityId ? res.entity(this.entitySetName, entityId) : res.entitySet(this.entitySetName);
    const url = res
      .action(actionName)
      .queryOptions(queryParams)
      .asString();

    const httpRequest: AxiosRequestConfig = {
      url,
      method: "POST",
      data
    };

    try {
      const { data } = await this.http.request<ODataActionResponse<TRsp>>(httpRequest);
      const {"@odata.context": _, ...entity} = data;
      //console.log("entity", entity)

      return entity as any as TRsp;
    }
    catch(_e) {
      const message = `OData action ${this.entitySetName}/${actionName} failed. ${_e.message}`;
      console.error(message, _e);
      throw _e;
    }
  }



  // Assumption: Response is a snigle result, not a collection
  private async baseOdataFunction<TRsp>(
    httpMethod: HttpMethod = "POST",
    functionName: string,
    entityId?: string,
    functionParams?: KeyValuePairs,
    queryParams?: ODataQueryParams
  ): Promise<TRsp> {

    let res = new Resource(this.baseUrl.toString());
    res = !!entityId ? res.entity(this.entitySetName, entityId) : res.entitySet(this.entitySetName);
    const url = res
        .function(functionName, functionParams)
        .queryOptions(queryParams)
        .asString();

    const httpRequest: AxiosRequestConfig = {
      url,
      method: httpMethod
    };

    try {
      const { data } = await this.http.request<ODataActionResponse<TRsp>>(httpRequest);
      const {"@odata.context": _, ...entity} = data;
      //console.log("entity", entity)

      return entity as any as TRsp;
    }
    catch(_e) {
      const message = `OData function ${this.entitySetName}/${functionName} failed. ${_e.message}`;
      console.error(message, _e);
      throw _e;
    }
  }



  runEntityDeepUpdate<TReq extends {RecordId: string}>(data: TReq, queryParams?: ODataQueryParams): Promise<TReq> {
    const model = { object: data  }
    return this.baseOdataAction<TReq>("DeepUpdate", data.RecordId, model, queryParams);
  }


  runCollectionAction<T>(
    actionName: string,
    data?: KeyValuePairs,
    queryParams?: PostOperationQueryParams
  ): Promise<T> {
    return this.baseOdataAction(actionName, undefined, data, queryParams)
  }

  runCollectionFunction<TReq, TResp = IApiQueryResponse<TReq>>(
    httpMethod: HttpMethod = "GET",
    functionName: string,
    functionParams?: KeyValuePairs,
    queryParams?: ODataQueryParams
  ): Promise<TResp> {

    return this.baseOdataFunction(httpMethod, functionName, undefined, functionParams, queryParams);
  }

  runEntityAction<T>(
    entityId: string,
    actionName: string,
    data?: KeyValuePairs,
    queryParams?: ODataQueryParams
  ): Promise<T> {

    return this.baseOdataAction(actionName, entityId, data, queryParams);
  }


  runEntityFunction<T>(
    httpMethod: HttpMethod = "GET",
    entityId: string,
    functionName: string,
    functionParams?: KeyValuePairs,
    queryParams?: ODataQueryParams
  ): Promise<T> {

    return this.baseOdataFunction(httpMethod, functionName, entityId, functionParams, queryParams);
  }


}


