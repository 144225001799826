import { useRef, useMemo } from "react";

const _missing = Symbol("useLazyRef.missing");

// Like a ref, but creates the initla value lazily. This is useful when we want to initialize a ref value
// using the result of a funciton call -- ex: `useReg(someFunction())`. In such a case,  this will only
// invoke the function only once, rather than on each render.
export default function useLazyRef<T>(factory: () => T) {
  const value = useRef<T | typeof _missing>(_missing);

  const refObject = useMemo<React.MutableRefObject<T>>(() => ({
    get current() {
      if (value.current === _missing) {
        value.current = factory();
      }
      return value.current as T;
    },

    set current(newValue: T) {
      value.current = newValue;
    }
  }), [factory]);


  return refObject;
}
