"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsvcsEnrollmentStatuses_DisplayOrders = exports.EsvcsEnrollmentStatuses_Descriptions_English = exports.EsvcsEnrollmentStatuses_Descriptions = exports.EsvcsEnrollmentStatuses_translateDescriptionBack = exports.EsvcsEnrollmentStatuses_translateDescriptions = exports.EsvcsEnrollmentStatuses = void 0;
var EsvcsEnrollmentStatuses;
(function (EsvcsEnrollmentStatuses) {
    EsvcsEnrollmentStatuses[EsvcsEnrollmentStatuses["New"] = 0] = "New";
    EsvcsEnrollmentStatuses[EsvcsEnrollmentStatuses["Viewed"] = 1] = "Viewed";
    EsvcsEnrollmentStatuses[EsvcsEnrollmentStatuses["Confirmed"] = 2] = "Confirmed";
    EsvcsEnrollmentStatuses[EsvcsEnrollmentStatuses["SenseiDBProvisioning"] = 3] = "SenseiDBProvisioning";
    EsvcsEnrollmentStatuses[EsvcsEnrollmentStatuses["SenseiDBProvisioned"] = 4] = "SenseiDBProvisioned";
    EsvcsEnrollmentStatuses[EsvcsEnrollmentStatuses["GatewayProvisioning"] = 5] = "GatewayProvisioning";
    EsvcsEnrollmentStatuses[EsvcsEnrollmentStatuses["GatewayProvisioned"] = 6] = "GatewayProvisioned";
    EsvcsEnrollmentStatuses[EsvcsEnrollmentStatuses["AgentLive"] = 22] = "AgentLive";
    EsvcsEnrollmentStatuses[EsvcsEnrollmentStatuses["CloudSyncInitiated"] = 30] = "CloudSyncInitiated";
    EsvcsEnrollmentStatuses[EsvcsEnrollmentStatuses["SenseiSyncInitiated"] = 32] = "SenseiSyncInitiated";
    EsvcsEnrollmentStatuses[EsvcsEnrollmentStatuses["Maintenance"] = 98] = "Maintenance";
    EsvcsEnrollmentStatuses[EsvcsEnrollmentStatuses["Completed"] = 99] = "Completed";
})(EsvcsEnrollmentStatuses = exports.EsvcsEnrollmentStatuses || (exports.EsvcsEnrollmentStatuses = {}));
function EsvcsEnrollmentStatuses_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.EsvcsEnrollmentStatuses_Descriptions);
    allKeys.forEach(function (key) {
        exports.EsvcsEnrollmentStatuses_Descriptions[key] = translationFunction(exports.EsvcsEnrollmentStatuses_Descriptions[key]);
    });
}
exports.EsvcsEnrollmentStatuses_translateDescriptions = EsvcsEnrollmentStatuses_translateDescriptions;
function EsvcsEnrollmentStatuses_translateDescriptionBack() {
    var allKeys = Object.keys(exports.EsvcsEnrollmentStatuses_Descriptions);
    allKeys.forEach(function (key) {
        exports.EsvcsEnrollmentStatuses_Descriptions[key] = exports.EsvcsEnrollmentStatuses_Descriptions_English[key];
    });
}
exports.EsvcsEnrollmentStatuses_translateDescriptionBack = EsvcsEnrollmentStatuses_translateDescriptionBack;
var _EsvcsEnrollmentStatuses_Descriptions = {};
_EsvcsEnrollmentStatuses_Descriptions.New = _EsvcsEnrollmentStatuses_Descriptions[0] = "New";
_EsvcsEnrollmentStatuses_Descriptions.Viewed = _EsvcsEnrollmentStatuses_Descriptions[1] = "Viewed";
_EsvcsEnrollmentStatuses_Descriptions.Confirmed = _EsvcsEnrollmentStatuses_Descriptions[2] = "Confirmed";
_EsvcsEnrollmentStatuses_Descriptions.SenseiDBProvisioning = _EsvcsEnrollmentStatuses_Descriptions[3] = "SenseiDB Provisioning";
_EsvcsEnrollmentStatuses_Descriptions.SenseiDBProvisioned = _EsvcsEnrollmentStatuses_Descriptions[4] = "SenseiDB Provisioned";
_EsvcsEnrollmentStatuses_Descriptions.GatewayProvisioning = _EsvcsEnrollmentStatuses_Descriptions[5] = "Gateway Provisioning";
_EsvcsEnrollmentStatuses_Descriptions.GatewayProvisioned = _EsvcsEnrollmentStatuses_Descriptions[6] = "Gateway Provisioned";
_EsvcsEnrollmentStatuses_Descriptions.AgentLive = _EsvcsEnrollmentStatuses_Descriptions[22] = "Agent Live";
_EsvcsEnrollmentStatuses_Descriptions.CloudSyncInitiated = _EsvcsEnrollmentStatuses_Descriptions[30] = "Initial Cloud Sync Initiated";
_EsvcsEnrollmentStatuses_Descriptions.SenseiSyncInitiated = _EsvcsEnrollmentStatuses_Descriptions[32] = "Sensei Sync Initiated";
_EsvcsEnrollmentStatuses_Descriptions.Maintenance = _EsvcsEnrollmentStatuses_Descriptions[98] = "Scheduled Maintenance in Progress";
_EsvcsEnrollmentStatuses_Descriptions.Completed = _EsvcsEnrollmentStatuses_Descriptions[99] = "Completed";
var _EsvcsEnrollmentStatuses_Descriptions_English = {};
_EsvcsEnrollmentStatuses_Descriptions_English.New = _EsvcsEnrollmentStatuses_Descriptions_English[0] = "New";
_EsvcsEnrollmentStatuses_Descriptions_English.Viewed = _EsvcsEnrollmentStatuses_Descriptions_English[1] = "Viewed";
_EsvcsEnrollmentStatuses_Descriptions_English.Confirmed = _EsvcsEnrollmentStatuses_Descriptions_English[2] = "Confirmed";
_EsvcsEnrollmentStatuses_Descriptions_English.SenseiDBProvisioning = _EsvcsEnrollmentStatuses_Descriptions_English[3] = "SenseiDB Provisioning";
_EsvcsEnrollmentStatuses_Descriptions_English.SenseiDBProvisioned = _EsvcsEnrollmentStatuses_Descriptions_English[4] = "SenseiDB Provisioned";
_EsvcsEnrollmentStatuses_Descriptions_English.GatewayProvisioning = _EsvcsEnrollmentStatuses_Descriptions_English[5] = "Gateway Provisioning";
_EsvcsEnrollmentStatuses_Descriptions_English.GatewayProvisioned = _EsvcsEnrollmentStatuses_Descriptions_English[6] = "Gateway Provisioned";
_EsvcsEnrollmentStatuses_Descriptions_English.AgentLive = _EsvcsEnrollmentStatuses_Descriptions_English[22] = "Agent Live";
_EsvcsEnrollmentStatuses_Descriptions_English.CloudSyncInitiated = _EsvcsEnrollmentStatuses_Descriptions_English[30] = "Initial Cloud Sync Initiated";
_EsvcsEnrollmentStatuses_Descriptions_English.SenseiSyncInitiated = _EsvcsEnrollmentStatuses_Descriptions_English[32] = "Sensei Sync Initiated";
_EsvcsEnrollmentStatuses_Descriptions_English.Maintenance = _EsvcsEnrollmentStatuses_Descriptions_English[98] = "Scheduled Maintenance in Progress";
_EsvcsEnrollmentStatuses_Descriptions_English.Completed = _EsvcsEnrollmentStatuses_Descriptions_English[99] = "Completed";
exports.EsvcsEnrollmentStatuses_Descriptions = _EsvcsEnrollmentStatuses_Descriptions;
exports.EsvcsEnrollmentStatuses_Descriptions_English = _EsvcsEnrollmentStatuses_Descriptions_English;
var _EsvcsEnrollmentStatuses_DisplayOrders = {};
_EsvcsEnrollmentStatuses_DisplayOrders.New = _EsvcsEnrollmentStatuses_DisplayOrders[0] = 0;
_EsvcsEnrollmentStatuses_DisplayOrders.Viewed = _EsvcsEnrollmentStatuses_DisplayOrders[1] = 1;
_EsvcsEnrollmentStatuses_DisplayOrders.Confirmed = _EsvcsEnrollmentStatuses_DisplayOrders[2] = 2;
_EsvcsEnrollmentStatuses_DisplayOrders.SenseiDBProvisioning = _EsvcsEnrollmentStatuses_DisplayOrders[3] = 3;
_EsvcsEnrollmentStatuses_DisplayOrders.SenseiDBProvisioned = _EsvcsEnrollmentStatuses_DisplayOrders[4] = 4;
_EsvcsEnrollmentStatuses_DisplayOrders.GatewayProvisioning = _EsvcsEnrollmentStatuses_DisplayOrders[5] = 5;
_EsvcsEnrollmentStatuses_DisplayOrders.GatewayProvisioned = _EsvcsEnrollmentStatuses_DisplayOrders[6] = 6;
_EsvcsEnrollmentStatuses_DisplayOrders.AgentLive = _EsvcsEnrollmentStatuses_DisplayOrders[22] = 22;
_EsvcsEnrollmentStatuses_DisplayOrders.CloudSyncInitiated = _EsvcsEnrollmentStatuses_DisplayOrders[30] = 30;
_EsvcsEnrollmentStatuses_DisplayOrders.SenseiSyncInitiated = _EsvcsEnrollmentStatuses_DisplayOrders[32] = 32;
_EsvcsEnrollmentStatuses_DisplayOrders.Maintenance = _EsvcsEnrollmentStatuses_DisplayOrders[98] = 98;
_EsvcsEnrollmentStatuses_DisplayOrders.Completed = _EsvcsEnrollmentStatuses_DisplayOrders[99] = 99;
exports.EsvcsEnrollmentStatuses_DisplayOrders = _EsvcsEnrollmentStatuses_DisplayOrders;
