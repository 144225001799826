import React, { useEffect } from "react";
import { InteractionStatus, EventMessage, EventType, AuthenticationResult } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import Wait from "../../components/shared/Wait";
import { consoleTextStyles } from "./msalClient";
import IAuthService from "./IAuthServices";


const MsalContainer: React.FC<{authService: IAuthService, logMessages: boolean}> = ({ authService, logMessages, children}) => {
  const { instance, inProgress } = useMsal();

  // Handle MSAL events
  useEffect(() => {
    // Add event handler
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      if (logMessages) {
        console.log(`%cMSAL%c Event: ${event.eventType}. Interaction: ${event.interactionType}. Error: ${event.error}`, consoleTextStyles.Title, consoleTextStyles.Unset);
      }

      switch(event.eventType) {
        case EventType.LOGIN_START:
        case EventType.LOGOUT_START:
          authService.setLoggedInUser(null);
          break;

        case EventType.LOGIN_SUCCESS:
          const account = (event.payload as AuthenticationResult).account;
          authService.setLoggedInUser(account);
          break;

        case EventType.LOGIN_FAILURE:
          console.groupCollapsed("%cLogin failed. There's likely a configuration issue.", "background: #faa; color: red");
          console.error(event.error);
          console.groupEnd();
      }
    });

    // Remove event handler
    return function detachEvent() {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    }

  }, [authService, instance, logMessages]);


  // Is the app handling MSAL events?
  if (inProgress !== InteractionStatus.None) {
    return (
      <div style={{ height: '100vh' }}>
          <div className="centered">
            <Wait style={{ padding: '8px 20px' }} rightOrBottomText="Please Wait" />
          </div>
      </div>
    )
  }

  // All good. Load rest of app.
  return <>{children}</>;
}


export default MsalContainer;
