import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { getCustomer } from "../../../../stores/customersStore";
import PromiseWait from "../../../shared/PromiseWait";
import CustomerPageContent from "./CustomerPageContent";


const CustomerPage: React.FC = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const promise = useMemo(() => getCustomer(customerId), [customerId]);

  return (
    <PromiseWait promise={promise} component={CustomerPageContent} mapComponentProps={PromiseWait.mapAsIs} />
  );
};

export default CustomerPage;
