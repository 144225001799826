import React from "react";
import { Button, Intent } from '@blueprintjs/core';
import { IconNames } from "@blueprintjs/icons";
import TextField from "../../shared/form/fields/TextField";
import { ValidationType, IValidation } from "../../shared/form/models/validation";
import { FormModes } from "../../shared/form/models/baseTypes";
import FormHost from "../../shared/form/FormHost";
import { isLocaleUK } from "../../../i18n";


export interface IFormFields {
  sapId: string;
  postCode: string;
}

const sapIdValidations: IValidation[] = [
  { type: ValidationType.Required},
  { type: ValidationType.SapId }
]

const postCodeValidations: IValidation[] = [
  { type: ValidationType.Required},
  { type: ValidationType.PostCode }
]

const initialValues: IFormFields = { sapId: '', postCode: '' };

interface IProps {
  onFormSubmitted: (values: IFormFields) => Promise<any>;
  onFormCompleted: (saved: boolean) => Promise<any>;
}

const OfficeAuthForm: React.FC<IProps> = ({
  onFormSubmitted,
  onFormCompleted,
}) => {
  return (
    <FormHost<IFormFields>
      formMode={FormModes.Edit}
      initialValues={initialValues}
      disableAutoComplete={true}
      onSave={onFormSubmitted}
      onFormCompleted={onFormCompleted}
    >
      <div style={{margin: '2em'}}>
        <div className="columns centered">
          <div className="column is-5">
            <TextField label="Account ID" binding="sapId" validations={sapIdValidations} />
          </div>
          <div className="column is-4">
            <TextField label={isLocaleUK() ? "Postcode" : "ZIP Code"} binding="postCode" validations={postCodeValidations} />
          </div>
        </div>

        <div className="centered">
          <Button type="submit" text="Continue" icon={IconNames.LOG_IN} intent={Intent.SUCCESS} />
        </div>
      </div>
    </FormHost>
  );
}

export default OfficeAuthForm;
