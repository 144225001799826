"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DpmsTypes_DisplayOrders = exports.DpmsTypes_Descriptions_English = exports.DpmsTypes_Descriptions = exports.DpmsTypes_translateDescriptionBack = exports.DpmsTypes_translateDescriptions = exports.DpmsTypes = void 0;
var DpmsTypes;
(function (DpmsTypes) {
    DpmsTypes[DpmsTypes["CMP"] = 0] = "CMP";
    DpmsTypes[DpmsTypes["Orthotrac"] = 1] = "Orthotrac";
    DpmsTypes[DpmsTypes["PracticeWorks"] = 2] = "PracticeWorks";
    DpmsTypes[DpmsTypes["R4"] = 3] = "R4";
    DpmsTypes[DpmsTypes["SoftDent"] = 4] = "SoftDent";
    DpmsTypes[DpmsTypes["T4"] = 5] = "T4";
    DpmsTypes[DpmsTypes["WinOMS"] = 6] = "WinOMS";
})(DpmsTypes = exports.DpmsTypes || (exports.DpmsTypes = {}));
function DpmsTypes_translateDescriptions(translationFunction) {
    var allKeys = Object.keys(exports.DpmsTypes_Descriptions);
    allKeys.forEach(function (key) {
        exports.DpmsTypes_Descriptions[key] = translationFunction(exports.DpmsTypes_Descriptions[key]);
    });
}
exports.DpmsTypes_translateDescriptions = DpmsTypes_translateDescriptions;
function DpmsTypes_translateDescriptionBack() {
    var allKeys = Object.keys(exports.DpmsTypes_Descriptions);
    allKeys.forEach(function (key) {
        exports.DpmsTypes_Descriptions[key] = exports.DpmsTypes_Descriptions_English[key];
    });
}
exports.DpmsTypes_translateDescriptionBack = DpmsTypes_translateDescriptionBack;
var _DpmsTypes_Descriptions = {};
_DpmsTypes_Descriptions.CMP = _DpmsTypes_Descriptions[0] = "Sensei Cloud";
_DpmsTypes_Descriptions.Orthotrac = _DpmsTypes_Descriptions[1] = "OrthoTrac";
_DpmsTypes_Descriptions.PracticeWorks = _DpmsTypes_Descriptions[2] = "PracticeWorks";
_DpmsTypes_Descriptions.R4 = _DpmsTypes_Descriptions[3] = "R4";
_DpmsTypes_Descriptions.SoftDent = _DpmsTypes_Descriptions[4] = "SoftDent";
_DpmsTypes_Descriptions.T4 = _DpmsTypes_Descriptions[5] = "T4";
_DpmsTypes_Descriptions.WinOMS = _DpmsTypes_Descriptions[6] = "WinOMS";
var _DpmsTypes_Descriptions_English = {};
_DpmsTypes_Descriptions_English.CMP = _DpmsTypes_Descriptions_English[0] = "Sensei Cloud";
_DpmsTypes_Descriptions_English.Orthotrac = _DpmsTypes_Descriptions_English[1] = "OrthoTrac";
_DpmsTypes_Descriptions_English.PracticeWorks = _DpmsTypes_Descriptions_English[2] = "PracticeWorks";
_DpmsTypes_Descriptions_English.R4 = _DpmsTypes_Descriptions_English[3] = "R4";
_DpmsTypes_Descriptions_English.SoftDent = _DpmsTypes_Descriptions_English[4] = "SoftDent";
_DpmsTypes_Descriptions_English.T4 = _DpmsTypes_Descriptions_English[5] = "T4";
_DpmsTypes_Descriptions_English.WinOMS = _DpmsTypes_Descriptions_English[6] = "WinOMS";
exports.DpmsTypes_Descriptions = _DpmsTypes_Descriptions;
exports.DpmsTypes_Descriptions_English = _DpmsTypes_Descriptions_English;
var _DpmsTypes_DisplayOrders = {};
_DpmsTypes_DisplayOrders.CMP = _DpmsTypes_DisplayOrders[0] = 0;
_DpmsTypes_DisplayOrders.Orthotrac = _DpmsTypes_DisplayOrders[1] = 1;
_DpmsTypes_DisplayOrders.PracticeWorks = _DpmsTypes_DisplayOrders[2] = 2;
_DpmsTypes_DisplayOrders.R4 = _DpmsTypes_DisplayOrders[3] = 3;
_DpmsTypes_DisplayOrders.SoftDent = _DpmsTypes_DisplayOrders[4] = 4;
_DpmsTypes_DisplayOrders.T4 = _DpmsTypes_DisplayOrders[5] = 5;
_DpmsTypes_DisplayOrders.WinOMS = _DpmsTypes_DisplayOrders[6] = 6;
exports.DpmsTypes_DisplayOrders = _DpmsTypes_DisplayOrders;
