import { useState, useCallback } from "react";


// Used to manage state of components that can be in opened/closed state -- ex. Dialogs.
// Ex:
// const [isOpen, open, close] = useDialogState();
//   return (
//     <div>
//       <Button text="Launch" onClick={open} />
//       {isOpen && <SomeDialog isOpen={true} onDialogClosed={close} />}
//     </div>
//   );

const useDialogState = (initiallyOpen = false): [boolean, ()=> void, () => void] => {
  const [isOpen, setIsOpen] = useState(initiallyOpen);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  return [isOpen, open, close];
}

export default useDialogState;
