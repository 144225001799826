import { assertNonNil } from './objectUtils';

export type PendingPromiseCacheOptions<K, V> = {
  promiseFactory: (key: K) => Promise<V>;
  throttle?: number;
  debugMode?: boolean;
  onCacheHit?: (key: K) => void;
  onCacheMiss?: (key: K) => void;
}

// Maintains a cache of pending (i.e. not settled) promises.
// While a promise is pending, any subsequent requests
// using the same key will return the pending promise instance.
export default class PendingPromiseCache<K, V> {
  private _options: PendingPromiseCacheOptions<K, V>;
  private _cache = new Map<K, Promise<V>>();

  constructor(options: PendingPromiseCacheOptions<K, V>) {
    const defaults = {
      debugMode: false,
      onCacheHit: (key: K) => this.debugMessage("Cache hit: " + key),
      onCacheMiss: (key: K) => this.debugMessage("Cache miss: " + key)
    };

    this._options = Object.assign(defaults, options);
  }


  private debugMessage(message: string) {
    if (this._options.debugMode)
      console.info(message);
  }




  public readonly remove = (key: K) => {
    if (this._cache.delete(key))
      this.debugMessage("Removed: " + key)
  }



  private readonly onPromiseSettled = (key: K) => {
    if (this._options.throttle)
      setTimeout(() => this.remove(key), this._options.throttle);
    else
      this.remove(key);
  }


  get(key: K) {
    var pendingPromise = this._cache.get(assertNonNil(key));

    if (pendingPromise)
      assertNonNil(this._options.onCacheHit)(key);
    else {
      assertNonNil(this._options.onCacheMiss)(key);
      // Add the promise to the cache.
      // Also, setup to remove the promise from the cache when it completes.
      pendingPromise =
        this._options.promiseFactory(key)
          .finally(() => this.onPromiseSettled(key));

      this._cache.set(key, assertNonNil(pendingPromise));
    }

    return pendingPromise;
  }
}
