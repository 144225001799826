import React, { useState, useMemo, useCallback, useEffect, useRef } from "react";
import { Button, InputGroup, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";


interface IProps {
  value: string;
  onChanged: (value: string) => void;
  maxLength?: number;
  allowEdit?: boolean;
  disabled?: boolean;
  fill?: boolean;
  isValidValue?: (value: string) => boolean;
  placeholder?: string;
  //cancelButtonClears?: boolean;

}


const Comp: React.FC<IProps> = ({ value, onChanged, allowEdit = true, disabled, fill = false, isValidValue = () => true,
  maxLength, placeholder}) => {

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = React.createRef<HTMLInputElement | null>();
  const [localValue, setLocalValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const isValid = isValidValue(localValue);

  const enableEditMode = useCallback(() => setIsEditing(true), []);

  const onAcceptEdit = useCallback(() => {
    //console.log("accept", localValue)
    setIsEditing(false);
    onChanged(localValue);
  }, [localValue, onChanged]);

  const onCancelEdit = useCallback(() => {
    //console.log("cancel")
    setIsEditing(false);
    setLocalValue(value);

  }, [value]);

  const handleInputChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setLocalValue(e.currentTarget.value);
  }, []);

  const onInputClicked = useCallback((e: React.MouseEvent) => {
    // If the input is clicked while in disabled/readonly mode, enable edit mode
    if (e.target === inputRef.current && allowEdit && !isEditing) {
      enableEditMode();
    }
  }, [allowEdit, enableEditMode, inputRef, isEditing]);

  const onEditBlur = useCallback((e: React.FocusEvent<HTMLElement>) => {
    // Is the focus moving outside of the container, or to one of it's child element?
    if (e.relatedTarget) {
      const allDescendents = Array.from(containerRef.current!.querySelectorAll("*"));
      const isOutside = allDescendents.every(node => e.relatedTarget !== node);
      if (isOutside) {
        onCancelEdit();
      }
    }
  }, [onCancelEdit]);

  const onInputKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      onAcceptEdit();
    }
    else if (e.keyCode === 27) {
      // Note: Will not trigger if parent Dialog captures Escape key.
      onCancelEdit();
    }
  }, [onAcceptEdit, onCancelEdit]);


  useEffect(() => {
    // Set focus to input when entering edit mode
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, isEditing]);

  useEffect(() => {
    // Discard edit?
    if (!allowEdit && isEditing) {
      onCancelEdit();
    }
  }, [allowEdit, isEditing, onCancelEdit]);

  const buttons = useMemo(() =>
    isEditing
      ? (
        <div>
          <Button
            minimal={true}
            small={true}
            icon={IconNames.TICK}
            intent={Intent.SUCCESS}
            onClick={onAcceptEdit}
            title="OK"
            disabled={!isValid}
          />
          <Button
            minimal={true}
            small={true}
            icon={IconNames.CROSS}
            intent={Intent.DANGER}
            onClick={onCancelEdit}
            title={"Cancel"}
          />
        </div>
      )
      : allowEdit
        ? <Button small={true} icon={IconNames.EDIT} onClick={enableEditMode} title="Edit" />
        : undefined
    , [isEditing, onAcceptEdit, isValid, onCancelEdit, allowEdit, enableEditMode]);

  return (
    <div ref={containerRef} onClick={onInputClicked} onBlur={onEditBlur} >
      <InputGroup
        value={localValue}
        onChange={handleInputChange}
        onKeyDown={onInputKeyDown}
        fill={fill}
        readOnly={!isEditing}
        disabled={disabled}
        placeholder={placeholder}
        rightElement={buttons}
        inputRef={inputRef as any}
        maxLength={maxLength}
        intent={isValid ? Intent.NONE : Intent.DANGER}
      />
    </div>
  );
}


export default Comp;
