import React, { memo } from "react";
import { Classes, Text, Intent } from "@blueprintjs/core";
import classnames from "classnames";


interface IProps {
  muted?: boolean;
  ellipsize?:  boolean;
  className?: string;
  intent?: Intent;
  style?: React.CSSProperties;
}


const TextSpan: React.FC<IProps> = memo(({ muted = false, intent = Intent.NONE, ellipsize = false, style, className, children }) => {
  // Note: using `Classes.intentClass(intent)` doesn't work, because intent classes (ex: `bp3-intent-danger`)
  // only applies to specific elements.
  const classes = classnames(className, Classes.RUNNING_TEXT, {
    [Classes.TEXT_MUTED]: muted,
    "intent-primary": intent === Intent.PRIMARY,
    "intent-success": intent === Intent.SUCCESS,
    "intent-warning": intent === Intent.WARNING,
    "intent-danger": intent === Intent.DANGER
  });

  return (
    <Text ellipsize={ellipsize} className={classes} tagName="span"><span style={style}>{children}</span></Text>
  );
})

export default TextSpan;
