import validator from "validator";
import isDate from "lodash.isdate";
import { today, millisecondsInADay } from "../.././../../utils/dateUtils";
import { formatString, notNil, isNil } from "../../../../utils/objectUtils";
import { getLocaleSettings } from "../../../../i18n/localeSettings";
import { getLocale } from "../../../../i18n/index";

export const groupErrorsKey = "$errors";

export type GroupErrors = {
  [groupErrorsKey]?: Record<string, string>
}

export type FormikErrorsEx<T> = {
  // The built-in `FormikErrors` doesn't handle optional properties of type object.
  [K in keyof T]?: Required<T>[K] extends object ? FormikErrorsEx<T[K]> : string;
} & GroupErrors;


export enum ValidationType {
  Required = "required",
  Length = "length",
  Number = "number",
  Email = "email",
  Date = "date" ,
  Phone = "phone",
  PostCode = "postCode",
  SapId = "sapId",
  Custom = "custom"
}

export interface IValidationParamsBase {
  message?: string;
  //binding: string;
  locale?: number;
}

type IValidationParams<T> = T & IValidationParamsBase;

//  IMPORTANT: Formik Validation functions must return string or undefined, *not* null.
export type IValidationFunc<T={}> = (value: any, params: IValidationParams<T>) => string | undefined;


type IValidationBase<T extends IValidationParamsBase, Q extends ValidationType>  = T &  { type: Q }


type RequiredParams = IValidationParams<{}>;
export interface IRequiredValidation extends IValidationBase<RequiredParams, ValidationType.Required> {
}


type CustomValidationFunc = IValidationFunc<{}>;
type CustomParams = IValidationParams<{validate: CustomValidationFunc}>;
export interface ICustomValidation extends  IValidationBase<CustomParams, ValidationType.Custom> {
}


type LengthParams = IValidationParams<{min?: number, max?: number}>;
export interface ILengthValidation extends IValidationBase<LengthParams, ValidationType.Length> {
}

type NumberParams = IValidationParams<{min?: number, max?: number}>;
export interface INumberValidation extends IValidationBase<NumberParams, ValidationType.Number> {
}

type EmailParams = IValidationParams<{}>;
export interface IEmailValidation extends IValidationBase<EmailParams, ValidationType.Email> {
}

type DateParams = IValidationParams<{
  min?: 'today' | 'tomorrow';
  max?: 'today' | 'yesterday'
}>;
export interface IDateValidation extends IValidationBase<DateParams, ValidationType.Date> {
}


type PhoneParams = IValidationParams<{omitLengthCheck?: boolean}>;
export interface IPhoneValidation extends IValidationBase<PhoneParams, ValidationType.Phone> {
}


type PostCodeParams = IValidationParams<{}>;
export interface IPostCodeValidation extends IValidationBase<PostCodeParams, ValidationType.PostCode> {
}

type SapIdParams = IValidationParams<{fixedLengthOnly?: boolean}>;
export interface ISapIdValidation extends IValidationBase<SapIdParams, ValidationType.SapId> {
}

export type IValidation = IRequiredValidation | ILengthValidation | INumberValidation | IEmailValidation
  | IDateValidation | IPhoneValidation | IPostCodeValidation | ISapIdValidation | ICustomValidation;

export function findValidation<T extends IValidation>(type: T['type'],  validations: IValidation[]) {
  return (validations || []).find(v => v.type === type) as T;
}

export const findRequiredValidation = (validations: IValidation[]) =>
  (validations || []).find(v => v.type === ValidationType.Required) as IRequiredValidation;

export const hasRequiredValidation = (validations: IValidation[]) => !!findRequiredValidation(validations);

export const isEmpty = (val: any) =>
  isNil(val) // null | undfined | NaN
  || val === '' // type string
  || (Array.isArray(val) && val.length === 0)
  || (isDate(val) && isNaN(val.valueOf()));

export const hasValue = (val: any) => !isEmpty(val);

export function requiredMessage(value: any, { message =  "Required field" }: RequiredParams) {
  return hasValue(value) ? undefined : message;
}


export function customMessage(value: any, { validate, ...otherParams}: CustomParams) {
  return validate(value, otherParams);
}


export function lengthMessage(value: any, { min, max, message }: LengthParams) {
  console.assert(hasValue(value));
  const isValid = (!min || value.length >= min) && (!max || value.length <= max);
  if (isValid)
    return undefined;

  if (message)
    return formatString(message, { min, max, value });

  if (min && max)
    if (min === max)
      return `Must be ${min} characters in length`
    else
      return `Must be between ${min} and ${max} characters in length`

  if (min)
    return `Must be at least ${min} characters in length`

  if (max)
    return `Must be no more than ${max} characters in length`

  throw new Error("Should not get here!");
}


export function numberMessage(value: string = '', { min, max, message }: LengthParams) {
  console.assert(hasValue(value));
  // Note: `validator.isInt` checks for presense of `min` `max` options.
  // Settings them to `undefined` fails validation.
  const options: any = {};
  if (notNil(min))
    options.min = min;
  if (notNil(max))
    options.max = max;

  const isValid = validator.isInt(value, options);
  if (isValid)
    return undefined;

  if (message)
    return formatString(message, { min, max, value });

  if (notNil(min) && notNil(max))
    return `Must be between ${min} and ${max}`

  if (notNil(min))
    return `Must be at at least ${min}`

  if (notNil(max))
    return `Must be no more than ${max}`

  return `Must be digits only`;
}


export function emailMessage(value: string = '', { message }: LengthParams) {
  console.assert(hasValue(value));
  const isValid = validator.isEmail(value, { allow_display_name: false, allow_utf8_local_part: false });
  if (isValid)
    return undefined;

  if (message)
    return formatString(message, { value });

  return "Invalid email address";
}


export function getDateFromDescription(desc?: "today" | 'tomorrow' | 'yesterday')
{
  const _today = today();
  switch (desc) {
    case 'today': return _today;
    case 'yesterday': return new Date(+_today - millisecondsInADay);
    case 'tomorrow': return  new Date(+_today + millisecondsInADay);
    default: return undefined;
  }
}

export function dateMessage(value: string = '', { min, max, message }: DateParams) {
  console.assert(hasValue(value));
  const minDate = getDateFromDescription(min);
  const maxDate = getDateFromDescription(max);
  const date = new Date(value);

  const isValid = !isNaN(date.valueOf()) && (!minDate || date >= minDate) && (!maxDate || date <= maxDate);
  if (isValid)
    return undefined;

  if (message)
    return formatString(message, { min, max, value });

  if (minDate && maxDate)
    return `Must be between ${min} and ${max}`

  if (minDate)
    return `Must be ${min} or after`

  if (maxDate)
    return `Must be on or before ${max}`

  return `Must be a valid date`;
}


export function phoneMessage(value: string = '', { locale, omitLengthCheck = false }: PhoneParams) {
  if (!hasValue(value)) {
    return;
  }

  const { allowedChars, allowedDigitCounts, desc } = getLocaleSettings(locale).phone;

  if (!allowedChars.test(value)) {
    return "Invalid characters";
  }

  // Do we have the correct number of digits?
  if (omitLengthCheck) {
    return;
  }

  const digitCount = value.replace(/[^0-9]/g, "").length;
  if (allowedDigitCounts.some(d => d === digitCount)) {
    return;
  }

  return desc;
}

export function postCodeMessage(value = '', { locale }: PostCodeParams) {
  const { postCode: settings } = getLocaleSettings(locale);

  if (!settings.regex.test(value))
    return settings.desc;
}



const sapIdFormat = /^\d{6,10}$/;
const fixedLenthSapIdFormat = /^\d{10}$/; // 10 digits only

export function sapIdMessage(value = '', { fixedLengthOnly = false }: SapIdParams) {
  if (fixedLengthOnly) {
    if (!fixedLenthSapIdFormat.test(value))
    return "Must 10 digits only";
  }

  if (!sapIdFormat.test(value))
    return "Must be 6 to 10 digits";
}




function runValidationFunction(value: string | number | Date | undefined, validation: IValidation, locale: number | undefined) {
  // Convert to string
  if (isDate(value)) {
    value = value.toISOString();
  }
  else if (notNil(value) && typeof value !== 'string')
    value = String(value);

  switch (validation.type) {
    case ValidationType.Required: return requiredMessage(value, {...validation, locale});

    case ValidationType.Length: return lengthMessage(value, {...validation, locale});

    case ValidationType.Number: return numberMessage(value, {...validation, locale});

    case ValidationType.Email: return emailMessage(value, {...validation, locale});

    case ValidationType.Date: return dateMessage(value, {...validation, locale});

    case ValidationType.Phone: return phoneMessage(value, {...validation, locale});

    case ValidationType.PostCode: return postCodeMessage(value, {...validation, locale});

    case ValidationType.SapId: return sapIdMessage(value,  {...validation, locale});

    case ValidationType.Custom: return customMessage(value, {...validation, locale});

    default:
      console.warn("Unknown validation type: ", validation);
      return undefined;
  }
}


export function runValidations(value: string | number | Date | undefined, validations: IValidation[] = []) {
  const requiredValidation = findRequiredValidation(validations);
  //use a local variable to prevent multiple calls to getLocale() in the map method call
  const localeString = getLocale();
  if (requiredValidation) {
    const msg = runValidationFunction(value, requiredValidation, localeString);
    if (msg)
      return msg;
  }

  // Only run other validations if the field has value
  validations = validations.filter(v => v.type !== ValidationType.Required);
  const messages = hasValue(value)
    ? validations.map(v => runValidationFunction(value, v, localeString))
    : [];
  const msg = messages.filter(msg => !!msg)[0];
  return msg;
}

// export interface IFieldValidation extends IValidationBase<any, any> {
//   binding: string;
// }



export const validationMethodFactory = ( { validations = [] }:  { validations: IValidation[] }) =>
  (value: string | number | Date | undefined) => runValidations(value, validations);
