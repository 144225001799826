import React, { useContext, useMemo } from "react";
import { AccountInfo, RedirectRequest } from "@azure/msal-browser";
import { MsalProvider, UnauthenticatedTemplate as MsalUnauthenticatedTemplate, AuthenticatedTemplate as MsalAuthenticatedTemplate } from "@azure/msal-react";
import { getMsalClient, getAuthScopes } from "./msalClient";
import { AppModes, isAppMode, isAppModeSet } from "../appModeService";
import MsalContainer from "./MsalContainer";
import useAppConfig from '../../components/shared/useAppConfig';
import IAuthService from "./IAuthServices";
import routes from '../../routePaths';
import { IAppConfig } from '../appConfigService';



export const accountIdentifierKey = "_account_Id_"

//export function getCurrentAccountId(throwIfNotFound: true): string;
export function getCurrentAccountId(throwIfNotFound = false) {
  const id = sessionStorage.getItem(accountIdentifierKey) || undefined;
  if (!id && throwIfNotFound) {
    throw new Error("Can't find logged in user's account ID.")
  }

  return id;
}


//export function getLoggedInUser(throwIfNotFound: true): string;
export function getLoggedInUser(throwIfNotFound = true) {
  const accountId = getCurrentAccountId(throwIfNotFound);
  const account = getMsalClient().getAccountByHomeId(accountId || '');


  if (!account && throwIfNotFound) {
    throw new Error("No logged in user found");
  }

  return account;
}


// Note: Azure AD app registration must have email claim enabled for this to work.
export const getAccountEmail = (account = getLoggedInUser()) =>
  (account?.idTokenClaims as { email: string })?.email;


function getAuthRequestParams(state?: string) {
  const authParams: RedirectRequest = {
    scopes: getAuthScopes(),
    state
  };

  return authParams;
}

class AuthService implements IAuthService {

  readonly _appConfig: IAppConfig;

  constructor(appConfig: IAppConfig) {
    this._appConfig = appConfig;
  }

  login = async (redirectAfterLoginTo?: string, state?: string) => {
    this.setLoggedInUser(null);
    const authParams = getAuthRequestParams(state);
    if (!redirectAfterLoginTo) {
      redirectAfterLoginTo = isAppMode(AppModes.Partner) ? routes.partnerMode.root.path : routes.home.path;
    }
    authParams.redirectStartPage = redirectAfterLoginTo;
    await getMsalClient().loginRedirect(authParams);
  }

  logout = async (endSession = isAppMode(AppModes.Partner)) => {
    const account = this.getLoggedInUser() || undefined;
    this.setLoggedInUser(null);

    await getMsalClient().logout({
      account,
      onRedirectNavigate: () => {
        sessionStorage.clear(); // clear any other session data
        if (endSession) {
          return endSession;
        }
        // Local logout only
        setTimeout(() => window.location.reload(), 0);
        return false;
      }
    });
  }

  setLoggedInUser = (account: AccountInfo | null) => {
    if (!account) {
      sessionStorage.removeItem(accountIdentifierKey);
    }
    else {
      sessionStorage.setItem(accountIdentifierKey, account.homeAccountId);
    }
  }

  getLoggedInUser = (throwIfNotFound = true) =>
    getLoggedInUser(throwIfNotFound); // Note: calls non-member version of the function

  get isLoggedIn() {
    return !!this.getLoggedInUser(false);
  }

  isInRole = (roles: string | string[]) => {
    if (typeof roles === "string") {
      roles = [roles];
    }

    const account = this.getLoggedInUser(false);
    if (!account)
      return false;
    const roleClaims = (account.idTokenClaims as any)["roles"] as string[];
    return Array.isArray(roleClaims) && roles.some(r => roleClaims.includes(r));
  }

  isInGroup = (groups: string | string[]) => {
    if (typeof groups === "string") {
      groups = [groups];
    }
    const account = getLoggedInUser(false);
    if (!account)
      return false;

    const groupClaims = (account.idTokenClaims as any)["groups"] as string[];

    return Array.isArray(groupClaims) && groups.some(r => groupClaims.includes(r));
  }

  isInDefaultAccessGroup = () => {
    const { azureAd: { employee } } = this._appConfig;
    const allowedRoles = [employee.roles.user, employee.roles.admin];
    const allowedGroups = employee.groups.map(g => g.Id);
    return (!this.isInRole(allowedRoles) && this.isInGroup(allowedGroups));
  }
}







const context = React.createContext<IAuthService | Error>(new Error("MSAL Client isn't available on context!"));


export const AuthServiceProvider: React.FC = ({ children }) => {
  const appConfig = useAppConfig();
  const { azureAd: { logMsalEvents } } = appConfig;
  const authService = useMemo(() => new AuthService(appConfig), [appConfig]);

  if (!isAppModeSet()) {
    // Don't instanciate MSAL
    return (
      <context.Provider value={authService}>
        {children}
      </context.Provider>
    );
  }

  // Wrap in MSAL provider
  return (
    <context.Provider value={authService}>
      <MsalProvider instance={getMsalClient()}>
        <MsalContainer authService={authService} logMessages={logMsalEvents}>
          {children}
        </MsalContainer>
      </MsalProvider>
    </context.Provider>
  );
}



function useAuthService() {
  const ctx = useContext(context);
  if (ctx instanceof Error) {
    throw ctx;
  }
  return ctx;
}

export const UnauthenticatedTemplate: React.FC = ({ children }) =>
  <MsalUnauthenticatedTemplate homeAccountId={getCurrentAccountId()} children={children} />

export const AuthenticatedTemplate: React.FC = ({ children }) =>
  <MsalAuthenticatedTemplate homeAccountId={getCurrentAccountId()} children={children} />

export default useAuthService;

