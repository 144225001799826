import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { AppConfigProvider } from "../shared/useAppConfig";
import AppErrorBoundary from "./AppErrorBoundary";
import { AuthServiceProvider } from "../../services/authService";
import { AppModeProvider } from "../../services/appModeService";
import AppRoutes from "./AppRoutes";
import { BlueprintProvider } from '@blueprintjs/core';



const App: React.FC = () => {
  return (
    <div className="app">
      <AppErrorBoundary>
        <AppConfigProvider>
          <AppModeProvider>
            <AuthServiceProvider>
              <BrowserRouter>
                <BlueprintProvider>
                  <AppRoutes />
                </BlueprintProvider>
              </BrowserRouter>
            </AuthServiceProvider>
          </AppModeProvider>
        </AppConfigProvider>
      </AppErrorBoundary>
    </div>
  );
}


export default App;
