import React, { useCallback, useMemo } from "react";
import { IColumn, FieldNames } from "./models";
import NoData from "./NoData";
import Row from "./Row";


interface IProps<TRow> {
  columns: IColumn<TRow, any>[];
  data: TRow[];
  getRowKey: FieldNames<TRow> | ((row: TRow, index: number) => string);
}


const Rows = <TRow extends {}>({ columns, data, getRowKey }: IProps<TRow>) => {
  const keyCol = useMemo(() =>
    typeof getRowKey === "string" ? columns.find(c => c.name === getRowKey) : undefined
    , [columns, getRowKey]);

  const getKey = useCallback((row: TRow, index: number) => {
    // Note: We don't want to call the accessor on the `keyCol`,
    // since the accessor might return some non-unique value.
    return keyCol
      ? (row[keyCol.name] as any).valueOf()
      : (typeof getRowKey === "function" && getRowKey(row, index)) || index
  }, [getRowKey, keyCol]);


  if (!data.length) {
    return <NoData show={true} colSpan={columns.length} />
  }

  return (
    <>
      {data.map((row, i) => <Row key={getKey(row, i)} columns={columns} data={row} />)}
    </>
  )
}


export default Rows;


