import React, { memo, useCallback } from "react";
import css from "./page.module.scss";
import Header from "./Header";
import Footer from "./Footer";
import ModalWait from "../ModalWait";
//import Section from "./Section";
import classnames from "classnames";



type RenderFunc = () => React.ReactNode;

export interface IPageProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  headerContainerStyle?: React.CSSProperties;
  footerContainerStyle?: React.CSSProperties;
  contentContainerStyle?: React.CSSProperties;
  contentContainerClassName?: string;
  isLoading?: boolean;
  renderBodyDuringLoad?: boolean;
  children: React.ReactNode | RenderFunc;
  showCsdFlowGraphic?: boolean;
  className?: string;
}


function getChildren(children: React.ReactNode | RenderFunc) {
  return (typeof children === 'function')
    ? (children as RenderFunc)()
    : children;
}

const Page: React.FC<IPageProps> = memo((props) => {
  const isLoading = props.isLoading === true;
  const renderContent = useCallback(() => {
    return (
    <>
      {<ModalWait isOpen={isLoading} />}
      {(!isLoading || props.renderBodyDuringLoad === true) && getChildren(props.children)}
    </>
  )}, [props, isLoading]);

  return (
    <div className={classnames(css.page, props.className)}>
      <Header containerStyle={props.headerContainerStyle} showCsdFlowGraphic={props.showCsdFlowGraphic} >{props.header}</Header>
      <div className={classnames(css.content, props.contentContainerClassName)} style={props.contentContainerStyle}>{renderContent()}</div>
      <Footer containerStyle={props.footerContainerStyle} >{props.footer}</Footer>
    </div>);
})

export default Page;
