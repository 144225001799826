import React, {memo} from "react";
import css from "./page.module.scss";

const Footer: React.FC<{containerStyle?: React.CSSProperties}> = memo(({children, containerStyle}) => {

  if (React.Children.count(children) === 0) {
    return null;
  }

  return (
    <div className={css.footer} style={containerStyle}>
      {children}
    </div>
  );
})


export default Footer;
