import React, { useMemo } from "react";
import { Overlay } from "@blueprintjs/core";
import Wait, { IWaitProps } from "./Wait";

interface IProps extends IWaitProps {
  isOpen: boolean;
  large?: boolean;
};

const backdropProps: React.HTMLProps<HTMLDivElement> = {
  style: {
    backgroundColor: 'rgba(128, 128, 128, 0.5)'
  }
}

const ModalWait: React.FC<IProps> = ({isOpen = false, large = false, ...waitProps} ) => {
  const {style: styleProp, ...otherProps} = waitProps;
  const showBorder = waitProps.showBorder !== false;
  const style = useMemo(() => ({
    padding: large ? '20px 60px' : '8px 20px',
    border: showBorder ? 'solid 1px #ccc' : undefined,
    boxShadow: showBorder ? 'unset' : undefined,
    ...styleProp,
  }), [large, showBorder, styleProp]);

  if (isOpen !== true)
    return null;

  return (
      <Overlay isOpen={true} canEscapeKeyClose={false} canOutsideClickClose={false} backdropProps={backdropProps}>
        <div className="centered">
          <Wait {...otherProps} showBorder={showBorder} style={style} />
        </div>
      </Overlay>
  );
}


export default ModalWait;

