import React, {memo} from "react";
import classnames from "classnames";
import css from "./page.module.scss";

interface IProps {
  containerStyle?: React.CSSProperties;
  showCsdFlowGraphic?: boolean;
}

const Header: React.FC<IProps> = memo(({children, showCsdFlowGraphic = false, containerStyle}) => {

  // If the child is a string, then wrap it.
  if (typeof children === 'string') {
    children = <h2>{children}</h2>
  }

  return (
    <div className={classnames(css.header, showCsdFlowGraphic && css.csdFlow)} style={containerStyle}>
      {children}
    </div>
  );
})


export default Header;
