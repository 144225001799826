import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { IPersonSearchResult } from "../../../../models/Person";
import { getPatients } from "../../../../stores/entitySnapshotsStore";
import { formatBirthDate } from "../../../../utils/dataFormatters";
import { IColumn } from "../../../shared/DataTable/models";
import { FnOpenJsonDialog } from "./JsonDisplayDialog";
import PersonEntityViewerPanel, { IPersonPanelProps, TPersonSearchColDef } from "./PersonEntityViewerPanel";



const getPatientColumns = (openJsonDlg: FnOpenJsonDialog) => {
  const formatDate = (date?: Date) => !!date ? date.toLocaleString() : '';

  const columns: TPersonSearchColDef[] = [
    {

      header: "Patient ID",
      name: "id",
      disableSort: true
    },

    {
      header: "Last Name",
      name: "lastName",
      disableSort: true
    },

    {
      header: "First Name",
      name: "firstName",
      disableSort: true
    },

    {
      header: "Active?",
      name: "isActive",
      disableSort: true
    },

    {
      header: "Birth Date",
      name: "birthDate",
      disableSort: true,
      renderer: (value: Date, row: IPersonSearchResult, col: IColumn<IPersonSearchResult, Date>) => {
        if (!value) return null;
        return (<div>{formatBirthDate(value)}</div>);
      }
    },

    {
      header: "Created On",
      name: "createdOn",
      disableSort: true,
      renderer: (value: Date, row: IPersonSearchResult, col: IColumn<IPersonSearchResult, Date>) => {
        if (!value) return null;
        return (<div>{formatDate(value)}</div>);
      }
    },

    {
      header: "Last Sync Date",
      name: "modifiedOn",
      disableSort: true,
      renderer: (value: Date, row: IPersonSearchResult, col: IColumn<IPersonSearchResult, Date>) => {
        if (!value) return null;
        return (<div>{formatDate(value)}</div>);
      }
    },

    {
      header: "",
      name: "_actions_",
      disableSort: true,
      accessor: (data: IPersonSearchResult) => data,
      renderer: (data: IPersonSearchResult) => {
        return (
          <Button onClick={() => openJsonDlg(data.entitySnapshot)}
            style={{ fontSize: "smaller" }}
            rightIcon={IconNames.EYE_OPEN}
            title="View Patient Details"
            small={true}
            minimal={true}>
          </Button>
        );
      }
    }
  ];

  return columns;
};


const PatientSearchPanel: React.FC<IPersonPanelProps> = (props) => {
  return (
    <PersonEntityViewerPanel
      {...props}
      personLabel="Patient"
      colGetter={getPatientColumns}
      apiFunc={getPatients} />
  );
}

export default PatientSearchPanel;
