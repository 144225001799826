import React, { memo } from "react";
import { IColumn } from "./models";
import Cell from "./Cell";


interface IProps<TRow> {
  columns: IColumn<TRow, any>[]
  data: TRow;
}


const Row = <TRow extends {}>({ columns, data }: IProps<TRow>) => {
  return (
    <tr>
      {columns.map((c, i) => <Cell key={`${i}-${c.name}`} column={c} data={data} />)}
    </tr>
  )
}

const memoized = memo(Row) as typeof Row;
export default memoized;


